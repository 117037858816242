import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { VscSaveAs } from 'react-icons/vsc';
import {
  BsCreditCard,
  BsPencil,
  BsPerson,
  BsToggleOff,
  BsToggleOn,
} from 'react-icons/bs';
import { IoBookmarkOutline, IoFilter } from 'react-icons/io5';
import { AiOutlineCalendar } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { typeSubtypeStringToObject } from 'helpers';
import {
  FormTextField,
  FormDateField,
  FormGroup,
  FloatingCard,
} from '_components/_core';
import { CategorySelect } from '_components/_shared';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import CustomFormSelectField from '_components/Transactions/components/CustomFormSelectField/CustomFormSelectField';
import MobileTransactionType from '_components/Transactions/components/MobileTransactionType/MobileTransactionType';
import useCategories from '_store/_hooks/useCategories';

import {
  StyledFormCurrencyField,
  StyledPaidToggle,
  StyledSubmit,
} from './styles';

function MobileTransactionForm({
  isOpen,
  recipients,
  accounts,
  categories: originalCategories,
  selectedDate,
  account_id,
  onCreateTransaction,
  onCreateTransferTransaction,
  onToggleMobileTransactionForm,
  onAfterSaveCallback,
}) {
  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectActive, setSelectActive] = useState(false);

  const {
    onLoadSuggestions: onLoadCategoriesSuggestions,
  } = useCategories({
    categories: originalCategories,
  });

  const canCreateIncome = useMemo(() => hasPermissions({
    permissions: ['aba_recebimento_create'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canCreateExpense = useMemo(() => hasPermissions({
    permissions: [
      'aba_despesa_fixa_create',
      'aba_despesa_variavel_create',
      'aba_pessoal_create',
      'aba_imposto_create',
    ],
    userPermissions,
    type: 'any',
  }), [userPermissions]);

  const canCreateFixedExpense = useMemo(() => hasPermissions({
    permissions: [
      'aba_despesa_fixa_create',
    ],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canCreateVariableExpense = useMemo(() => hasPermissions({
    permissions: [
      'aba_despesa_variavel_create',
    ],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canCreatePeopleExpense = useMemo(() => hasPermissions({
    permissions: [
      'aba_pessoal_create',
    ],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canCreateTaxesExpense = useMemo(() => hasPermissions({
    permissions: [
      'aba_imposto_create',
    ],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canCreateTransfer = useMemo(() => hasPermissions({
    permissions: ['aba_transferencia_create'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const availableExpenseTypesOptions = useMemo(() => {
    const options = [];

    if (canCreateFixedExpense) {
      options.push({
        value: 'EXPENSE::FIXED_EXPENSE',
        label: 'Despesa Fixa',
      });
    }

    if (canCreateVariableExpense) {
      options.push({
        value: 'EXPENSE::VARIABLE_EXPENSE',
        label: 'Despesa Variável',
      });
    }

    if (canCreatePeopleExpense) {
      options.push({
        value: 'EXPENSE::PEOPLE',
        label: 'Pessoas',
      });
    }

    if (canCreateTaxesExpense) {
      options.push({
        value: 'EXPENSE::TAXES',
        label: 'Impostos',
      });
    }

    return options;
  }, [
    canCreateFixedExpense,
    canCreateVariableExpense,
    canCreatePeopleExpense,
    canCreateTaxesExpense,
  ]);

  const initialValues = useMemo(() => {
    const selectedMonthYear = selectedDate.toFormat('yyyy-MM');
    const currentMonthYear = DateTime.now().toFormat('yyyy-MM');

    let event_date = null;

    if (selectedMonthYear === currentMonthYear) {
      event_date = DateTime.now().toFormat('yyyy-MM-dd');
    } else {
      event_date = selectedDate.startOf('month').toISODate();
    }

    let defaultType = 'INCOME';
    let defaultTypeSubType = 'INCOME::';

    if (!canCreateIncome && canCreateExpense) {
      defaultType = 'EXPENSE';
      defaultTypeSubType = availableExpenseTypesOptions[0].value;
    }

    if (!canCreateIncome && !canCreateExpense && canCreateTransfer) {
      defaultType = 'TRANSFER';
      defaultTypeSubType = 'TRANSFER::';
    }

    return {
      description: '',
      amount: 0,
      paid: false,
      account_id,
      type: defaultType,
      type_sub_type: defaultTypeSubType,
      sub_type: null,
      payment_plan: 'ONE_TIME',
      event_date,
      category_id: null,
      recipient_id: null,
    };
  }, [
    selectedDate,
    account_id,
    canCreateExpense,
    canCreateIncome,
    canCreateTransfer,
    availableExpenseTypesOptions,
  ]);

  const formattedRecipients = useMemo(() => {
    if (isEmpty(recipients)) {
      return [];
    }

    const formatted = recipients.map((recipient) => ({
      value: recipient.id,
      label: recipient.name,
    }));

    formatted.unshift({
      value: null,
      label: '--',
    });

    return formatted;
  }, [recipients]);

  const formattedBankAccounts = useMemo(() => {
    if (isEmpty(accounts)) {
      return [];
    }

    const formatted = accounts.map((account) => ({
      value: account.id,
      label: account.description,
    }));

    return formatted;
  }, [accounts]);

  const handleSubmitForm = useCallback((values, { resetForm }) => {
    setIsLoading(true);

    if (values.type_sub_type) {
      const { type, sub_type } = typeSubtypeStringToObject(values.type_sub_type);

      values.type = type;
      values.sub_type = sub_type;
      values.temp_type = type;
      values.temp_sub_type = sub_type;
    }

    delete values.type_sub_type;

    if (values.type === 'TRANSFER') {
      onCreateTransferTransaction(values, (success) => {
        setIsLoading(false);

        if (success && values.save_and_close) {
          resetForm();
          onToggleMobileTransactionForm();
        }
      });
    } else {
      onCreateTransaction(values, (created_transaction) => {
        setIsLoading(false);

        if (values.save_and_close) {
          resetForm();

          onToggleMobileTransactionForm();
        }

        if (values.save_and_new) {
          toast.success(() => (
            <div>
              <strong>Sucesso!</strong>
              <br />
              <small>
                Transação criada com sucesso.
              </small>
            </div>
          ), {
            autoClose: 3000,
          });

          return;
        }

        if (values.save_and_edit) {
          resetForm();
          onToggleMobileTransactionForm();

          onAfterSaveCallback(created_transaction);
        }
      });
    }
  }, [
    onCreateTransaction,
    onAfterSaveCallback,
    onCreateTransferTransaction,
    onToggleMobileTransactionForm,
  ]);

  const renderPaidLabel = useCallback((type) => {
    if (type === 'INCOME') {
      return 'Já recebi esse valor';
    }

    return 'Já paguei esse valor';
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        setFieldValue,
        setValues,
        values,
      }) => (
        <>
          <FloatingCard
            title="Nova Transação"
            fullHeight
            isVisible={isOpen}
            onToggleVisibility={onToggleMobileTransactionForm}
            withCloseButton
            side="left"
            bodyClassName="p-2 pl-3 pr-3"
          >
            <Form onSubmit={handleSubmit} className="ml-2 mr-2">
              <>
                <Form.Group as={Col} lg={12} sm={12}>
                  <MobileTransactionType
                    onSetSelectedType={(type) => {
                      setFieldValue('type', type);

                      if (type === 'INCOME') {
                        setFieldValue('type_sub_type', 'INCOME::');
                      }

                      if (type === 'EXPENSE') {
                        const [first] = availableExpenseTypesOptions;

                        setFieldValue('type_sub_type', first.value);
                      }

                      if (type === 'TRANSFER') {
                        setFieldValue('type_sub_type', 'TRANSFER::');
                      }
                    }}
                    selectedType={values.type}
                    selectActive={selectActive}
                    setSelectActive={setSelectActive}
                    canCreateIncome={canCreateIncome}
                    canCreateExpense={canCreateExpense}
                    canCreateTransfer={canCreateTransfer}
                  />
                </Form.Group>
                <Form.Row>
                  <Form.Group as={Col} lg={6} className="d-flex flex-row align-middle">
                    <StyledFormCurrencyField
                      selectedType={values.type}
                      className="m-0 p-0"
                      name="amount"
                      placeholder="Valor"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={12} lg={12}>
                    <StyledPaidToggle>
                      <Form.Label className={classNames({
                        'm-0': true,
                        'text-danger': values.type === 'EXPENSE',
                        'text-success': values.type === 'INCOME',
                      })}
                      >
                        {renderPaidLabel(values.type)}
                      </Form.Label>
                      <div className="d-flex justify-content-center">
                        {!values.paid && (
                          <BsToggleOff
                            size="2.2em"
                            className="text-muted"
                            onClick={() => setFieldValue('paid', !values.paid)}
                          />
                        )}
                        {values.paid && (
                          <BsToggleOn
                            size="2.2em"
                            className="text-success"
                            onClick={() => setFieldValue('paid', !values.paid)}
                          />
                        )}
                      </div>
                    </StyledPaidToggle>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-3 mt-3">
                  <FormGroup
                    as={Col}
                    xs={12}
                    label="Descrição"
                    icon={<BsPencil size="1.1em" />}
                  >
                    <FormTextField
                      name="description"
                      placeholder="Descrição"
                      inputStyleProps={{
                        border: 'none',
                        padding: '0',
                        fontSize: '1.3em',
                        color: '#000',
                      }}
                    />
                  </FormGroup>
                </Form.Row>
                <Form.Row className="mb-1">
                  <FormGroup
                    as={Col}
                    xs={6}
                    label="Pagamento"
                    icon={<AiOutlineCalendar size="1.1em" />}
                  >
                    <FormDateField
                      name="event_date"
                      placeholder="Selecione..."
                      withPortal
                      inputStyleProps={{
                        border: 'none',
                        padding: '0',
                        fontSize: '0.9em',
                        color: '#000',
                      }}
                    />
                  </FormGroup>
                  {values.type !== 'TRANSFER' && (
                    <FormGroup
                      as={Col}
                      xs={6}
                      label="Competência"
                      icon={<AiOutlineCalendar size="1.1em" />}
                    >
                      <FormDateField
                        name="due_date"
                        placeholder="Selecione..."
                        withPortal
                        allowClearWithPortal
                        inputStyleProps={{
                          border: 'none',
                          padding: '0',
                          fontSize: '0.9em',
                          color: '#000',
                        }}
                      />
                    </FormGroup>
                  )}
                </Form.Row>
                {values.type === 'EXPENSE' && (
                  <Form.Row>
                    <FormGroup
                      as={Col}
                      lg={12}
                      label="Tipo de Despesa"
                      icon={<IoFilter size="1.1em" />}
                    >
                      <CustomFormSelectField
                        isSearchable={false}
                        name="type_sub_type"
                        options={availableExpenseTypesOptions}
                        loadingMessage={() => 'Carregando...'}
                        placeholder="Selecionar"
                        customStyleName="transactions"
                        label="Tipo de Despesa"
                        width="100%"
                      />
                    </FormGroup>
                  </Form.Row>
                )}
                {values.type !== 'TRANSFER' && (
                  <>
                    <Form.Row className="mb-1">
                      <FormGroup
                        as={Col}
                        xs={12}
                        label={['INCOME'].includes(values.type) ? 'Recebido de' : 'Pago a'}
                        icon={<BsPerson size="1.1em" />}
                      >
                        <CustomFormSelectField
                          isClearable
                          name="recipient_id"
                          options={formattedRecipients}
                          creatable="recipient"
                          label={['INCOME'].includes(values.type) ? 'Recebido de' : 'Pago a'}
                          metadata={{
                            type: null,
                          }}
                          loadingMessage={() => 'Carregando...'}
                          onCreateCallback={(created_recipient) => {
                            if (created_recipient) {
                              setFieldValue('recipient_id', created_recipient.id);
                            }
                          }}
                          onChange={(option) => {
                            setFieldValue('recipient_id', option ? option.value : null);

                            if (!option) {
                              return;
                            }

                            const params = {
                              suggestion_for: 'category_id',
                              recipient_id: option.value,
                            };

                            onLoadCategoriesSuggestions(params, (found) => {
                              const [first] = found || [];

                              if (first && !values.category_id) {
                                setFieldValue('category_id', first.value);
                              }
                            });
                          }}
                          width="100%"
                        />
                      </FormGroup>

                    </Form.Row>
                    <Form.Row className="mb-1">
                      <FormGroup
                        as={Col}
                        xs={12}
                        label="Categoria"
                        icon={<IoBookmarkOutline size="1.1em" />}
                      >
                        <CategorySelect
                          isClearable
                          name="category_id"
                          placeholder="Aplicar a categoria..."
                          onChange={(value) => setFieldValue('category_id', value)}
                          value={values.category_id}
                          typeSubType={values.type_sub_type}
                          label="Categoria"
                        />
                      </FormGroup>
                    </Form.Row>
                  </>
                )}
                {values.type === 'TRANSFER' && (
                  <>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        lg={12}
                        label="Conta de Origem"
                        icon={<BsCreditCard size="1.1em" />}
                      >
                        <CustomFormSelectField
                          isClearable
                          name="account_id"
                          options={formattedBankAccounts}
                          creatable="bank_account"
                          loadingMessage={() => 'Carregando...'}
                          placeholder="Selecionar"
                          customStyleName="transactions"
                          label="Conta de Origem"
                        />
                      </FormGroup>
                    </Form.Row>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        lg={12}
                        label="Conta de Destino"
                        icon={<BsCreditCard size="1.1em" />}
                      >
                        <CustomFormSelectField
                          isClearable
                          name="account_id_destination"
                          options={formattedBankAccounts}
                          creatable="bank_account"
                          loadingMessage={() => 'Carregando...'}
                          placeholder="Selecionar"
                          customStyleName="transactions"
                          label="Conta de Destino"
                        />
                      </FormGroup>
                    </Form.Row>
                  </>
                )}
                {values.type !== 'TRANSFER' && (
                  <>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        lg={12}
                        label="Conta bancária"
                        icon={<BsCreditCard size="1.1em" />}
                      >
                        <CustomFormSelectField
                          name="account_id"
                          options={formattedBankAccounts}
                          creatable="bank_account"
                          label="Conta bancária"
                          loadingMessage={() => 'Carregando...'}
                          onCreateCallback={(created) => {
                            if (created) {
                              setFieldValue('account_id', created.id);
                            }
                          }}
                          width="100%"
                        />
                      </FormGroup>
                    </Form.Row>
                  </>
                )}
              </>
              <Form.Row className="mt-2">
                <Form.Group as={Col} xs={values.type === 'TRANSFER' ? 6 : 4} className="d-flex flex-column justify-content-center align-items-center">
                  <StyledSubmit
                    isLoading={isLoading}
                    isMobile
                    variant="success-2"
                    icon={<VscSaveAs size="1.5em" className="text-white" />}
                    onClick={() => {
                      setValues((prev) => ({
                        ...prev,
                        save_and_close: true,
                        save_and_edit: false,
                        save_and_new: false,
                      }));

                      handleSubmit();
                    }}
                    loadingText=""
                  />
                  <small className="mt-2 text-muted">
                    Salvar
                  </small>
                  <small className="text-muted">
                    & fechar
                  </small>
                </Form.Group>
                {values.type !== 'TRANSFER' && (
                  <Form.Group as={Col} xs={values.type === 'TRANSFER' ? 6 : 4} className="d-flex flex-column justify-content-center align-items-center">
                    <StyledSubmit
                      isLoading={isLoading}
                      isMobile
                      variant="success-2"
                      icon={<VscSaveAs size="1.5em" className="text-white" />}
                      onClick={() => {
                        setValues((prev) => ({
                          ...prev,
                          save_and_edit: true,
                          save_and_close: false,
                          save_and_new: false,
                        }));

                        handleSubmit();
                      }}
                      disabled={values.type === 'TRANSFER'}
                      loadingText=""
                    />
                    <small className="mt-2 text-muted">
                      Salvar
                    </small>
                    <small className="text-muted">
                      & detalhar
                    </small>
                  </Form.Group>
                )}
                <Form.Group as={Col} xs={values.type === 'TRANSFER' ? 6 : 4} className="d-flex flex-column justify-content-center align-items-center">
                  <StyledSubmit
                    isLoading={isLoading}
                    isMobile
                    variant="success-2"
                    icon={<FaPlus className="text-white" />}
                    onClick={() => {
                      setValues((prev) => ({
                        ...prev,
                        save_and_edit: false,
                        save_and_close: false,
                        save_and_new: true,
                      }));

                      handleSubmit();
                    }}
                    loadingText=""
                  />
                  <small className="mt-2 text-muted">
                    Salvar
                  </small>
                  <small className="text-muted">
                    & criar outra
                  </small>
                </Form.Group>
              </Form.Row>
            </Form>
          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

MobileTransactionForm.defaultProps = {
  accounts: [],
  categories: [],
  selectedDate: {},
};

MobileTransactionForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  recipients: PropTypes.array,
  accounts: PropTypes.array,
  categories: PropTypes.array,
  selectedDate: PropTypes.object,
  account_id: PropTypes.string.isRequired,
  onCreateTransaction: PropTypes.func.isRequired,
  onToggleMobileTransactionForm: PropTypes.func.isRequired,
  onCreateTransferTransaction: PropTypes.func.isRequired,
  onAfterSaveCallback: PropTypes.func.isRequired,
};

export default MobileTransactionForm;
