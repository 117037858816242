/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { FaDownload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoArrowDown, IoArrowUp } from 'react-icons/io5';

import { AsyncOperations } from '_components/_shared';
import useAsyncOperations from '_components/_shared/AsyncOperations/useAsyncOperations';

import {
  PageHeader,
  Button,
  Card,
  CardBody,
  Dropzone,
} from '_components/_core';

import {
  NumberIcon,
  StyledUl,
  Summary,
  TotalFound,
} from './styles';

const TRANSACTIONS_SPREADSHEET_URL = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/modelo_movimentacoes_zenply.xlsx';

function TransactionsUpload({
  onUploadTransactionsSpreadsheet,
  onUploadTransactionsSpreadsheetConfirm,
}) {
  const history = useHistory();

  const [isReading, setIsReading] = useState(false);
  const [summary, setSummary] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showAllColumns, setShowAllColumns] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleCompleteRead = useCallback((completedOperation) => {
    const { response } = completedOperation || {};
    const { success, errors: operationErrors, summary: operationSummary } = response || {};

    if (!success) {
      setErrors(operationErrors || []);
      setSelectedFile(null);
    } else {
      setSummary(operationSummary);
      setErrors([]);
    }

    setIsReading(false);
  }, []);

  const handleErrorRead = useCallback(() => {
    setIsReading(false);
  }, []);

  const {
    hasPending: hasPendingRead,
    pendingAsyncOperation: pendingAsyncOperationRead,
    onSetAsyncOperations: onSetAsyncOperationsRead,
  } = useAsyncOperations({
    type: 'TRANSACTIONS_SPREADSHEET_IMPORT_READ',
    showConfirmation: false,
    onComplete: handleCompleteRead,
    onError: handleErrorRead,
  });

  const {
    hasPending: hasPendingConfirm,
    pendingAsyncOperation: pendingAsyncOperationConfirm,
    onSetAsyncOperations: onSetAsyncOperationsConfirm,
  } = useAsyncOperations({
    type: 'TRANSACTIONS_SPREADSHEET_IMPORT_CONFIRM',
    successTitle: 'Importação concluída!',
    successMessage: 'Suas movimentações foram importadas com sucesso!',
    redirectTo: '/transacoes',
  });

  const handleToggleColumns = useCallback(() => {
    setShowAllColumns(!showAllColumns);
  }, [showAllColumns]);

  const handleFilesChanged = useCallback((files, setFieldValue) => {
    if (isEmpty(files)) {
      return;
    }

    const [spreadsheet] = files || [];

    setFieldValue('spreadsheet', spreadsheet);
    setSelectedFile(spreadsheet);
  }, []);

  const renderInstructions = useCallback(() => (
    <>
      <NumberIcon>
        1
      </NumberIcon>
      <h4 className="mt-3">
        Leia as regras de preenchimento
      </h4>
      <p>
        Preencha o arquivo com as suas movimentações. <br /><br />
        Algumas colunas, como a de <b>Tipo de Lancamento</b>, <b>Data Pagamento</b>, <b>Descricao</b>, <b>Valor</b>, <b>Conta</b> e <b>Pago</b>, devem ser obrigatoriamente preenchidas. <br /><br />
        <b>
          Veja como preencher cada coluna:
        </b>
        <StyledUl className="mt-3">
          <li>
            Coluna <b>Tipo de Lancamento</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Você deve preencher com: <b>Recebimentos</b>, <b>Despesas fixas</b>, <b>Despesas variáveis</b>, <b>Pessoas</b> ou <b>Impostos</b>;
              </li>
            </ul>
          </li>
          <li className="mt-2">
            Coluna <b>Data Pagamento</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Insira a data no formato dia/mês/ano (ex.: 01/08/2023);
              </li>
            </ul>
          </li>
          <li className="mt-2">
            Coluna <b>Descricao</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Insira um texto de até 255 caracteres;
              </li>
            </ul>
          </li>
          <li className="mt-2">
            Coluna <b>Valor</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Insira o valor em um formato de número válido (ex.: 1.450,35);
              </li>
              <li>
                Se o valor for negativo, o sistema irá transformar em positivo;
              </li>
            </ul>
          </li>
          <li className="mt-2">
            Coluna <b>Conta</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Preencha o nome exato da conta bancária da a qual a movimentação foi/será paga.
              </li>
              <li>
                Se ela não existir, será criada uma conta nova com o nome declarado.
              </li>
            </ul>
          </li>
          <li className="mt-2">
            Coluna <b>Pago</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Preencha com <b>Sim</b> as movimentações que já foram pagas, e <b>Não</b> com as que ainda não foram pagas;
              </li>
            </ul>
          </li>
          {showAllColumns && (
            <>
              <li className="mt-2">
                Coluna <b>Data Competencia</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Insira a data no formato dia/mês/ano (ex.: 01/08/2023);
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Categoria</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha o nome exato da Categoria da movimentação;
                  </li>
                  <li>
                    Se ela não existir, será criada uma Categoria com o nome declarado;
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Recebido de/Pago a</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha o nome exato do Cliente, Fornecedor ou Funcionário da movimentação;
                  </li>
                  <li>
                    Se ele não existir, será criado um Contato com o nome declarado;
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Detalhes</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Insira um texto de até 255 caracteres;
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Numero do Documento</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Insira um texto de até 255 caracteres;
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Forma de Pagamento</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Você deve preencher com: <b>Dinheiro</b>, <b>PIX</b>, <b>Transferência bancária</b>, <b>Boleto bancário</b>, <b>Cartão de crédito</b>, <b>Cartão de débito</b>, <b>Remessa bancária</b>, <b>Cheque</b>, <b>Débito automático</b>
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Centro de Custo</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha o nome exato do Centro de Custo da movimentação;
                  </li>
                  <li>
                    Se ele não existir, será criado um Centro de Custo com o nome declarado;
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Tags</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com uma ou mais tags separadas por vírgula (ex.: tag1, tag2, tag3);
                  </li>
                </ul>
              </li>
            </>
          )}
        </StyledUl>
        <Button variant="link" className="m-0 p-0" onClick={handleToggleColumns}>
          {showAllColumns ? 'Esconder' : 'Mostrar'} colunas opcionais
          {showAllColumns ? <IoArrowUp className="ml-2" /> : <IoArrowDown className="ml-2" />}
        </Button>
        <Button variant="default" className="d-flex justify-content-center align-items-center mt-4" onClick={() => window.open(TRANSACTIONS_SPREADSHEET_URL, '_blank')}>
          <FaDownload className="mr-2" />
          Baixar modelo
        </Button>
      </p>
    </>
  ), [handleToggleColumns, showAllColumns]);

  const handleSubmit = useCallback((values) => {
    const formData = new FormData();

    setErrors([]);

    formData.append('spreadsheet', values.spreadsheet);

    const successCallback = (asyncOperation) => {
      onSetAsyncOperationsRead([asyncOperation]);
    };

    const errorCallback = () => {
      setIsReading(false);
    };

    setIsReading(true);

    onUploadTransactionsSpreadsheet(formData, successCallback, errorCallback);
  }, [
    onUploadTransactionsSpreadsheet,
    onSetAsyncOperationsRead,
  ]);

  const handleSubmitConfirm = useCallback(() => {
    const formData = new FormData();

    setErrors([]);

    formData.append('spreadsheet', selectedFile);

    const successCallback = (asyncOperation) => {
      onSetAsyncOperationsConfirm([asyncOperation]);
    };

    const errorCallback = () => {
      setIsReading(false);
    };

    const onStart = () => {
      setIsReading(true);
    };

    const onCancel = () => {
      setIsReading(false);
    };

    onUploadTransactionsSpreadsheetConfirm(formData, successCallback, errorCallback, onStart, onCancel);
  }, [
    onUploadTransactionsSpreadsheetConfirm,
    onSetAsyncOperationsConfirm,
    selectedFile,
  ]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title={(
          <div>
            <Button
              variant="icon"
              className="m-0 p-0 mr-3"
              onClick={() => history.goBack()}
            >
              <IoArrowBackOutline size="1.5em" />
            </Button>
            <span>
              Importar Movimentações por planilha
            </span>
          </div>
        )}
        variant="small"
      />
      {hasPendingRead && (
        <AsyncOperations
          hasPending={hasPendingRead}
          pendingAsyncOperation={pendingAsyncOperationRead}
        />
      )}
      {hasPendingConfirm && (
        <AsyncOperations
          hasPending={hasPendingConfirm}
          pendingAsyncOperation={pendingAsyncOperationConfirm}
        />
      )}
      {!hasPendingRead && !hasPendingConfirm && (
        <Row>
          <Col>
            <Card>
              <Formik
                initialValues={{
                  spreadsheet: null,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnMount
              >
                {({
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <CardBody>
                    {!summary && (
                      <Row>
                        <Col md={6} className="mt-4 mt-lg-0">
                          {renderInstructions()}
                        </Col>
                        <Col md={6} className="mt-2 mt-lg-0">
                          <NumberIcon>
                            2
                          </NumberIcon>
                          <h4 className="mt-3">
                            Upload de planilha de movimentações
                          </h4>
                          <p>
                            Salve a planilha assim que você terminar de preenchê-la. Selecione o arquivo no botão abaixo, e clique em "Importar planilha".
                          </p>
                          <Form.Group>
                            <Dropzone
                              onChange={(files) => handleFilesChanged(files, setFieldValue)}
                              accept=".xlsx"
                              showLengthMessage
                              maxFiles={1}
                              forceMaxSize={2 * 1024 * 1024}
                            />
                            <Form.Text className="text-muted mt-3">
                              <b>Atenção:</b> A planilha deve estar no formato <b>.xlsx</b> e não pode ter mais de 2MB.
                            </Form.Text>
                          </Form.Group>
                          <Button
                            variant="success-2"
                            onClick={handleSubmit}
                            disabled={isReading || isEmpty(values.spreadsheet)}
                            isLoading={isReading}
                          >
                            Importar planilha
                          </Button>
                          {!isEmpty(errors) && (
                            <div className="mt-3">
                              <p className="text-danger">
                                <b>Corrija os erros abaixo e tente novamente:</b>
                              </p>
                              <ul>
                                {errors.slice(0, 15).map((error) => (
                                  <li className="text-danger" style={{ fontWeight: '400' }}>
                                    {error}
                                  </li>
                                ))}
                                {errors.slice(15).map((error) => (
                                  <li className="text-danger" style={{ fontWeight: '400' }}>
                                    {error}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                    {summary && (
                      <Row>
                        <Col md={5}>
                          <TotalFound>
                            {summary.Total || 0}
                          </TotalFound>
                          <h4 className="mt-3">
                            dados foram encontrados em sua planilha!
                          </h4>
                          <p>
                            Você está prestes a importar {summary.Total || 0} dados para o Zenply. <br />
                            Verifique abaixo se os itens encontrados estão corretos e clique em Confirmar Importação para continuar.
                          </p>
                          <hr />
                          <div className="d-flex mt-3">
                            <Button
                              variant="default"
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => {
                                setSummary(null);
                                setIsReading(false);
                              }}
                            >
                              Cancelar
                            </Button>
                            <Button
                              variant="success-2"
                              className="d-flex justify-content-center align-items-center ml-3"
                              onClick={() => handleSubmitConfirm(values)}
                            >
                              Confirmar importação
                            </Button>
                          </div>
                        </Col>
                        <Col md={4} className="mt-4 mt-lg-0">
                          <Summary>
                            <li>
                              <b>{summary.Recebimentos || 0}</b> recebimento(s)
                            </li>
                            <li>
                              <b>{summary['Despesas fixas'] || 0}</b> despesa(s) fixa(s)
                            </li>
                            <li>
                              <b>{summary['Despesas variáveis'] || 0}</b> despesa(s) variável(is)
                            </li>
                            <li>
                              <b>{summary.Impostos || 0}</b> imposto(s)
                            </li>
                            <li>
                              <b>{summary.Pessoas || 0}</b> despesa(s) com pessoal
                            </li>
                            <li>
                              <b>{summary['Transferências Entrada'] + summary['Transferências Saída'] || 0}</b> transferências (entrada/saída)
                            </li>
                            <li>
                              <b>{summary.Categorias || 0}</b> categoria(s)
                            </li>
                            <li>
                              <b>{summary.Contas || 0}</b> conta(s)
                            </li>
                          </Summary>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Formik>

            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}

TransactionsUpload.propTypes = {
  onUploadTransactionsSpreadsheet: PropTypes.func.isRequired,
  onUploadTransactionsSpreadsheetConfirm: PropTypes.func.isRequired,
};

export default TransactionsUpload;
