import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import Transactions from './Transactions';

const mapStateToProps = (state) => {
  const { id } = state.auth.user;
  const userPermissions = state.userPermissions.permissions[id] || {};

  const availableTabs = [];

  const getParams = (permissionName) => ({
    permissions: [permissionName],
    userPermissions,
    type: 'all',
  });

  if (hasPermissions(getParams('aba_recebimento_view'))) {
    availableTabs.push('INCOME-null');
  }

  if (hasPermissions(getParams('aba_despesa_fixa_view'))) {
    availableTabs.push('EXPENSE-FIXED_EXPENSE');
  }

  if (hasPermissions(getParams('aba_despesa_variavel_view'))) {
    availableTabs.push('EXPENSE-VARIABLE_EXPENSE');
  }

  if (hasPermissions(getParams('aba_pessoal_view'))) {
    availableTabs.push('EXPENSE-PEOPLE');
  }

  if (hasPermissions(getParams('aba_imposto_view'))) {
    availableTabs.push('EXPENSE-TAXES');
  }

  if (hasPermissions(getParams('aba_transferencia_view'))) {
    availableTabs.push('TRANSFER-null');
  }

  return {
    activeCompany: state.company.activeCompany,
    categories: state.companyTransactions.categories,
    recipients: state.companyTransactions.recipients,
    costsCenter: state.companyTransactions.costsCenter,
    tags: state.companyTransactions.tags,
    preferences: state.user.preferences,
    sorting: state.companyTransactions.sorting,
    availableTabs,
    getParams,
  };
};

const mapDispatchToProps = {
  onFetchTransactions: companyTransactionsActionCreators.fetchAllTransactions,
  onCreateTransaction: companyTransactionsActionCreators.createTransaction,
  onDeleteTransaction: companyTransactionsActionCreators.deleteTransaction,
  onLoadTransactionsPageData: companyTransactionsActionCreators.loadTransactionsPageData,
  onMoveTransactions: companyTransactionsActionCreators.moveTransactions,
  onUpdateMultipleTransactions: companyTransactionsActionCreators.updateMultipleTransactions,
  onApplyTagMultipleTransactions: companyTransactionsActionCreators.applyTagMultipleTransactions,
  onSortTransactions: companyTransactionsActionCreators.sortTransactions,
  onClearDuplicatedTransactions: companyTransactionsActionCreators.clearDuplicatedTransactions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions([
    'aba_recebimento_view',
    'aba_despesa_fixa_view',
    'aba_despesa_variavel_view',
    'aba_pessoal_view',
    'aba_imposto_view',
    'aba_transferencia_view',
  ], 'any'),
)(Transactions);
