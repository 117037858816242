/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import {
  IoBookmarkOutline,
  IoClose,
  IoPersonOutline,
  IoSearch,
} from 'react-icons/io5';
import { FaLock } from 'react-icons/fa';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import { ButtonGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Mark from 'mark.js';

import FORMATTERS from 'helpers/formatters';
import { MonthPicker, ActiveFilters, Tag } from '_components/_core';
import { TransactionForm } from '_components/_shared';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import MobileTransactionForm from '../MobileTransactionForm/MobileTransactionFormContainer';
import ActionButton from '../ActionButton';
import useTransactions from '../../utilities/useTransactions';
import FrequencyModal from '../FrequencyModal/FrequencyModalContainer';

import {
  Content,
  DateDisplay,
  Description,
  Empty,
  EmptyText,
  Header,
  List,
  ListItem,
  Loading,
  Spinner,
  TransactionsListMobileContainer,
  Value,
  PaidUnpaid,
  Category,
  StyledDate,
  StyledDescriptionContainer,
  Subheader,
  ActiveFiltersContainer,
  HeaderBalance,
  StyledChevron,
  IconContainer,
} from './styles';

import BankAccountOverview from '../BankAccountOverview/BankAccountOverviewContainer';
import TransactionFilters from '../TransactionFilters/TransactionFiltersMobile';
import { FILTERS_CONFIGURATION } from '../TransactionFilters';

import filterTransactions from '../../utilities/filterTransactions';
import TransactionActionSheet from './components/TransactionActionSheet/TransactionActionSheet';
import TransactionSorting from './components/TransactionSorting/TransactionSorting';
import TransactionSummary from './components/TransactionSummary/TransactionSummary';
import TransactionPaymentAlert from './components/TransactionPaymentAlert/TransactionPaymentAlert';

const translation = {
  WEEKLY: 'semanal',
  BIWEEKLY: 'quinzenal',
  MONTHLY: 'mensal',
  BIMONTHLY: 'bimestral',
  QUARTERLY: 'trimestral',
  BIANNUAL: 'semestral',
  ANNUAL: 'anual',
};

function TransactionsListMobile({
  transactionFilters,
  availableTabs,
  sorting,
  onUpdateTransactionFilters,
  onUpdateMultipleTransactions,
  onMoveTransactions,
  onSortTransactions,
  isTablet,
  preferences,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [isDayAlertOpen, setIsDayAlertOpen] = useState(false);
  const [dayAlertTransaction, setDayAlertTransaction] = useState({});
  const [isMobileOpen, setMobileOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(null);

  const inputRef = React.useRef(null);

  const [searchCriteria, setSearchCriteria] = useState('');
  const [textFilter, setTextFilter] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [actionSheetView, setActionSheetView] = useState('main');

  const markInstance = new Mark(document.querySelector('#transactions-list'));

  const {
    isLoading,
    transactions: allTransactions,
    selectedDate,
    onChangeDate,
    onRefreshBalance,
    account_id,
    onToggleTransactionPaid,
    accounts,
    onCreateTransaction,
    onCreateTransferTransaction,
    onDeleteTransaction,
    onDuplicateTransaction,
    onDuplicateMultipleTransactions,
  } = useTransactions();

  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  const canViewIncomes = useMemo(() => hasPermissions({
    permissions: ['view_month_incomes'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canViewExpenses = useMemo(() => hasPermissions({
    permissions: ['view_month_expenses'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canViewForecast = useMemo(() => hasPermissions({
    permissions: ['view_month_forecast_result'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canCreateTransaction = useMemo(() => hasPermissions({
    permissions: [
      'aba_recebimento_create',
      'aba_despesa_fixa_create',
      'aba_despesa_variavel_create',
      'aba_pessoal_create',
      'aba_imposto_create',
      'aba_transferencia_create',
    ],
    userPermissions,
    type: 'any',
  }), [userPermissions]);

  const canEditTransaction = useCallback((transaction) => {
    const { type, sub_type } = transaction;

    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-null': 'aba_transferencia_edit',
      'TRANSFER-SENT': 'aba_transferencia_edit',
      'TRANSFER-RECEIVED': 'aba_transferencia_edit',
    };

    return hasPermissions({
      permissions: [permissionMap[`${type}-${sub_type}`]],
      userPermissions,
      type: 'all',
    });
  }, [userPermissions]);

  const handleToggleActionSheet = useCallback(() => {
    setIsActionSheetOpen((prev) => {
      if (prev) {
        setActionSheetView('main');
      }
      return !prev;
    });
    setSelectedTransaction(selectedTransaction);
  }, [selectedTransaction]);

  const handleModalToggle = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setPaymentPlan(null);
  }, [isModalOpen]);

  const handleToggleForm = useCallback(() => {
    const newState = !isOpen;

    if (!newState) {
      setSelectedTransaction({});
    }

    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleToggleMobileForm = useCallback(() => {
    setMobileOpen(!isMobileOpen);
  }, [isMobileOpen]);

  const handleEditTransaction = useCallback((transaction) => {
    if (!canEditTransaction(transaction)) {
      return;
    }

    setSelectedTransaction(transaction);
    setIsOpen(true);
  }, [canEditTransaction]);

  const handleShowActionSheet = useCallback((transaction) => {
    setSelectedTransaction(transaction);
    setIsActionSheetOpen(true);
  }, []);

  const filterByAvailableTabs = (transactions, availableTabs) => {
    const tabMap = availableTabs.map((tab) => {
      const [type, subType] = tab.split('-');
      return { type, subType };
    });

    return transactions.filter(
      (transaction) => tabMap.some(
        (tab) => tab.type === transaction.type && (tab.subType === transaction.sub_type || tab.subType === 'null'),
      ),
    );
  };

  const transactions = useMemo(() => {
    if (isEmpty(allTransactions)) {
      return [];
    }

    let filteredTransactions = filterByAvailableTabs(allTransactions, availableTabs);

    if (textFilter) {
      filteredTransactions = filterTransactions(allTransactions, textFilter);
    }

    return filteredTransactions;
  }, [allTransactions, textFilter, availableTabs]);

  const handleCloseDayAlert = useCallback(() => {
    setIsDayAlertOpen(false);
    setDayAlertTransaction({});
  }, []);

  const handleToggleTransactionPaid = useCallback((e, transaction) => {
    e.stopPropagation();

    if (!canEditTransaction(transaction)) {
      return;
    }

    const isPaid = !transaction.paid;

    onToggleTransactionPaid(transaction.id, isPaid, () => {
      const { show_old_items_payment_confirmation } = preferences || {};
      const isSameDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' }).toFormat('yyyy-MM-dd') === DateTime.now().toFormat('yyyy-MM-dd');

      if (isPaid && !isSameDate && show_old_items_payment_confirmation) {
        setIsDayAlertOpen(true);
        setDayAlertTransaction(transaction);
      }
    });
  }, [onToggleTransactionPaid, preferences, canEditTransaction]);

  const groupedTransactions = groupBy(transactions, 'event_date');
  const days = Object.keys(groupedTransactions);

  const delayedOnFilter = (query) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      setTextFilter(query);

      markInstance.unmark({
        done: () => {
          markInstance.mark(query);
        },
      });
    }, 475);

    setTypingTimeout(newTypingTimeout);
  };

  const handleInputChange = (e) => {
    const searchValue = e.target.value;

    setSearchCriteria(searchValue);

    delayedOnFilter(searchValue.trim());
  };

  const renderRecurrence = (payment_plan, instalment_text, recurrent_Text) => {
    if (payment_plan === 'INSTALMENT') {
      return (
        <Tag className="mr-1 p-0 pl-1 pr-1" variant="payment_plan" style={{ fontSize: '0.75em' }}>
          {instalment_text}
        </Tag>
      );
    }

    if (payment_plan === 'RECURRENT') {
      return (
        <Tag className="mr-1 p-0 pl-1 pr-1" variant="payment_plan" style={{ fontSize: '0.75em' }}>
          {recurrent_Text}
        </Tag>
      );
    }

    return null;
  };

  const handleShowOptions = (e, transaction) => {
    e.stopPropagation();

    handleShowActionSheet(transaction);
  };

  const hasFilters = useMemo(() => {
    if (!transactionFilters) {
      return false;
    }

    const keys = Object.keys(transactionFilters).filter((key) => key !== 'uiValues');

    return !isEmpty(keys);
  }, [transactionFilters]);

  return (
    <TransactionsListMobileContainer style={{
      marginBottom: '180px',
    }}
    >
      <FrequencyModal
        transaction={selectedTransaction}
        isVisible={isModalOpen}
        paymentPlan={paymentPlan}
        onModalToggle={handleModalToggle}
        selectedDate={selectedDate}
      />
      <TransactionForm
        isOpen={isOpen}
        disabledFields={[]}
        selectedDate={selectedDate}
        transaction={selectedTransaction}
        transaction_id={selectedTransaction ? selectedTransaction.id : null}
        onToggleForm={handleToggleForm}
        onAfterSaveCallback={() => {
          handleToggleForm();
          onRefreshBalance();
        }}
        hiddenFields={[
          'type_sub_type',
        ]}
      />
      <MobileTransactionForm
        isOpen={isMobileOpen}
        transaction={selectedTransaction}
        onToggleMobileTransactionForm={handleToggleMobileForm}
        onAfterSaveCallback={(created_transaction) => {
          handleEditTransaction(created_transaction);
        }}
        onCreateTransaction={onCreateTransaction}
        onCreateTransferTransaction={onCreateTransferTransaction}
        selectedDate={selectedDate}
        account_id={account_id}
        mode="report-edit"
      />
      <TransactionActionSheet
        isOpen={isActionSheetOpen}
        selectedTransaction={selectedTransaction}
        onToggle={handleToggleActionSheet}
        actionSheetView={actionSheetView}
        onChangeActionSheetView={setActionSheetView}
        onMoveTransactions={onMoveTransactions}
        onDeleteTransaction={onDeleteTransaction}
        onDuplicateTransaction={onDuplicateTransaction}
        onDuplicateMultipleTransactions={onDuplicateMultipleTransactions}
        onChangePaymentPlan={setPaymentPlan}
        onChangeModalVisibility={setIsModalOpen}
        onRefreshBalance={onRefreshBalance}
      />
      <Header>
        <DateDisplay>
          <MonthPicker
            value={selectedDate}
            onChange={onChangeDate}
            variant="transactions"
          />
        </DateDisplay>
        <HeaderBalance>
          <BankAccountOverview variant="transactions-mobile" />
        </HeaderBalance>
      </Header>
      <Subheader>
        <IoSearch size="1.5em" className="ml-2" />
        <Form.Control
          ref={inputRef}
          type="text"
          placeholder="Pesquisar na lista"
          style={{
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
            marginLeft: '0.5em',
          }}
          onChange={handleInputChange}
          value={searchCriteria}
          onFocus={(e) => e.target.select()}
        />
        {searchCriteria && (
          <IoClose
            style={{
              cursor: 'pointer',
            }}
            size="1.5em"
            className="mr-2 text-danger"
            onClick={() => {
              handleInputChange({ target: { value: '' } });

              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          />
        )}
        <ButtonGroup className="mr-2">
          <TransactionSorting
            sorting={sorting}
            onSortTransactions={onSortTransactions}
          />
          <TransactionFilters
            transactionFilters={transactionFilters}
            onUpdateTransactionFilters={onUpdateTransactionFilters}
            isMobile
          />
        </ButtonGroup>
      </Subheader>
      {hasFilters && (
        <ActiveFiltersContainer>
          <ActiveFilters
            filtersConfiguration={FILTERS_CONFIGURATION}
            filters={transactionFilters.uiValues || {}}
            onFilter={onUpdateTransactionFilters}
            isMobile
          />
        </ActiveFiltersContainer>
      )}
      <Content style={{
        backgroundColor: 'white',
        marginLeft: '-16px',
        marginRight: '-16px',
      }}
      >
        {isLoading && (
          <Loading>
            <Spinner />
          </Loading>
        )}
        {!isLoading && transactions.length === 0 && (
          <Empty>
            <EmptyText>Nenhuma transação encontrada</EmptyText>
          </Empty>
        )}
        {!isLoading && transactions.length > 0 && (
          <List id="transactions-list">
            {days.map((day) => (
              <>
                <StyledDate>
                  {FORMATTERS.DATE_DDMMYYYY(day)}
                </StyledDate>
                <div>
                  {groupedTransactions[day].map((transaction) => {
                    let description = 'Sem descrição';
                    let recipientName = 'Sem contato';
                    let categoryName = 'Sem categoria';
                    const isTransfer = transaction.type === 'TRANSFER';

                    const {
                      payment_plan,
                      frequency_number,
                      frequency_total,
                      frequency_type,
                    } = transaction || {};

                    const instalment_text = `${frequency_number} / ${frequency_total} ${translation[frequency_type]}`;
                    const reccurent_text = 'recorrente';

                    if (transaction.description) {
                      description = transaction.description;
                    }

                    if (transaction.recipient && transaction.recipient.name) {
                      recipientName = transaction.recipient.name;
                    }

                    if (transaction.category && transaction.category.description) {
                      categoryName = transaction.category.description;
                    }

                    if (isTransfer) {
                      recipientName = 'Transferência';
                      categoryName = FORMATTERS.TRANSACTION_LINE_MOBILE_TRANSFER(
                        accounts,
                        transaction,
                      );
                    }

                    return (
                      <ListItem
                        key={transaction.id}
                        onClick={() => handleEditTransaction(transaction)}
                      >
                        <StyledDescriptionContainer>
                          <Description>
                            {FORMATTERS.MOBILE_DESCRIPTION(description)}
                          </Description>
                          <Category>
                            <span className="d-flex align-items-center">
                              {!isTransfer && <IoPersonOutline className="mr-1" size="0.9em" />}
                              {!isTransfer ? FORMATTERS.MAX_X_CHARS(recipientName, 12) : ''}
                            </span>
                            {!isTransfer && (
                              <>
                                &nbsp;|&nbsp;
                              </>
                            )}
                            <span className="d-flex align-items-center">
                              {!isTransfer && <IoBookmarkOutline className="mr-1" size="0.9em" />}
                              {!isTransfer ? FORMATTERS.MAX_X_CHARS(categoryName, 12) : categoryName}
                            </span>
                          </Category>
                        </StyledDescriptionContainer>
                        <Value>
                          {FORMATTERS.MOBILE_REPORT_AMOUNT(
                            transaction.amount,
                            transaction.type,
                            transaction.sub_type,
                          )}
                          <div className="d-flex justify-content-end align-items-center">
                            {renderRecurrence(payment_plan, instalment_text, reccurent_text)}
                            {!canEditTransaction(transaction) && (
                              <span className="text-muted d-flex justify-content-center align-items-center">
                                <FaLock className="mr-1" size="0.9em" />
                              </span>
                            )}
                            <PaidUnpaid>
                              {!transaction.paid && <BsToggleOff size="2.5em" className="ml-2 text-muted" onClick={(e) => handleToggleTransactionPaid(e, transaction)} />}
                              {transaction.paid && <BsToggleOn size="2.5em" className="ml-2 text-success" onClick={(e) => handleToggleTransactionPaid(e, transaction)} />}
                            </PaidUnpaid>
                          </div>
                        </Value>
                        <IconContainer onClick={(e) => handleShowOptions(e, transaction)}>
                          <StyledChevron size="1em" className="ml-3 text-muted" />
                        </IconContainer>
                      </ListItem>
                    );
                  })}
                </div>
              </>
            ))}
          </List>
        )}
        <TransactionSummary
          canViewExpenses={canViewExpenses}
          canViewIncomes={canViewIncomes}
          canViewForecast={canViewForecast}
          transactions={transactions}
          transactionFilters={transactionFilters}
          searchCriteria={searchCriteria}
          isTablet={isTablet}
        />
        <TransactionPaymentAlert
          isOpen={isDayAlertOpen}
          transaction={dayAlertTransaction}
          onClose={handleCloseDayAlert}
          onUpdateMultipleTransactions={onUpdateMultipleTransactions}
        />
      </Content>
      {canCreateTransaction && (
        <ActionButton
          type={null}
          subType={null}
          onToggleMobileTransactionForm={handleToggleMobileForm}
          isMobile
        />
      )}
    </TransactionsListMobileContainer>
  );
}

TransactionsListMobile.defaultProps = {
  transactionFilters: {},
  isMobile: false,
  isTablet: false,
  preferences: {},
};

TransactionsListMobile.propTypes = {
  availableTabs: PropTypes.array.isRequired,
  transactionFilters: PropTypes.object,
  onUpdateTransactionFilters: PropTypes.func.isRequired,
  onMoveTransactions: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  muted: PropTypes.bool,
  canView: PropTypes.bool,
  type: PropTypes.string.isRequired,
  sorting: PropTypes.object,
  subType: PropTypes.string.isRequired,
  onSortTransactions: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  preferences: PropTypes.object,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
};

export default TransactionsListMobile;
