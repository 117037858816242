/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  IoCloseCircle,
  IoLogOutOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { FcSettings } from 'react-icons/fc';

import { ActionSheet, Button } from '_components/_core';
import { ZENPLY_TERMS_URL, ZENPLY_PRIVACY_POLICY_URL } from 'helpers';
import {
  CustomFilterButton,
  FilterMenu,
  FilterDescription,
  InputContainer,
  StyledDropdown,
  Footer,
  CustomButton,
} from './styles';

const public_folder = process.env.PUBLIC_URL;

const SettingsMenu = ({
  id,
  className,
  isMobile,
  onLogout,
  variant,
  user,
  onOpenStateChange,
}) => {
  const [open, setOpen] = useState(false);
  const [actionSheetOpen, setActionSheetOpen] = useState(false);

  const handleToggle = useCallback((isOpen, ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
    onOpenStateChange(isOpen);
  }, [onOpenStateChange]);

  const handleNavigate = useCallback(() => {
    setOpen(false);
    setActionSheetOpen(false);

    onOpenStateChange(false);
  }, [onOpenStateChange]);

  const isManager = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null;
  }, [user]);

  const shouldHideUsersAndPermissions = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null && user.document_type === 'PF';
  }, [user]);

  const handleClose = useCallback(() => {
    setActionSheetOpen(false);
  }, []);

  const Icon = ({ name }) => (
    <img
      src={`${public_folder}/assets/icons/${name}.png`}
      alt="Zenply"
      width="30"
      height="30"
      className="mr-2"
    />
  );

  const SettingsBody = useCallback(() => (
    <InputContainer isMobile={isMobile}>
      <Row>
        <Col sm={12} md={4}>
          <ul>
            <li className="title">
              Empresa atual
            </li>
            <li>
              <Link to="/contas-bancarias" onClick={handleNavigate}>
                <Icon name="icon-contas-bancarias" />
                Contas bancárias
              </Link>
            </li>
            <li>
              <Link to="/categorias" onClick={handleNavigate}>
                <Icon name="icon-categorias" />
                Categorias
              </Link>
            </li>
            <li>
              <Link to="/centros-de-custo" onClick={handleNavigate}>
                <Icon name="icon-centro-de-custos" />
                Centros de custo
              </Link>
            </li>
            <li>
              <Link to="/tags" onClick={handleNavigate}>
                <Icon name="icon-tags" />
                Marcadores (tags)
              </Link>
            </li>
            {isManager && (
              <li>
                <Link to="/excluir-dados" onClick={handleNavigate} className="text-danger">
                  Excluir dados
                </Link>
              </li>
            )}
            <li className="title mt-0 mt-md-3">
              Importações
            </li>
            <li>
              <Link to="/importar" onClick={handleNavigate}>
                <Icon name="icone-conciliacao" />
                Movimentações (.xlsx)
              </Link>
            </li>
            <li>
              <Link to="/importar-extrato" onClick={handleNavigate}>
                <Icon name="icone-conciliacao" />
                Extrato bancário (.ofx)
              </Link>
            </li>
          </ul>
        </Col>
        <Col sm={12} md={4}>
          <ul>
            <li className="title">
              Geral
            </li>
            <li>
              <Link to="/empresas" onClick={handleNavigate}>
                <Icon name="icon-empresas" />
                Empresas
              </Link>
            </li>
            {!shouldHideUsersAndPermissions && (
            <>
              <li>
                <Link to="/usuarios" onClick={handleNavigate}>
                  <Icon name="icon-contatos" />
                  Usuários
                </Link>
              </li>
              <li>
                <Link to="/permissoes" onClick={handleNavigate}>
                  <Icon name="icon-permissoes" />
                  Permissões
                </Link>
              </li>
            </>
            )}
            <li>
              <Link to="/logs" onClick={handleNavigate}>
                <Icon name="icon-logs-atividades" />
                Logs de atividades
              </Link>
            </li>
            <li className="title mt-0 mt-md-3">Documentos</li>
            <li className="text-muted">
              <Link to="/recibos" onClick={handleNavigate}>
                <Icon name="icon-recibos" />
                Modelos de Recibos
              </Link>
            </li>
          </ul>
        </Col>
        <Col sm={12} md={4}>
          <ul>
            <li className="title">
              Minha conta
            </li>
            <li>
              <Link to="/meu-perfil" onClick={handleNavigate}>
                <Icon name="icon-dados-pessoais" />
                Dados pessoais
              </Link>
            </li>
            <li>
              <Link to="/meu-plano" onClick={handleNavigate}>
                <Icon name="icon-assinatura" />
                Minha assinatura
              </Link>
            </li>
            <li>
              <Link to="/preferencias" onClick={handleNavigate}>
                <Icon name="icon-bell" />
                Preferências
              </Link>
            </li>
            <li>
              <Link to="/pagamentos" onClick={handleNavigate}>
                <Icon name="icon-historico" />
                Hist. de pagamentos
              </Link>
            </li>
            <li>
              <Link to="/exportar-dados" onClick={handleNavigate}>
                <Icon name="icon-backup" />
                Exportar dados
              </Link>
            </li>
            <li className="title mt-0 mt-md-3">
              Privacidade
            </li>
            <li>
              <Link to={{ pathname: ZENPLY_PRIVACY_POLICY_URL }} target="_blank" onClick={handleNavigate}>
                <Icon name="icon-recibos" />
                Política de privacidade
              </Link>
            </li>
            <li>
              <Link to={{ pathname: ZENPLY_TERMS_URL }} target="_blank">
                <Icon name="icon-recibos" />
                Termos de uso
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </InputContainer>
  ), [isMobile, isManager, shouldHideUsersAndPermissions, handleNavigate]);

  if (isMobile) {
    return (
      <>
        <CustomFilterButton onClick={() => setActionSheetOpen(true)} variant="link">
          <IoSettingsOutline size="1.3em" />
        </CustomFilterButton>
        <ActionSheet
          isOpen={actionSheetOpen}
          onToggle={() => setActionSheetOpen(!actionSheetOpen)}
          detent="content-height"
          prefersReducedMotion
          header={(
            <div className="ml-3 mr-3 d-flex justify-content-between align-items-center">
              <h5 className="m-0 mb-3">
                Configurações
              </h5>
              <IoCloseCircle className="text-muted" size="2em" onClick={handleClose} />
            </div>
          )}
        >
          <div>
            <SettingsBody />
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="link"
                className="m-0 p-0 pb-4 d-flex justify-content-center align-items-center text-muted"
                onClick={onLogout}
              >
                <IoLogOutOutline size="1.3em" className="mr-2" />
                Sair do sistema
              </Button>
            </div>
          </div>
        </ActionSheet>
      </>
    );
  }

  return (
    <StyledDropdown
      id={id}
      show={open}
      onToggle={handleToggle}
      className={className}
      drop={variant === 'sidebar' ? 'right' : 'down'}

    >
      {variant === 'navbar' && (
        <CustomFilterButton variant="link" as={Dropdown.Toggle}>
          <IoSettingsOutline size="1.3em" />
        </CustomFilterButton>
      )}
      {variant === 'sidebar' && (
        <CustomButton
          variant="link"
          as={Dropdown.Toggle}
          style={{
            textDecoration: 'none',
          }}
          onClick={() => {
            setOpen(!open);

            onOpenStateChange(!open);
          }}
        >
          <span onClick={(e) => { e.preventDefault(); }}>
            <div>
              <FcSettings size="1.5em" />
            </div>
            <p>Configurações</p>
          </span>
        </CustomButton>
      )}
      <FilterMenu variant={variant} isMobile={isMobile}>
        <FilterDescription>
          Configurações
        </FilterDescription>
        <SettingsBody />
        <Footer>
          <Button
            variant="link"
            className="m-0 p-0 pt-2 pb-2 d-flex justify-content-center align-items-center text-muted"
            onClick={onLogout}
          >
            <IoLogOutOutline size="1.3em" className="mr-2" />
            Sair do sistema
          </Button>
        </Footer>
      </FilterMenu>
    </StyledDropdown>
  );
};

export default SettingsMenu;

SettingsMenu.defaultProps = {
  id: null,
  className: null,
  isMobile: false,
  variant: 'navbar',
  user: null,
  onOpenStateChange: () => {},
};

SettingsMenu.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['navbar', 'sidebar']),
  user: PropTypes.object,
  onOpenStateChange: PropTypes.func,
  name: PropTypes.string,
};
