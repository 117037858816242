import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';

const getPreviousAndNextCompetencesWithLuxon = (competence) => {
  const date = DateTime.fromFormat(competence, 'yyyy-MM');
  const previousDate = date.minus({ months: 1 });
  const nextDate = date.plus({ months: 1 });

  return [
    previousDate.toFormat('MMMM', { locale: 'pt-BR' }),
    date.toFormat('MMMM', { locale: 'pt-BR' }),
    nextDate.toFormat('MMMM', { locale: 'pt-BR' }),
  ];
};

const getEmptyOptions = (competence) => {
  const formattedCompetences = getPreviousAndNextCompetencesWithLuxon(competence);

  const empty = {
    0: {
      paid_incomes: 0,
      unpaid_incomes: 10000,
      paid_expenses: 0,
      unpaid_expenses: -10000,
      net_amount: 0,
    },
    1: {
      paid_incomes: 0,
      unpaid_incomes: 35000,
      paid_expenses: 0,
      unpaid_expenses: -18000,
      net_amount: 17000,
    },
    2: {
      paid_incomes: 0,
      unpaid_incomes: 43000,
      paid_expenses: 0,
      unpaid_expenses: -18000,
      net_amount: 25000,
    },
  };

  const unpaidIncomes = formattedCompetences.map((item, index) => empty[index].unpaid_incomes);
  const paidIncomes = formattedCompetences.map((item, index) => empty[index].paid_incomes);
  const unpaidExpenses = formattedCompetences.map((item, index) => empty[index].unpaid_expenses);
  const paidExpenses = formattedCompetences.map((item, index) => empty[index].paid_expenses);
  const netAmounts = formattedCompetences.map((item, index) => empty[index].net_amount);

  return {
    chart: {
      spacingTop: 25,
      backgroundColor: 'transparent',
      height: 220,
    },
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    title: false,
    xAxis: [{
      categories: formattedCompetences,
      lineColor: '#D4D7DC',
      tickColor: '#D4D7DC',
      fontWeight: 'bold',
      labels: {
        style: {
          color: '#8D9096',
          fontFamily: 'Poppins',
        },
      },
      showFirstLabel: !0,
    }],
    yAxis: {
      title: false,
      lineColor: '#D4D7DC',
      gridLineColor: '#D4D7DC',
      minorGridLineColor: '#F4F5F8',
      minorGridLineWidth: 1,
      minorTickLength: 0,
      minorTickInterval: 'auto',
      labels: {
        style: {
          color: '#8D9096',
          fontFamily: 'Poppins',
        },
        y: 2,
        formatter() {
          return `${FORMATTERS.NUMBER(this.value)}`;
        },
      },
      plotLines: [{
        color: '#393A3D',
        value: 0,
        width: 3,
        zIndex: 5,
      }],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      borderRadius: 2,
      shadow: false,
      style: {
        fontFamily: 'Poppins',
      },
      formatter() {
        return `${this.point.series.name}: <strong>${FORMATTERS.NUMBER(this.y)}</strong>`;
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: !0,
        },
      },
      column: {
        pointPadding: 0.15,
        groupPadding: 0.15,
        borderWidth: 0,
        borderRadius: 1,
        shadow: false,
        stacking: 'normal',
      },
    },
    series: [
      {
        type: 'column',
        name: 'A Receber',
        data: unpaidIncomes,
        color: 'url(#income-empty-pattern)',
      },
      {
        type: 'column',
        name: 'Recebido',
        color: '#4CCEAC',
        data: paidIncomes,
      },
      {
        type: 'column',
        name: 'A Pagar',
        color: 'url(#expense-empty-pattern)',
        data: unpaidExpenses,
      },
      {
        type: 'column',
        name: 'Pago',
        color: '#DB504A',
        data: paidExpenses,
      },
      {
        type: 'line',
        name: 'Saldo',
        color: '#393A3D',
        lineWidth: 1,
        dashStyle: 'dot',
        marker: {
          radius: 3,
        },
        data: netAmounts,
      },
    ],
  };
};

export {
  getPreviousAndNextCompetencesWithLuxon,
  getEmptyOptions,
};
