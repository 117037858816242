import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { TRANSACTIONS_ACCOUNTS_TYPE_OPTIONS } from 'helpers/constants';

import {
  Button,
  FormTextField,
  FormSelectField,
  FormCurrencyField,
} from '_components/_core';

import FormInitialBalanceField from '_components/Financial/Companies/Create/components/FormInitialBalanceField/FormInitialBalanceField';

import {
  StyledModal,
  ModalFooter,
} from './styles';

import { ValidationSchema } from '../../utilities';

const AccountsModal = ({
  account,
  isVisible,
  isLoading,
  onModalToggle,
  onAddTransactionAccount,
  onUpdateTransactionAccount,
}) => {
  const handleAddEditAccount = useCallback((values, { resetForm, setSubmitting }) => {
    setSubmitting(true);

    if (values.id) {
      onUpdateTransactionAccount(values.id, values, () => {
        resetForm();
        onModalToggle();
        setSubmitting(false);
      });
    } else {
      onAddTransactionAccount(values, () => {
        resetForm();
        onModalToggle();
        setSubmitting(false);
      });
    }
  }, [onAddTransactionAccount, onUpdateTransactionAccount, onModalToggle]);

  const renderModalFooter = useCallback((handleSubmit, isValid, isSubmitting) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading || isSubmitting}
        disabled={!isValid || isLoading}
      >
        {account ? 'Salvar alterações' : 'Cadastrar'}
      </Button>
    </ModalFooter>
  ), [onModalToggle, account, isLoading]);

  const getBalanceType = useCallback(() => {
    if (account) {
      if (account.initial_balance > 0) {
        return 'POSITIVO';
      }

      if (account.initial_balance < 0) {
        return 'NEGATIVO';
      }

      return 'ZERADO';
    }

    return 'POSITIVO';
  }, [account]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        id: account ? account.id : null,
        account_type: account ? account.account_type : 'CHECKING',
        description: account ? account.description : null,
        initial_balance: account ? account.initial_balance : 0,
        is_principal: false,
        balance_type: getBalanceType(),
      }}
      onSubmit={handleAddEditAccount}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isValid, isSubmitting }) => (
        <>
          <StyledModal
            title={account ? 'Editar Conta' : 'Cadastrar Nova Conta'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid, isSubmitting)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Tipo de Conta</Form.Label>
                  <FormSelectField
                    name="account_type"
                    options={TRANSACTIONS_ACCOUNTS_TYPE_OPTIONS}
                    placeholder="Tipo de Conta"
                  />
                </Form.Group>
                <Form.Group as={Col} md="8">
                  <Form.Label>Conta</Form.Label>
                  <FormTextField
                    name="description"
                    placeholder="Conta"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Saldo Inicial</Form.Label>
                  <FormCurrencyField name="initial_balance" placeholder="Saldo Inicial" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <FormInitialBalanceField name="balance_type" className="mt-0" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default AccountsModal;

AccountsModal.propTypes = {
  account: PropTypes.object,
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
  onAddTransactionAccount: PropTypes.func.isRequired,
  onUpdateTransactionAccount: PropTypes.func.isRequired,
};
