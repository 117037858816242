import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { CgSpinnerTwo } from 'react-icons/cg';
import FORMATTERS from 'helpers/formatters';

export default function LoadingIcon({ className, uppercase = false, text }) {
  return (
    <span className={className} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CgSpinnerTwo className="icon-spin" />
      {!isEmpty(text) && (
        <>
          &nbsp;&nbsp;&nbsp;
        </>
      )}
      {uppercase ? FORMATTERS.UPPERCASE(text) : text}
    </span>
  );
}

LoadingIcon.defaultProps = {
  uppercase: false,
  text: 'Aguarde',
  className: '',
};

LoadingIcon.propTypes = {
  className: PropTypes.string,
  uppercase: PropTypes.bool,
  text: PropTypes.string,
};
