/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';

import { Button } from '_components/_core';
import { StyledName, StyledTag, StyledText } from './styles';

function SubscriptionAlerts() {
  const [isVisible, setIsVisible] = useState(() => {
    const dismissed = localStorage.getItem('zenply-alert');

    if (dismissed === 'dismissed') {
      return false;
    }

    return true;
  });

  const handleViewMore = useCallback(() => {
    window.open('https://zenply.com.br/post/zenpaper-agora-e-zenply', '_blank');
  }, []);

  const handleDismissAlert = useCallback(() => {
    localStorage.setItem('zenply-alert', 'dismissed');

    setIsVisible(false);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <StyledTag variant="default" className="p-4">
        <div className="d-flex">
          <span className="d-flex justify-content-center">
            <svg width="40" viewBox="0 0 220 224" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.865 81.5674C6.51811 81.5674 0.563599 87.6434 0.563599 95.1385V210.242C0.563599 217.737 6.51811 223.813 13.865 223.813H126.666C134.011 223.813 139.967 217.737 139.967 210.24V162.304H88.8875C82.7379 162.304 76.8313 159.194 73.7489 153.764C69.818 146.841 70.9948 138.337 76.39 132.832L125.939 82.2726C126.193 82.0125 126.012 81.5674 125.651 81.5674H13.865Z" fill="#4FCC1B" />
              <path d="M94.0112 143.06H205.797C213.143 143.06 219.099 136.982 219.099 129.487V14.3871C219.099 6.89024 213.143 0.8125 205.797 0.8125H92.9969C85.6516 0.8125 79.6971 6.89024 79.6971 14.3853V62.323H130.775C136.924 62.323 142.833 65.4335 145.915 70.8636C149.846 77.7862 148.668 86.2884 143.274 91.7954L93.7256 142.355C93.4707 142.615 93.6503 143.06 94.0112 143.06Z" fill="#237B0A" />
            </svg>
          </span>
          <div className="ml-3">
            <h6 className="m-0">
              O Zenpaper agora é <StyledName>Zenply</StyledName>!
            </h6>
            <StyledText className="mt-1">
              Estamos atualizando nossa marca, mas sua experiência continua a mesma!
            </StyledText>
            <div className="d-flex mt-1">
              <Button onClick={handleViewMore} variant="link" className="m-0 p-0">
                Saiba mais
              </Button>
              &nbsp;|&nbsp;
              <Button onClick={handleDismissAlert} variant="link" className="m-0 p-0">
                Fechar aviso
              </Button>
            </div>
          </div>
        </div>
      </StyledTag>
    </>
  );
}

SubscriptionAlerts.defaultProps = {
  subscription: null,
  user: null,
};

SubscriptionAlerts.propTypes = {

};

export default SubscriptionAlerts;
