import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import TransactionDetails from './TransactionDetails';

const mapStateToProps = (state) => ({
  recipients: state.companyTransactions.recipients,
  accounts: state.companyTransactions.accounts,
  tags: state.companyTransactions.tags,
  costsCenter: state.companyTransactions.costsCenter,
  categories: state.companyTransactions.categories,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
  isEditing: state.companyTransactions.isEditing,
});

const mapDispatchToProps = {
  onEditTransaction: companyTransactionsActionCreators.editTransaction,
  onDeleteTransaction: companyTransactionsActionCreators.deleteTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
