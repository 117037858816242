/* eslint-disable max-len */
/* eslint-disable react/no-this-in-sfc */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill from 'highcharts-pattern-fill';
import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';

import { getEmptyOptions } from './utilities';

HC_patternFill(Highcharts);

function CashflowQuarter({ account_ids, competence, cashflow }) {
  const competences = useMemo(() => {
    if (isEmpty(cashflow)) {
      return [];
    }

    const getPreviousAndNextCompetencesWithLuxon = (competence) => {
      const date = DateTime.fromFormat(competence, 'yyyy-MM');
      const previousDate = date.minus({ months: 1 });
      const nextDate = date.plus({ months: 1 });

      return [
        previousDate.toFormat('yyyy-MM'),
        date.toFormat('yyyy-MM'),
        nextDate.toFormat('yyyy-MM'),
      ];
    };

    return getPreviousAndNextCompetencesWithLuxon(competence);
  }, [competence, cashflow]);

  const formattedCompetences = useMemo(() => {
    if (isEmpty(cashflow)) {
      return [];
    }

    const getPreviousAndNextCompetencesWithLuxon = (competence) => {
      const date = DateTime.fromFormat(competence, 'yyyy-MM');
      const previousDate = date.minus({ months: 1 });
      const nextDate = date.plus({ months: 1 });

      return [
        previousDate.toFormat('MMMM', { locale: 'pt-BR' }),
        date.toFormat('MMMM', { locale: 'pt-BR' }),
        nextDate.toFormat('MMMM', { locale: 'pt-BR' }),
      ];
    };

    return getPreviousAndNextCompetencesWithLuxon(competence);
  }, [competence, cashflow]);

  const info = useMemo(() => {
    if (isEmpty(account_ids) || isEmpty(cashflow)) {
      return {};
    }

    const reducedByAccoundId = account_ids.reduce((accAccounts, accountId) => {
      if (!has(cashflow, accountId)) {
        return accAccounts;
      }

      const accountCashflow = cashflow[accountId];

      competences.forEach((competence) => {
        const emptyCashflow = {
          total_paid_incomes: 0,
          total_unpaid_incomes: 0,
          total_income: 0,
          total_paid_expenses: 0,
          total_unpaid_expenses: 0,
          total_expense: 0,
        };

        const competenceCashflow = !has(accountCashflow, competence) ? emptyCashflow : accountCashflow[competence];

        if (!has(accAccounts, competence)) {
          accAccounts[competence] = emptyCashflow;
        }

        accAccounts[competence] = {
          total_paid_incomes: accAccounts[competence].total_paid_incomes + competenceCashflow.total_paid_incomes,
          total_unpaid_incomes: accAccounts[competence].total_unpaid_incomes + competenceCashflow.total_unpaid_incomes,
          total_income: accAccounts[competence].total_income + competenceCashflow.total_income,
          total_paid_expenses: accAccounts[competence].total_paid_expenses + competenceCashflow.total_paid_expenses,
          total_unpaid_expenses: accAccounts[competence].total_unpaid_expenses + competenceCashflow.total_unpaid_expenses,
          total_expense: accAccounts[competence].total_expense + competenceCashflow.total_expense,
        };

        return accAccounts;
      }, {});

      return accAccounts;
    }, {});

    return reducedByAccoundId;
  }, [cashflow, competences, account_ids]);

  const unpaidIncomes = useMemo(() => {
    if (isEmpty(info)) {
      return [];
    }

    return competences.map((competence) => {
      const competenceInfo = info[competence];

      return competenceInfo?.total_unpaid_incomes || 0;
    });
  }, [info, competences]);

  const paidIncomes = useMemo(() => {
    if (isEmpty(info)) {
      return [];
    }

    return competences.map((competence) => {
      const competenceInfo = info[competence];

      return competenceInfo?.total_paid_incomes || 0;
    });
  }, [info, competences]);

  const unpaidExpenses = useMemo(() => {
    if (isEmpty(info)) {
      return [];
    }

    return competences.map((competence) => {
      const competenceInfo = info[competence];

      return (competenceInfo?.total_unpaid_expenses * -1) || 0;
    });
  }, [info, competences]);

  const paidExpenses = useMemo(() => {
    if (isEmpty(info)) {
      return [];
    }

    return competences.map((competence) => {
      const competenceInfo = info[competence];

      return (competenceInfo?.total_paid_expenses * -1) || 0;
    });
  }, [info, competences]);

  const netAmounts = useMemo(() => {
    if (isEmpty(info)) {
      return [];
    }

    return competences.map((competence) => {
      const competenceInfo = info[competence];

      return competenceInfo?.total_income - competenceInfo?.total_expense || 0;
    });
  }, [info, competences]);

  const checkAllZeros = useCallback((array) => array.every((item) => item === 0), []);

  const isAllEmpty = useMemo(
    () => checkAllZeros(unpaidIncomes) && checkAllZeros(paidIncomes)
    && checkAllZeros(unpaidExpenses) && checkAllZeros(paidExpenses),
    [checkAllZeros, unpaidIncomes, paidIncomes, unpaidExpenses, paidExpenses],
  );

  const getChartOptions = useCallback(() => {
    if (isAllEmpty) {
      return getEmptyOptions(competence);
    }

    return {
      defs: {
        patterns: [
          {
            id: 'income-pattern',
            width: 6,
            height: 6,
            path: {
              d: 'M 5 -1 L -1 5 M 7 3 L 3 7',
              stroke: '#4CCEAC',
              strokeWidth: 1.5,
            },
          },
          {
            id: 'expense-pattern',
            width: 6,
            height: 6,
            path: {
              d: 'M 5 -1 L -1 5 M 7 3 L 3 7',
              stroke: '#DB504A',
              strokeWidth: 1.5,
            },
          },
          {
            id: 'income-empty-pattern',
            width: 6,
            height: 6,
            path: {
              d: 'M 5 -1 L -1 5 M 7 3 L 3 7',
              stroke: '#AAAAAA',
              strokeWidth: 1.5,
            },
          },
          {
            id: 'expense-empty-pattern',
            width: 6,
            height: 6,
            path: {
              d: 'M 5 -1 L -1 5 M 7 3 L 3 7',
              stroke: '#CCCCCC',
              strokeWidth: 1.5,
            },
          },
        ],
      },
      chart: {
        spacingTop: 25,
        backgroundColor: 'transparent',
        height: 220,
      },
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: false,
      xAxis: [{
        categories: formattedCompetences,
        lineColor: '#D4D7DC',
        tickColor: '#D4D7DC',
        fontWeight: 'bold',
        labels: {
          style: {
            color: '#8D9096',
            fontFamily: 'Poppins',
          },
        },
        showFirstLabel: !0,
      }],
      yAxis: {
        title: false,
        lineColor: '#D4D7DC',
        gridLineColor: '#D4D7DC',
        minorGridLineColor: '#F4F5F8',
        minorGridLineWidth: 1,
        minorTickLength: 0,
        minorTickInterval: 'auto',
        labels: {
          style: {
            color: '#8D9096',
            fontFamily: 'Poppins',
          },
          y: 2,
          formatter() {
            return `${FORMATTERS.NUMBER(this.value)}`;
          },
        },
        plotLines: [{
          color: '#393A3D',
          value: 0,
          width: 3,
          zIndex: 5,
        }],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        borderRadius: 2,
        shadow: false,
        style: {
          fontFamily: 'Poppins',
        },
        formatter() {
          return `${this.point.series.name}: <strong>${FORMATTERS.NUMBER(this.y)}</strong>`;
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: !0,
          },
        },
        column: {
          pointPadding: 0.15,
          groupPadding: 0.15,
          borderWidth: 0,
          borderRadius: 1,
          shadow: false,
          stacking: 'normal',
        },
      },
      series: [
        {
          type: 'column',
          name: 'A Receber',
          data: unpaidIncomes,
          color: 'url(#income-pattern)',
        },
        {
          type: 'column',
          name: 'Recebido',
          color: '#4CCEAC',
          data: paidIncomes,
        },
        {
          type: 'column',
          name: 'A Pagar',
          color: 'url(#expense-pattern)',
          data: unpaidExpenses,
        },
        {
          type: 'column',
          name: 'Pago',
          color: '#DB504A',
          data: paidExpenses,
        },
        {
          type: 'line',
          name: 'Saldo',
          color: '#393A3D',
          lineWidth: 1,
          dashStyle: 'dot',
          marker: {
            radius: 3,
          },
          data: netAmounts,
        },
      ],
    };
  }, [
    formattedCompetences,
    paidExpenses,
    paidIncomes,
    unpaidExpenses,
    unpaidIncomes,
    netAmounts,
    isAllEmpty,
    competence,
  ]);

  return (
    <div>
      <p className="mb-1">
        &nbsp;
      </p>
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions()}
      />
    </div>
  );
}

CashflowQuarter.defaultProps = {
  account_ids: [],
  cashflow: {},
};

CashflowQuarter.propTypes = {
  account_ids: PropTypes.array,
  cashflow: PropTypes.object,
  competence: PropTypes.string.isRequired,
};

export default CashflowQuarter;
