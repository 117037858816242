import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import {
  IoArrowDown,
  IoArrowUp,
  IoSearch,
  IoTrash,
} from 'react-icons/io5';

import { TRANSACTION_TYPE_SUB_TYPE_OPTIONS_V2, typeSubtypeStringToObject, useMediaQuery } from 'helpers';
import useSelectOptions from 'helpers/useSelectOptions';
import {
  Button,
  Card,
  FormikSubmitListener,
  FormDateFromToField,
  FormCurrencyFromToField,
  FastFilterSelect,
  CurrencyInput,
  DatePicker,
} from '_components/_core';
import { FormPaidField } from '_components/_shared';
import CustomFormSelectField from '_components/Transactions/components/CustomFormSelectField/CustomFormSelectField';

import { FiltersCount, StyledSearchInput } from '../../styles';

function AdvancedSearch({
  searchBy,
  dateSearchTerm,
  advancedFilters,
  recipients,
  tags,
  categories,
  costCenters,
  accounts,
  tempSearchTerm,
  onChangeTempSearchTerm,
  onSearch,
  onSetAdvancedFilters,
  isSearching,
  onChangeSearchBy,
  onChangeDate,
  onClearSearchTerm,
}) {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const { isMobile } = useMediaQuery();

  const {
    recipientsOptions: formattedRecipients,
    tagsOptions: formattedTags,
    categoriesOptions: formattedCategories,
    costCentersOptions: formattedCostCenters,
    accountsOptions: formattedAccounts,
  } = useSelectOptions({
    recipients,
    tags,
    categories,
    costCenters,
    accounts,
  });

  const handleSubmitSearch = useCallback((values) => {
    if (values.type_sub_types) {
      const types = [];
      const subTypes = [];

      const converted = values.type_sub_types.map(
        (typeSubType) => typeSubtypeStringToObject(typeSubType),
      );

      converted.reduce((acc, curr) => {
        if (!acc.includes(curr.type) && curr.type) {
          types.push(curr.type);
        }

        if (!acc.includes(curr.sub_type) && curr.sub_type) {
          subTypes.push(curr.sub_type);
        }

        return [...acc, curr.type, curr.sub_type];
      }, []);

      values.types = types;
      values.sub_types = subTypes;
    } else {
      delete values.types;
      delete values.sub_types;
    }

    onSetAdvancedFilters(values);
  }, [onSetAdvancedFilters]);

  const initialValues = useMemo(() => ({
    event_date_start: null,
    event_date_end: null,
    event_competence_start: null,
    event_competence_end: null,
    created_at_start: null,
    created_at_end: null,
    amount_start: null,
    amount_end: null,
    category_ids: [],
    recipient_ids: [],
    tag_ids: [],
    cost_center_ids: [],
    bank_account_ids: [],
    payment_forms: [],
    type: null,
    sub_type: null,
    paid_status: ['paid', 'unpaid'],
    type_sub_types: [],
    payment_plans: [],
    types: [],
    sub_types: [],
  }), []);

  const activeFiltersCount = useMemo(() => {
    let count = 0;

    Object.keys(advancedFilters).forEach((key) => {
      const initialValue = initialValues[key];
      const advancedValue = advancedFilters[key];

      if (!advancedValue) {
        return;
      }

      if (!isNil(advancedValue) && !isEqual(initialValue, advancedValue)) {
        count += 1;
      }
    });

    return count;
  }, [initialValues, advancedFilters]);

  return (
    <Card className="p-3 no-print">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitSearch}
        enableReinitialize
      >
        {({ handleSubmit, resetForm }) => (
          <Form className="tab-content" onSubmit={handleSubmit}>
            <FormikSubmitListener />
            <Form.Row>
              <Col xs={12} sm={10} md={10} className="d-flex justify-content-center">
                <div className="w-100 d-flex align-items-center">
                  {isMobile && (
                    <h6 className="m-0 d-flex">
                      {isMobile ? '' : 'Pesquisar' }
                    </h6>
                  )}
                  {!isMobile && (
                    <h6 className="m-0 d-flex mr-3">
                      Pesquisar
                    </h6>
                  )}
                  <div
                    className="d-flex align-items-center justify-content-between w-100"
                    style={{
                      border: '1px solid #E8E7EA',
                      borderRadius: '8px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <IoSearch size="1.2em" className="ml-2" />
                    {searchBy === 'AMOUNT' && (
                      <StyledSearchInput
                        as={CurrencyInput}
                        value={tempSearchTerm}
                        className="form-control"
                        type="tel"
                        autoComplete="off"
                        placeholder={`${isMobile ? 'Digite aqui...' : 'O que você procura?'}`}
                        onChange={(value) => onChangeTempSearchTerm(value)}
                        onFocus={(e) => e.target.select()}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onSearch();
                          }
                        }}
                      />
                    )}
                    {searchBy === 'DESCRIPTION' && (
                      <StyledSearchInput
                        type="text"
                        className="form-control"
                        value={tempSearchTerm}
                        placeholder="O que você procura?"
                        onChange={(e) => onChangeTempSearchTerm(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onSearch();
                          }
                        }}
                      />
                    )}
                    {searchBy === 'DATE' && (
                      <StyledSearchInput
                        as={DatePicker}
                        selectedDate={dateSearchTerm}
                        type="text"
                        className="form-control"
                        placeholder={`${isMobile ? 'Selecione a Data...' : 'O que você procura?'}`}
                        onChange={onChangeDate}
                        onFocus={(e) => e.target.select()}
                        {...(isMobile ? { withPortal: true } : {})}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onSearch();
                          }
                        }}
                      />
                    )}
                    {tempSearchTerm && (
                      <IoTrash
                        className="text-danger mr-2"
                        size="1.2em"
                        onClick={onClearSearchTerm}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    )}

                  </div>
                  <FastFilterSelect
                    className="ml-2"
                    label="Pesquisar por:"
                    value={searchBy}
                    onChange={(value) => {
                      onChangeSearchBy(value);

                      onChangeTempSearchTerm('');
                    }}
                    options={[
                      { value: 'DESCRIPTION', label: 'Texto' },
                      { value: 'AMOUNT', label: 'Valor' },
                      { value: 'DATE', label: 'Data' },
                    ]}
                  />
                </div>
              </Col>
              <Col xs={12} sm={2} md={2} className="mt-3 mt-md-0">
                <div className="d-flex">
                  <Button
                    variant="success-2"
                    className={`${isMobile ? 'w-100' : ''}`}
                    onClick={onSearch}
                    isLoading={isSearching}
                    disabled={isSearching}
                  >
                    Pesquisar
                  </Button>
                </div>
              </Col>
            </Form.Row>
            <Form.Row className="mt-3">
              <Col className="d-flex justify-content-center align-items-center">
                <Button onClick={() => setShowAdvancedSearch(!showAdvancedSearch)} variant="link" className="m-0 p-0 d-flex align-items-center justify-content-center">
                  {showAdvancedSearch ? <IoArrowUp className="mr-2" /> : <IoArrowDown className="mr-2" />}
                  Busca avançada
                  {activeFiltersCount > 0 && (
                  <FiltersCount>
                    {activeFiltersCount}
                  </FiltersCount>
                  )}
                </Button>
              </Col>
            </Form.Row>
            {showAdvancedSearch && (
              <>
                <hr />
                <Form.Row className="mt-3">
                  <Form.Group as={Col} xl={2} lg={4} md={6}>
                    <FormDateFromToField
                      label="Data de pagamento"
                      fromName="event_date_start"
                      fromPlaceholder="De:"
                      toName="event_date_end"
                      toPlaceholder="Até:"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={2} lg={4} md={6}>
                    <FormDateFromToField
                      label="Data de competência"
                      fromName="event_competence_start"
                      fromPlaceholder="De:"
                      toName="event_competence_end"
                      toPlaceholder="Até:"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={2} lg={4} md={6}>
                    <FormDateFromToField
                      label="Data de criação"
                      fromName="created_at_start"
                      fromPlaceholder="De:"
                      toName="created_at_end"
                      toPlaceholder="Até:"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={2} lg={4} md={6}>
                    <FormCurrencyFromToField
                      label="Valor"
                      fromName="amount_start"
                      fromPlaceholder="De:"
                      toName="amount_end"
                      toPlaceholder="Até:"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={2} lg={4} md={6}>
                    <div className="d-flex flex-column justify-content-center align-items-start  ml-3">
                      <span style={{ color: '#17212E', fontSize: '0.8rem', fontWeight: 600 }}>
                        Incluir lançamentos
                      </span>
                      <div className="d-flex justify-content-center align-items-start mt-1">
                        <FormPaidField name="paid_status" />
                      </div>
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mt-3 mt-xl-1">
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Contas bancárias</Form.Label>
                    <CustomFormSelectField
                      name="bank_account_ids"
                      options={formattedAccounts}
                      placeholder="Todas"
                      isMulti
                      useValueForArrayOption
                      multiple
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Categorias</Form.Label>
                    <CustomFormSelectField
                      name="category_ids"
                      options={formattedCategories}
                      placeholder="Todas"
                      isMulti
                      useValueForArrayOption
                      multiple
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Contatos</Form.Label>
                    <CustomFormSelectField
                      name="recipient_ids"
                      options={formattedRecipients}
                      placeholder="Todos"
                      isMulti
                      multiple
                      useValueForArrayOption
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Centro de custos</Form.Label>
                    <CustomFormSelectField
                      name="cost_center_ids"
                      options={formattedCostCenters}
                      placeholder="Todos"
                      isMulti
                      multiple
                      useValueForArrayOption
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Tags</Form.Label>
                    <CustomFormSelectField
                      name="tag_ids"
                      options={formattedTags}
                      placeholder="Todas"
                      isMulti
                      multiple
                      useValueForArrayOption
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Tipo de movimentação</Form.Label>
                    <CustomFormSelectField
                      name="type_sub_types"
                      options={TRANSACTION_TYPE_SUB_TYPE_OPTIONS_V2}
                      placeholder="Todos"
                      isMulti
                      multiple
                      useValueForArrayOption
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Forma de Pagamento</Form.Label>
                    <CustomFormSelectField
                      name="payment_forms"
                      options={[
                        { value: 'BANK_SLIP', label: 'Boleto' },
                        { value: 'CREDIT_CARD', label: 'Cartão de crédito' },
                        { value: 'DEBIT_CARD', label: 'Cartão de débito' },
                        { value: 'BANK_CHECK', label: 'Cheque' },
                        { value: 'DIRECT_DEBIT', label: 'Débito em conta' },
                        { value: 'CASH', label: 'Dinheiro' },
                        { value: 'PIX', label: 'PIX' },
                        { value: 'TRANSFER', label: 'Transferência' },
                        { value: 'REMITTANCE', label: 'Remessa Bancária' },
                      ]}
                      placeholder="Todas"
                      isMulti
                      multiple
                      useValueForArrayOption
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={3} lg={4} md={6}>
                    <Form.Label>Plano de Pagamento</Form.Label>
                    <CustomFormSelectField
                      name="payment_plans"
                      options={[
                        { value: 'ONE_TIME', label: 'À vista' },
                        { value: 'INSTALMENT', label: 'Parcelado' },
                        { value: 'RECURRENT', label: 'Recorrente' },
                      ]}
                      placeholder="Todos"
                      isMulti
                      multiple
                      useValueForArrayOption
                      isClearable
                      closeMenuOnSelect={false}
                      width="100%"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} lg={12} className="d-flex justify-content-center align-items-center">
                    <Button
                      variant="success-2"
                      onClick={onSearch}
                      isLoading={isSearching}
                      disabled={isSearching}
                    >
                      Pesquisar
                    </Button>
                    <Button
                      variant="link"
                      className="m-0 p-0 ml-3"
                      size="sm"
                      onClick={resetForm}
                    >
                      Limpar filtros
                    </Button>
                  </Form.Group>
                </Form.Row>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Card>
  );
}

AdvancedSearch.defaultProps = {
  categories: [],
  costCenters: [],
  tags: [],
  recipients: [],
  accounts: [],
  tempSearchTerm: '',
  advancedFilters: {},
  dateSearchTerm: '',
};

AdvancedSearch.propTypes = {
  searchBy: PropTypes.string.isRequired,
  dateSearchTerm: PropTypes.string,
  advancedFilters: PropTypes.object,
  categories: PropTypes.array,
  costCenters: PropTypes.array,
  accounts: PropTypes.array,
  tags: PropTypes.array,
  recipients: PropTypes.array,
  tempSearchTerm: PropTypes.string,
  onChangeTempSearchTerm: PropTypes.func,
  isSearching: PropTypes.bool.isRequired,
  onSearch: PropTypes.func,
  onSetAdvancedFilters: PropTypes.func,
  onChangeSearchBy: PropTypes.func,
  onChangeDate: PropTypes.func,
  onClearSearchTerm: PropTypes.func,
};

export default AdvancedSearch;
