/* eslint-disable consistent-return */
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Button } from '_components/_core';

import { FcInvite } from 'react-icons/fc';
import {
  FormContainer,
  Card,
  Title,
  Text,
} from './styles';

import CodeInput from './components/CodeInput';

function SignUpConfirmation({ onConfirmEmail, onRequestEmailConfirmation }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const history = useHistory();

  useEffect(() => {
    document.body.style.backgroundImage = 'url(assets/img/bg-activate-subscription.png)';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundImage = 'none';
    };
  }, []);

  useEffect(() => {
    if (timeLeft === 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const queryEmail = new URLSearchParams(history.location.search).get('email');

  const handleConfirmEmail = useCallback((code) => {
    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
    };

    const onError = () => {
      setIsLoading(false);
    };

    const params = {
      email: queryEmail,
      token: code,
    };

    onConfirmEmail(params, onSuccess, onError);
  }, [queryEmail, onConfirmEmail]);

  const handleRequestToken = useCallback(() => {
    if (timeLeft > 0) return;

    setIsRequesting(true);

    const params = {
      email: queryEmail,
    };

    const onSuccess = () => {
      setIsRequesting(false);

      setTimeLeft(60);
    };

    const onError = () => {
      setIsRequesting(false);
    };

    onRequestEmailConfirmation(params, onSuccess, onError);
  }, [timeLeft, onRequestEmailConfirmation, queryEmail]);

  return (
    <Container className="h-100">
      <FormContainer>
        <Card>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <FcInvite size={80} />
            <Title className="mt-3">
              Confirme seu e-mail
            </Title>
            <Text>
              Digite o código de confirmação enviado para
            </Text>
            <p className="m-0 p-0">
              <strong clas>
                {queryEmail}
              </strong>
            </p>
          </div>
          <>
            <Row className="mt-4 d-flex flex-column justify-content-center align-items-center">
              <CodeInput
                length={6}
                onComplete={handleConfirmEmail}
              />
              <Button
                variant="success-2"
                className="mt-3"
                size="lg"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Confirmar
              </Button>
            </Row>
            <div className="d-flex flex-column align-items-start mt-3">
              <Button className="ml-0 pl-0" variant="link" onClick={() => history.push('/entrar')}>
                Ir para login
              </Button>
              <Button className="ml-0 pl-0" variant="link" onClick={() => history.push('/cadastrar')}>
                Ir para cadastro
              </Button>
              <Button
                disabled={isRequesting || timeLeft > 0}
                isLoading={isRequesting}
                className="ml-0 pl-0"
                variant="link"
                onClick={handleRequestToken}
              >
                Reenviar código de confirmação {timeLeft > 0 && `(${timeLeft}s)`}
              </Button>
            </div>
          </>
        </Card>
      </FormContainer>
    </Container>
  );
}

SignUpConfirmation.propTypes = {
  onConfirmEmail: PropTypes.func.isRequired,
  onRequestEmailConfirmation: PropTypes.func.isRequired,
};

export default SignUpConfirmation;
