import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const setActiveCompany = (company) => {
  return (dispatch) => {
    dispatch(request(company));

    service.setActive(company).then(
      (response) => {
        dispatch(success(response.data));
      },
    );
  };

  function request(company) {
    return {
      type: constants.COMPANY_SET_ACTIVE_REQUEST,
      payload: { company },
    };
  }
  function success(company) {
    return {
      type: constants.COMPANY_SET_ACTIVE_SUCCESS,
      payload: { company },
    };
  }
};

export default setActiveCompany;
