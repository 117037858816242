import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

function FormTextField({
  placeholder,
  disabled,
  autoFocus,
  innerRef,
  onKeyPress,
  onFocus,
  type,
  inputStyleProps,
  className,
  ...props
}) {
  const [field, meta, { setTouched }] = useField(props);

  const error = useMemo(
    () => ((meta.touched && meta.error) ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const value = useMemo(() => (field.value || ''), [field.value]);

  return (
    <>
      <Form.Control
        type={type}
        value={value}
        ref={innerRef}
        placeholder={placeholder}
        onBlur={setTouched}
        disabled={disabled}
        autoComplete="off"
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        style={inputStyleProps}
        onFocus={onFocus}
        className={className}
        {...field}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormTextField.defaultProps = {
  autoFocus: false,
  innerRef: null,
  type: 'text',
  inputStyleProps: {},
  onKeyPress: null,
  onFocus: null,
  className: null,
};

FormTextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  innerRef: PropTypes.object,
  inputStyleProps: PropTypes.object,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
};

export default FormTextField;
