/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { FaDownload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoArrowDown, IoArrowUp } from 'react-icons/io5';

import { AsyncOperations } from '_components/_shared';
import useAsyncOperations from '_components/_shared/AsyncOperations/useAsyncOperations';

import {
  PageHeader,
  Button,
  Card,
  CardBody,
  Dropzone,
} from '_components/_core';

import {
  NumberIcon,
  StyledUl,
  Summary,
  TotalFound,
} from './styles';

const CONTACTS_SPREADSHEET_URL = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/modelo_contatos_zenply.xlsx';

function ContactsUpload({
  onUploadContactsSpreadsheet,
  onUploadContactsSpreadsheetConfirm,
}) {
  const history = useHistory();

  const [isReading, setIsReading] = useState(false);
  const [summary, setSummary] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showAllColumns, setShowAllColumns] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleCompleteRead = useCallback((completedOperation) => {
    const { response } = completedOperation || {};

    const { success, errors: operationErrors, summary: operationSummary } = response || {};

    if (!success) {
      setErrors(operationErrors || []);
      setSelectedFile(null);
    } else {
      setSummary(operationSummary);
      setErrors([]);
    }

    setIsReading(false);
  }, []);

  const handleErrorRead = useCallback(() => {
    setIsReading(false);
  }, []);

  const {
    hasPending: hasPendingRead,
    pendingAsyncOperation: pendingAsyncOperationRead,
    onSetAsyncOperations: onSetAsyncOperationsRead,
  } = useAsyncOperations({
    type: 'CONTACTS_SPREADSHEET_IMPORT_READ',
    showConfirmation: false,
    onComplete: handleCompleteRead,
    onError: handleErrorRead,
  });

  const {
    hasPending: hasPendingConfirm,
    pendingAsyncOperation: pendingAsyncOperationConfirm,
    onSetAsyncOperations: onSetAsyncOperationsConfirm,
  } = useAsyncOperations({
    type: 'CONTACTS_SPREADSHEET_IMPORT_CONFIRM',
    successTitle: 'Importação concluída!',
    successMessage: 'Contatos importados com sucesso!',
    redirectTo: '/contatos',
  });

  const handleToggleColumns = useCallback(() => {
    setShowAllColumns(!showAllColumns);
  }, [showAllColumns]);

  const handleFilesChanged = useCallback((files, setFieldValue) => {
    if (isEmpty(files)) {
      return;
    }

    const [spreadsheet] = files || [];

    setFieldValue('spreadsheet', spreadsheet);
    setSelectedFile(spreadsheet);
  }, []);

  const renderInstructions = useCallback(() => (
    <>
      <NumberIcon>
        1
      </NumberIcon>
      <h4 className="mt-3">
        Leia as regras de preenchimento
      </h4>
      <p>
        Preencha o arquivo com os seus contatos. <br /><br />
        A coluna <b>Nome</b> deve ser obrigatoriamente preenchida. <br /><br />
        Se existirem contatos no Zenply, o sistema irá <strong>atualizar os dados do contato</strong> com base na coluna <strong>Nome</strong>. <br />
        Se não existir, o sistema irá <strong>criar um novo contato</strong> com base na coluna <strong>Nome</strong>. <br /><br />
        <b>
          Veja como preencher cada coluna:
        </b>
        <StyledUl className="mt-3">
          <li>
            Coluna <b>Nome</b> <span className="text-danger">(obrigatório)</span>
            <ul>
              <li>
                Insira um texto de até 115 caracteres;
              </li>
            </ul>
          </li>
          {showAllColumns && (
            <>
              <li className="mt-2">
                Coluna <b>Tipo de Pessoa</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Valores válidos: <strong>PF</strong> ou <strong>PJ</strong>
                  </li>
                  <li>
                    <strong>PF</strong> para quando for Pessoa Física
                  </li>
                  <li>
                    <strong>PJ</strong> para quando for Pessoa Jurídica
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>CPF/CNPJ</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencher com um <strong>CPF</strong> ou <strong>CNPJ</strong> válido.
                  </li>
                  <li>
                    A pontuação será ignorada.
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>E-mail</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com um e-mail válido.
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Telefone</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com um número de telefone.
                  </li>
                  <li>
                    Caracteres não numéricos serão ignorados.
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Endereço</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com o Logradouro do endereço (ex.: Avenida do Contorno);
                  </li>
                </ul>
              </li>

              <li className="mt-2">
                Coluna <b>Número</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com o Número do endereço (ex.: 2905);
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Complemento</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com o Complemento do endereço (ex.: Sala 407);
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Bairro</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com o Bairro do endereço (ex.: Santa Efigênia);
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>CEP</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com o CEP válido do endereço (ex.: 30.110-915);
                  </li>
                  <li>
                    Caracteres não numéricos serão ignorados.
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Estado</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com a sigla do Estado (ex.: SP, RJ, MG);
                  </li>
                  <li>
                    Será ignorado caso esteja inválido.
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Cidade</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Preencha com o nome da Cidade (ex.: Belo Horizonte);
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Cidade IBGE</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Código da Cidade de acordo com o IBGE
                  </li>
                  <li>
                    Por exemplo: 3550308 para São Paulo, 3106200 para Belo Horizonte, etc.
                  </li>
                  <li>
                    Necessário caso queira emitir NFS-e para este contato. (em breve)
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Coluna <b>Tipo de Contato</b> <span className="text-info">(opcional)</span>
                <ul>
                  <li>
                    Valores válidos: <strong>Cliente</strong> ou <strong>Fornecedor</strong> ou <strong>Funcionário</strong>
                  </li>
                  <li>
                    Será ignorado caso esteja inválido.
                  </li>
                </ul>
              </li>
            </>
          )}
        </StyledUl>
        <Button variant="link" className="m-0 p-0" onClick={handleToggleColumns}>
          {showAllColumns ? 'Esconder' : 'Mostrar'} colunas opcionais
          {showAllColumns ? <IoArrowUp className="ml-2" /> : <IoArrowDown className="ml-2" />}
        </Button>
        <Button variant="default" className="d-flex justify-content-center align-items-center mt-4" onClick={() => window.open(CONTACTS_SPREADSHEET_URL, '_blank')}>
          <FaDownload className="mr-2" />
          Baixar modelo
        </Button>
      </p>
    </>
  ), [handleToggleColumns, showAllColumns]);

  const handleSubmit = useCallback((values) => {
    const formData = new FormData();

    setErrors([]);

    formData.append('spreadsheet', values.spreadsheet);

    const successCallback = (asyncOperation) => {
      onSetAsyncOperationsRead([asyncOperation]);
    };

    const errorCallback = () => {
      setIsReading(false);
    };

    setIsReading(true);

    onUploadContactsSpreadsheet(formData, successCallback, errorCallback);
  }, [
    onUploadContactsSpreadsheet,
    onSetAsyncOperationsRead,
  ]);

  const handleSubmitConfirm = useCallback(() => {
    const formData = new FormData();

    setErrors([]);

    formData.append('spreadsheet', selectedFile);

    const successCallback = (asyncOperation) => {
      onSetAsyncOperationsConfirm([asyncOperation]);
    };

    const errorCallback = () => {
      setIsReading(false);
    };

    const onStart = () => {
      setIsReading(true);
    };

    const onCancel = () => {
      setIsReading(false);
    };

    onUploadContactsSpreadsheetConfirm(formData, successCallback, errorCallback, onStart, onCancel);
  }, [
    onUploadContactsSpreadsheetConfirm,
    onSetAsyncOperationsConfirm,
    selectedFile,
  ]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title={(
          <div>
            <Button
              variant="icon"
              className="m-0 p-0 mr-3"
              onClick={() => history.goBack()}
            >
              <IoArrowBackOutline size="1.5em" />
            </Button>
            <span>
              Importar Contatos
            </span>
          </div>
        )}
        variant="small"
      />
      {hasPendingRead && (
        <AsyncOperations
          hasPending={hasPendingRead}
          pendingAsyncOperation={pendingAsyncOperationRead}
        />
      )}
      {hasPendingConfirm && (
        <AsyncOperations
          hasPending={hasPendingConfirm}
          pendingAsyncOperation={pendingAsyncOperationConfirm}
        />
      )}
      {!hasPendingRead && !hasPendingConfirm && (
        <Row>
          <Col>
            <Card>
              <Formik
                initialValues={{
                  spreadsheet: null,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnMount
              >
                {({
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <CardBody>
                    {!summary && (
                      <Row>
                        <Col md={6} className="mt-4 mt-lg-0">
                          {renderInstructions()}
                        </Col>
                        <Col md={6} className="mt-2 mt-lg-0">
                          <NumberIcon>
                            2
                          </NumberIcon>
                          <h4 className="mt-3">
                            Upload de planilha de contatos
                          </h4>
                          <p>
                            Salve a planilha assim que você terminar de preenchê-la. Selecione o arquivo no botão abaixo, e clique em "Importar planilha".
                          </p>
                          <Form.Group>
                            <Dropzone
                              onChange={(files) => handleFilesChanged(files, setFieldValue)}
                              accept=".xlsx"
                              showLengthMessage
                              maxFiles={1}
                              forceMaxSize={2 * 1024 * 1024}
                            />
                            <Form.Text className="text-muted mt-3">
                              <b>Atenção:</b> A planilha deve estar no formato <b>.xlsx</b> e não pode ter mais de 2MB.
                            </Form.Text>
                          </Form.Group>
                          <Button
                            variant="success-2"
                            onClick={handleSubmit}
                            disabled={isReading || isEmpty(values.spreadsheet)}
                            isLoading={isReading}
                          >
                            Importar planilha
                          </Button>
                          {!isEmpty(errors) && (
                            <div className="mt-3">
                              <p className="text-danger">
                                <b>Corrija os erros abaixo e tente novamente:</b>
                              </p>
                              <ul>
                                {errors.slice(0, 15).map((error) => (
                                  <li className="text-danger" style={{ fontWeight: '400' }}>
                                    {error}
                                  </li>
                                ))}
                                {errors.slice(15).map((error) => (
                                  <li className="text-danger" style={{ fontWeight: '400' }}>
                                    {error}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                    {summary && (
                      <Row>
                        <Col md={5}>
                          <TotalFound>
                            {summary.total_contacts || 0}
                          </TotalFound>
                          <h4 className="mt-3">
                            contatos foram encontrados em sua planilha!
                          </h4>
                          <p>
                            Você está prestes a importar {summary.total_contacts || 0} contatos para o Zenply. <br />
                            Verifique ao lado se os itens encontrados estão corretos e clique em Confirmar Importação para continuar.
                          </p>
                          <hr />
                          <div className="d-flex mt-3">
                            <Button
                              variant="default"
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => {
                                setSummary(null);
                                setIsReading(false);
                              }}
                            >
                              Cancelar
                            </Button>
                            <Button
                              variant="success-2"
                              className="d-flex justify-content-center align-items-center ml-3"
                              onClick={() => handleSubmitConfirm(values)}
                            >
                              Confirmar importação
                            </Button>
                          </div>
                        </Col>
                        <Col md={4} className="mt-4 mt-lg-0">
                          <Summary>
                            <li>
                              <b>{summary.contacts_to_create || 0}</b> contatos serão criados
                            </li>
                            <li>
                              <b>{summary.contacts_to_update || 0}</b> contatos serão atualizados
                            </li>
                          </Summary>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Formik>

            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}

ContactsUpload.propTypes = {
  onUploadContactsSpreadsheet: PropTypes.func.isRequired,
  onUploadContactsSpreadsheetConfirm: PropTypes.func.isRequired,
};

export default ContactsUpload;
