import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactTooltip from 'react-tooltip';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IoSwapHorizontalOutline } from 'react-icons/io5';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { usePrevious, useMediaQuery } from 'helpers';
import { MonthPicker } from '_components/_core';

import TransactionsTable from './components/TransactionsTable';
import BalanceSection from './components/BalanceSection/BalanceSection';
import TransactionsListMobile from './components/TransactionsListMobile/TransactionsListMobileContainer';
import useTransferForm from './utilities/useTransferForm';
import useTransactions from './utilities/useTransactions';
import { getTransactionsFiltersByTab, getTransactionsMobile } from './utilities';
import { StyledTabs } from './styles';

function Transactions({
  activeCompany,
  categories,
  recipients,
  costsCenter,
  tags,
  preferences,
  onFetchTransactions,
  onCreateTransaction,
  onDeleteTransaction,
  onLoadTransactionsPageData,
  onMoveTransactions,
  onUpdateMultipleTransactions,
  onApplyTagMultipleTransactions,
  onSortTransactions,
  sorting,
  onClearDuplicatedTransactions,
  availableTabs,
}) {
  const {
    selected_account_id,
    selectedDate,
    selectedAccountIds,
    onChangeDate,
    duplicatedTransactionIds,
  } = useTransactions();

  const { isMobile, isTablet } = useMediaQuery();

  const { isTransferFormOpen, onToggleTransferForm } = useTransferForm();

  const [lastUsedFilters, setLastUsedFilters] = useState(null);
  const [transactionFilters, setTransactionFilters] = useState(() => {
    const localFilters = localStorage.getItem('transactions_filters');

    if (localFilters) {
      return JSON.parse(localFilters);
    }

    return {};
  });

  const year = Number(selectedDate.toFormat('yyyy'));
  const month = Number(selectedDate.toFormat('MM'));

  const [tabsExpanded, setTabsExpanded] = useState(() => {
    const localExpanded = localStorage.getItem('tabsExpanded');

    if (!isEmpty(availableTabs)) {
      const expenseTabs = availableTabs.filter((tab) => tab.startsWith('EXPENSE'));

      if (expenseTabs.length !== 4) {
        return true;
      }
    }

    if (localExpanded) {
      return JSON.parse(localExpanded);
    }

    return true;
  });

  const [activeTab, setActiveTab] = useState(() => {
    const [firstTab] = availableTabs;

    const [type, subType] = firstTab.split('-');

    if (type === 'EXPENSE') {
      const expenseTabs = availableTabs.filter((tab) => tab.startsWith('EXPENSE'));

      if (expenseTabs.length === 4 && !tabsExpanded) {
        return 'EXPENSE';
      }

      return subType;
    }

    return type;
  });
  const [type, setType] = useState(() => {
    const [firstTab] = availableTabs;

    const [type] = firstTab.split('-');

    return type;
  });
  const [subType, setSubType] = useState(() => {
    const [firstTab] = availableTabs;

    // eslint-disable-next-line no-unused-vars
    const [type, subType] = firstTab.split('-');

    return subType === 'null' ? null : subType;
  });

  const handleTabChange = useCallback((tab, e) => {
    if (tab === 'MONTH_PICKER') {
      return;
    }

    if (isMobile || isTablet) {
      setType(null);
      setSubType(null);
    }

    if (['EXPENSE', 'FIXED_EXPENSE'].includes(tab)) {
      setType('EXPENSE');
      setSubType('FIXED_EXPENSE');
    } else if (['VARIABLE_EXPENSE', 'PEOPLE', 'TAXES'].includes(tab)) {
      setType('EXPENSE');
      setSubType(tab);
    } else if (tab === 'TRANSFER') {
      setType('TRANSFER');
      setSubType(null);
    } else {
      setType('INCOME');
      setSubType(null);
    }

    if (tab === 'EXPAND') {
      setTabsExpanded(!tabsExpanded);
      setActiveTab(tabsExpanded ? 'EXPENSE' : 'FIXED_EXPENSE');
      setType('EXPENSE');
      setSubType('FIXED_EXPENSE');

      localStorage.setItem('tabsExpanded', !tabsExpanded);

      return;
    }

    if (isTransferFormOpen) {
      onToggleTransferForm();
    }

    if (transactionFilters) {
      setTransactionFilters({});
      localStorage.removeItem('transactions_filters');
      localStorage.removeItem('transactions_filters_form');
    }

    setActiveTab(tab, e);
  }, [
    tabsExpanded,
    isTransferFormOpen,
    isMobile,
    isTablet,
    transactionFilters,
    onToggleTransferForm,
  ]);

  const isMounted = useRef(false);

  const prevCompany = usePrevious(activeCompany);

  useEffect(() => {
    if (!activeCompany) {
      return;
    }

    if (!isMounted.current) {
      onLoadTransactionsPageData(selectedDate);
      isMounted.current = true;

      return;
    }

    if ((activeCompany && prevCompany) && (prevCompany.id !== activeCompany.id)) {
      onLoadTransactionsPageData(selectedDate);
    }
  }, [
    activeCompany,
    prevCompany,
    selectedDate,
    isMounted,
    onLoadTransactionsPageData,
  ]);

  const filters = useMemo(
    () => {
      if (isMobile || isTablet) {
        return getTransactionsMobile({
          activeTab: null,
          selectedDate,
          account_ids: selectedAccountIds,
          transactionFilters,
        });
      }

      return getTransactionsFiltersByTab({
        activeTab,
        selectedDate,
        account_ids: selectedAccountIds,
        transactionFilters,
      });
    },
    [
      selectedAccountIds,
      transactionFilters,
      activeTab,
      selectedDate,
      isMobile,
      isTablet,
    ],
  );

  useEffect(() => {
    if (!isEmpty(duplicatedTransactionIds)) {
      const timeoutId = setTimeout(() => {
        onClearDuplicatedTransactions();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [duplicatedTransactionIds, onClearDuplicatedTransactions]);

  useEffect(() => {
    if (activeCompany
      && !isEmpty(filters.account_ids)
      && !isEqual(filters, lastUsedFilters)
    ) {
      onFetchTransactions(filters);
      setLastUsedFilters(filters);
    }
  }, [onFetchTransactions, activeCompany, lastUsedFilters, filters]);

  const handleUpdateFilters = useCallback((filters) => {
    setTransactionFilters(filters);

    localStorage.setItem('transactions_filters', JSON.stringify(filters));
  }, []);

  const getTabs = useCallback(() => {
    const props = {
      year,
      month,
      type,
      subType,
      activeTab,
      tabsExpanded,
      onFetchTransactions,
      onDeleteTransaction,
      onCreateTransaction,
      categories,
      recipients,
      costsCenter,
      tags,
      selected_account_id,
      preferences,
      sorting,
      onMoveTransactions,
      onUpdateMultipleTransactions,
      onUpdateTransactionFilters: handleUpdateFilters,
      onApplyTagMultipleTransactions,
      onSortTransactions,
      availableTabs,
    };

    const tabs = [];

    const tabsStartWithExpense = availableTabs.filter((tab) => tab.startsWith('EXPENSE'));

    tabs.push({
      id: 'MONTH_PICKER',
      title: (
        <MonthPicker
          value={selectedDate}
          onChange={onChangeDate}
          variant="transactions"
        />
      ),
      content: null,
      noBackground: true,
    });

    if (availableTabs.includes('INCOME-null')) {
      tabs.push({
        id: 'INCOME',
        title: 'Recebimentos',
        content: activeTab === 'INCOME' && <TransactionsTable type="INCOME" {...props} />,
        shortName: 'recebimentos',
        color: 'success',
        className: 'tab-income',
      });
    }

    if (tabsStartWithExpense.length === 4) {
      tabs.push({
        id: 'EXPENSE',
        title: 'Despesas',
        content: activeTab === 'EXPENSE' && <TransactionsTable type="EXPENSE" {...props} />,
        shortName: 'despesas',
        color: 'danger',
        hidden: tabsExpanded,
        className: 'tab-expense',
      });
    }

    if (availableTabs.includes('EXPENSE-FIXED_EXPENSE')) {
      tabs.push({
        id: 'FIXED_EXPENSE',
        title: 'Despesas Fixas',
        content: activeTab === 'FIXED_EXPENSE' && <TransactionsTable type="EXPENSE" subType="FIXED_EXPENSE" {...props} />,
        shortName: 'despesas',
        color: 'danger',
        hidden: !tabsExpanded,
        className: 'tab-expense',
      });
    }

    if (availableTabs.includes('EXPENSE-VARIABLE_EXPENSE')) {
      tabs.push({
        id: 'VARIABLE_EXPENSE',
        title: 'Despesas Variáveis',
        content: activeTab === 'VARIABLE_EXPENSE' && <TransactionsTable type="EXPENSE" subType="VARIABLE_EXPENSE" {...props} />,
        shortName: 'despesas',
        color: 'danger',
        hidden: !tabsExpanded,
        className: 'tab-expense',
      });
    }

    if (availableTabs.includes('EXPENSE-PEOPLE')) {
      tabs.push({
        id: 'PEOPLE',
        title: 'Pessoas',
        content: activeTab === 'PEOPLE' && <TransactionsTable type="EXPENSE" subType="PEOPLE" {...props} />,
        shortName: 'despesas',
        color: 'danger',
        hidden: !tabsExpanded,
        className: 'tab-expense',
      });
    }

    if (availableTabs.includes('EXPENSE-TAXES')) {
      tabs.push({
        id: 'TAXES',
        title: 'Impostos',
        content: activeTab === 'TAXES' && <TransactionsTable type="EXPENSE" subType="TAXES" {...props} />,
        shortName: 'despesas',
        color: 'danger',
        hidden: !tabsExpanded,
        className: 'tab-expense',
      });
    }

    if (tabsStartWithExpense.length === 4) {
      tabs.push({
        id: 'EXPAND',
        icon: tabsExpanded ? <FaChevronLeft className="" /> : <FaChevronRight className="" />,
        content: null,
      });
    }

    if (availableTabs.includes('TRANSFER-null')) {
      tabs.push({
        id: 'TRANSFER',
        title: 'Transferências',
        content: activeTab === 'TRANSFER' && <TransactionsTable type="TRANSFER" subType={null} {...props} />,
        icon: <IoSwapHorizontalOutline size="1.2em" className="mr-2 text-primary" />,
        shortName: 'transferencias',
        color: 'info',
        className: 'tab-transfer',
      });
    }

    return tabs;
  }, [
    availableTabs,
    onCreateTransaction,
    onDeleteTransaction,
    onFetchTransactions,
    year,
    month,
    activeTab,
    tabsExpanded,
    categories,
    type,
    subType,
    recipients,
    costsCenter,
    tags,
    sorting,
    selected_account_id,
    preferences,
    onChangeDate,
    selectedDate,
    handleUpdateFilters,
    onMoveTransactions,
    onUpdateMultipleTransactions,
    onApplyTagMultipleTransactions,
    onSortTransactions,
  ]);

  useEffect(() => () => {
    localStorage.removeItem('transactions_view_type');
    localStorage.removeItem('transactions_filters');
    localStorage.removeItem('transactions_filters_form');
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (isMobile || isTablet) {
    return (
      <Container fluid className="content-wrapper">
        <TransactionsListMobile
          availableTabs={availableTabs}
          transactionFilters={transactionFilters}
          onUpdateTransactionFilters={handleUpdateFilters}
          onMoveTransactions={onMoveTransactions}
          sorting={sorting}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      </Container>
    );
  }

  return (
    <Container fluid className="content-wrapper" style={{ paddingBottom: '150px' }}>
      <ReactTooltip />
      <Row>
        <Col xs={12} sm={12} lg={12}>
          <BalanceSection />
          <StyledTabs
            variant="transactions"
            className="no-print"
            tabs={getTabs()}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            floatRightLastTab={availableTabs.includes('TRANSFER-null')}
          />
        </Col>
      </Row>
    </Container>
  );
}

Transactions.defaultProps = {
  transaction: {},
  selectedTransactionId: null,
  accounts: [],
  categories: [],
  recipients: [],
  costsCenter: [],
  tags: [],
  activeCompany: null,
  preferences: {},
  availableTabs: [],
};

Transactions.propTypes = {
  selected_account_id: PropTypes.string.isRequired,
  activeCompany: PropTypes.object,
  categories: PropTypes.array,
  recipients: PropTypes.array,
  costsCenter: PropTypes.array,
  tags: PropTypes.array,
  transaction: PropTypes.object,
  accounts: PropTypes.array,
  type: PropTypes.string,
  selectedTransactionId: PropTypes.string,
  preferences: PropTypes.object,
  sorting: PropTypes.object,
  onFetchTransactions: PropTypes.func.isRequired,
  onDeleteTransaction: PropTypes.func.isRequired,
  onUpdateTransaction: PropTypes.func.isRequired,
  onCreateTransaction: PropTypes.func.isRequired,
  onFetchBankAccounts: PropTypes.func.isRequired,
  onLoadTransactionsPageData: PropTypes.func.isRequired,
  onMoveTransactions: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onApplyTagMultipleTransactions: PropTypes.func.isRequired,
  onSortTransactions: PropTypes.func.isRequired,
  onClearDuplicatedTransactions: PropTypes.func.isRequired,
  availableTabs: PropTypes.array,
};

export default Transactions;
