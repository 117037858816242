import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import FrequencyModal from './FrequencyModal';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  recipients: state.companyTransactions.recipients,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onCreateRecurringTransaction: companyTransactionsActionCreators.createRecurringTransaction,
  onUpdateRecurringTransaction: companyTransactionsActionCreators.updateRecurringTransaction,
  onFindInstalmentsTransactions: companyTransactionsActionCreators.fetchInstalmentsTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyModal);
