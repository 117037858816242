import { connect } from 'react-redux';

import sidebarActionCreators from '_store/_actions/sidebar';
import userActionCreators from '_store/_actions/user';
import authActionCreators from '_store/_actions/auth';

import NavBar from './NavBar';

const mapStateToProps = (state) => ({
  collapsed: state.sidebar.collapsed,
  transactionsOverview: state.companyTransactions.transactionsOverview,
  user: state.auth.user,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onSidebarToggle: sidebarActionCreators.toggleSidebar,
  onLoadUserPermissions: userActionCreators.fetchUsersPermissions,
  onLogout: authActionCreators.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar);
