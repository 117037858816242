/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { Formik } from 'formik';

import { getChangedValues } from 'helpers';
import { Button, FormCurrencyField, FormDateField } from '_components/_core';
import Calculator from '_components/_shared/Calculator/Calculator';

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      const isClickOnOption = event.target.id.includes('react-select');
      const isCreatableOption = event.target.id.includes('creatable-option');
      const isDetailButton = event.target.id.includes('details-button');
      // const isModalOpen = document.querySelector('.modal.show');
      const isDatePicker = Array.from(event.target.classList).some((c) => c.includes('datepicker'));
      // const isPreventClose = document.querySelector('.transaction-form-open');
      const isToggleButton = event.target.id.includes('toggle-payment-button');

      if (ref.current
        && !ref.current.contains(event.target)
        && !isClickOnOption
        // && !isModalOpen
        && !isDatePicker
        && !isCreatableOption
        && !isDetailButton
        // && !isPreventClose
        && !isToggleButton) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);

  return ref;
};

function RowForm({
  transactionFormRef,
  selectedFieldName,
  parcela,
  qtdeParcelas,
  onCancelEditInstalment,
  onDeleteParcela,
  onSubmit,
  isMobile,
}) {
  const { frequency_number } = parcela;

  const eventDateRef = useRef();
  const amountRef = useRef();

  useEffect(() => {
    if (selectedFieldName === 'event_date') {
      if (eventDateRef.current) {
        eventDateRef.current.setFocus(true);
        eventDateRef.current.setOpen(true);
      }
    }

    if (selectedFieldName === 'amount') {
      if (amountRef.current) {
        amountRef.current.theInput.focus();
      }
    }
  }, [selectedFieldName, eventDateRef, amountRef]);

  const initialValues = useMemo(() => parcela, [parcela]);

  const handleSubmitParcela = useCallback((values) => {
    const changed = getChangedValues(values, initialValues);

    onSubmit(values, changed);
  }, [
    onSubmit,
    initialValues,
  ]);

  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();

      onCancelEditInstalment();
    }
  }, [onCancelEditInstalment]);

  const handleClickOutside = useCallback(() => {
    if (transactionFormRef.current) {
      transactionFormRef.current.handleSubmit();
    }

    onCancelEditInstalment();
  }, [onCancelEditInstalment, transactionFormRef]);

  const rowRef = useOutsideClick(handleClickOutside);

  const renderToggleField = useCallback((values, setFieldValue) => {
    const { paid } = values;

    return (
      <span className="d-flex">
        {!paid && <BsToggleOff id="creatable-option" size="2.5em" style={{ cursor: 'pointer' }} className="text-muted" onClick={() => setFieldValue('paid', true)} />}
        {paid && <BsToggleOn id="creatable-option" size="2.5em" style={{ cursor: 'pointer' }} className="text-success" onClick={() => setFieldValue('paid', false)} />}
      </span>
    );
  }, []);

  return (
    <>
      <Formik
        innerRef={transactionFormRef}
        initialValues={parcela}
        onSubmit={handleSubmitParcela}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <tr ref={rowRef} onKeyPress={(e) => handleKeyPress(e, handleSubmit)}>
              <td data-label="#" className="number-column">
                {`${frequency_number}/${qtdeParcelas}`}
              </td>
              <td data-label="Vencimento" className="event-date-column">
                <FormDateField
                  inputStyleProps={{
                    textAlign: 'center',
                  }}
                  innerRef={eventDateRef}
                  name="event_date"
                  className="text-center"
                />
              </td>
              <td data-label="Valor" className="amount-column">
                <FormCurrencyField
                  innerRef={amountRef}
                  name="amount"
                  forceZero
                />
                {!isMobile && (
                  <Calculator
                    value={String(values.amount)}
                    onChange={(amount) => setFieldValue('amount', amount)}
                  />
                )}
              </td>
              <td
                data-label="Pago?"
                className="paid-column"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setFieldValue('paid', !values.paid);
                }}
              >
                {renderToggleField(values, setFieldValue)}
              </td>
              <td data-label="Excluir" className="buttons-column">
                <Button
                  noMargin
                  variant="link"
                  className="text-muted btn-sm"
                  icon={<FaTrash size="1.2rem" />}
                  onClick={() => onDeleteParcela(parcela)}
                />
              </td>
            </tr>
          </>
        )}
      </Formik>
    </>
  );
}

RowForm.defaultProps = {
  selectedFieldName: null,
  isMobile: false,
};

RowForm.propTypes = {
  parcela: PropTypes.object.isRequired,
  qtdeParcelas: PropTypes.number.isRequired,
  onDeleteParcela: PropTypes.func.isRequired,
  onCancelEditInstalment: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedFieldName: PropTypes.string,
  transactionFormRef: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

export default RowForm;
