import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';

import { StyledActionButton, StyledMobileActionButton } from '../styles';

import useTransferForm from '../utilities/useTransferForm';

function ActionButton({
  type,
  subType,
  onToggleTransactionCreateForm,
  onClearNewTransaction,
  isMobile,
  onToggleMobileTransactionForm,
}) {
  const { onToggleTransferForm } = useTransferForm();

  const handleCreateEmptyTransaction = () => {
    onToggleTransactionCreateForm(type, subType, () => {
      onClearNewTransaction();
    });
  };

  const handleCreateMobileTransaction = () => {
    onToggleMobileTransactionForm();
  };

  return (
    <>
      {type === 'INCOME' && (
        <StyledActionButton
          icon={<FaPlus />}
          iconPosition="left"
          className="ml-3"
          onClick={handleCreateEmptyTransaction}
          type={type}
          subType={subType}
          id="btn-adicionar-recebimento"
        >
          Adicionar recebimento
        </StyledActionButton>
      )}
      {(type === 'EXPENSE' || ['FIXED_EXPENSE', 'VARIABLE_EXPENSE', 'PEOPLE', 'TAXES'].includes(subType)) && (
        <StyledActionButton
          variant="danger"
          icon={<FaPlus />}
          iconPosition="left"
          className="ml-3"
          onClick={handleCreateEmptyTransaction}
          type={type}
          subType={subType}
          id="btn-adicionar-despesa"
        >
          Adicionar despesa
        </StyledActionButton>
      )}
      {type === 'TRANSFER' && (
        <StyledActionButton
          variant="info"
          icon={<FaPlus />}
          iconPosition="left"
          className="ml-3"
          onClick={() => onToggleTransferForm()}
          type={type}
          subType={subType}
          id="btn-adicionar-transferencia"
        >
          Adicionar transferência
        </StyledActionButton>
      )}
      {isMobile && (
        <StyledMobileActionButton
          className="d-flex flex-column justify-content-center align-items-center"
          isMobile
          variant="dark"
          icon={<FaPlus className="mr-2" />}
          iconPosition="right"
          onClick={handleCreateMobileTransaction}
          type={type}
          subType={subType}
        />
      )}
    </>
  );
}

ActionButton.defaultProps = {
  isMobile: false,
  onToggleMobileTransactionForm: () => {},
};

ActionButton.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  onToggleTransactionCreateForm: PropTypes.func.isRequired,
  onClearNewTransaction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  onToggleMobileTransactionForm: PropTypes.func,
};

export default ActionButton;
