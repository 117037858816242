/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IoCaretDown, IoCloseCircle } from 'react-icons/io5';

import { ActionSheet, Button } from '_components/_core';

import {
  expense_items,
  income_items,
  cashflow_items,
  REPORT_FRIENDLY_NAMES,
} from '../../utilities';

import {
  Menu,
  MenuCategory,
  MenuItem,
  GridContainer,
} from './styles';

function ReportSelect({
  values,
  isMobile,
  isTablet,
  setValues,
  onReportSelect,
}) {
  const [actionSheetOpen, setActionSheetOpen] = useState(false);

  const handleClose = useCallback(() => {
    setActionSheetOpen(false);
  }, []);

  const handleReportSelect = useCallback((item) => {
    onReportSelect(item, setValues);

    setActionSheetOpen(false);
  }, [onReportSelect, setValues]);

  const content = (
    <>
      <MenuCategory>
        Despesas
      </MenuCategory>
      <GridContainer>
        {expense_items.map((item) => (
          <MenuItem
            key={item.tab}
            active={values.report_name === item.tab}
            onClick={() => handleReportSelect(item)}
            className="d-flex align-items-center"
          >
            <item.icon size="1.3em" className="mr-3 text-danger" />
            {item.name}
          </MenuItem>
        ))}
      </GridContainer>
      <hr />
      <MenuCategory>
        Recebimentos
      </MenuCategory>
      <GridContainer>
        {income_items.map((item) => (
          <MenuItem
            key={item.tab}
            active={values.report_name === item.tab}
            onClick={() => handleReportSelect(item)}
            className="d-flex align-items-center"
          >
            <item.icon size="1.3em" className="mr-3 text-success" />
            {item.name}
          </MenuItem>
        ))}
      </GridContainer>
      <hr />
      <MenuCategory>
        Fluxo de Caixa
      </MenuCategory>
      <GridContainer>
        {cashflow_items.map((item) => (
          <MenuItem
            key={item.tab}
            active={values.report_name === item.tab}
            onClick={() => handleReportSelect(item)}
            className="d-flex align-items-center"
          >
            <item.icon size="1.3em" className="mr-3 text-success" />
            {item.name}
          </MenuItem>
        ))}
      </GridContainer>
    </>
  );

  if (isMobile || isTablet) {
    return (
      <>
        <div className="w-100" onClick={() => setActionSheetOpen(true)}>
          <h6
            className="d-flex align-items-center m-0"
            style={{ cursor: 'pointer' }}
          >
            {!values.report_name && (
              <span className="text-muted">
                Selecione um relatório
              </span>
            )}
            {values.report_name && (
              <span>
                {REPORT_FRIENDLY_NAMES[values.report_name]}
              </span>
            )}
            {<IoCaretDown className="ml-1" />}
          </h6>
        </div>
        <ActionSheet
          isOpen={actionSheetOpen}
          onToggle={() => setActionSheetOpen(!actionSheetOpen)}
          detent="content-height"
          prefersReducedMotion
        >
          <div>
            <div className="ml-3 mr-3 mb-3 d-flex justify-content-between align-items-center">
              <h5 className="m-0">
                Relatórios
              </h5>
              <IoCloseCircle className="text-muted" size="2em" onClick={handleClose} />
            </div>
            {content}
            <Button onClick={handleClose} className="w-100 m-0 p-0 mt-4 mb-4" variant="link">
              Fechar
            </Button>
          </div>
        </ActionSheet>
      </>
    );
  }

  return (
    <Menu className="pt-3 no-print">
      {content}
    </Menu>
  );
}

ReportSelect.propTypes = {
  values: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  onReportSelect: PropTypes.func.isRequired,
};

export default ReportSelect;
