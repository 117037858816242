/* eslint-disable no-alert */
import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'sheetjs-style';

import { CELL_TYPES } from './constants';
import getTransactionDescription from '../getTransactionDescription';
import getTransactionDate from '../getTransactionDate';

const reportToExcel = ({
  report_name,
  reports,
  selected_tags,
  date_type,
}) => {
  const { result, result_other } = reports[report_name] || [];

  const results = [...result, ...result_other];

  if (isEmpty(results)) return;

  if (isEmpty(selected_tags)) {
    alert('Selecione ao menos uma tag para exportar o relatório.');

    return;
  }

  let total = 0;

  const spreadsheetData = [['Pago?', 'Data', 'Descrição', 'Contato', 'Categoria', 'Valor']];

  const transactionsType = [null, 'HEADERS'];

  for (const item of results) {
    if (selected_tags.includes(item.description)) {
    // eslint-disable-next-line no-loop-func
      item.transactions.forEach((transaction) => {
        spreadsheetData.push([
          transaction.paid ? 'Sim' : 'Não',
          getTransactionDate(transaction, date_type),
          getTransactionDescription(transaction),
          transaction.recipient.name || '-',
          transaction.category.description || '-',
          transaction.amount *= -1,
        ]);

        transactionsType.push('EXPENSE');

        total += transaction.amount;
      });
    }
  }

  spreadsheetData.push(
    [null, null, null, null, null, null],
    ['Total', '-', '-', '-', '-', total *= -1],
  );

  transactionsType.push(null, 'HEADERS');

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  worksheet['!cols'] = [{ width: 7 }, { width: 12 }, { width: 40 }, { width: 30 }, { width: 30 }, { width: 16 }];

  for (let i = 1; i <= spreadsheetData.length; i += 1) {
    if (transactionsType[i] !== null) {
      worksheet[`A${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`B${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`C${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`D${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`E${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`F${i}`].s = CELL_TYPES[transactionsType[i]];
    }
    if (i !== 1 && i !== spreadsheetData.length - 1) {
      worksheet[`F${i}`].t = 'n';
      worksheet[`F${i}`].z = '#,##0.00';
    }
  }

  worksheet['!merges'] = [
    { s: { r: spreadsheetData.length - 1, c: 0 }, e: { r: spreadsheetData.length - 1, c: 5 } },
  ];

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Despesas_Por_Tag');

  XLSX.writeFile(workbook, 'Zenply_Despesas_Por_Tag.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
