/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import groupBy from 'lodash/groupBy';
import { FaArrowLeft } from 'react-icons/fa';

import { Button } from '_components/_core';
import { VARIABLES } from '../utilities';
import { StyledListGroupItem, StyledListGroup, StyledVariableGroups } from './styles';

pluralize.addIrregularRule('item', 'itens');

function VariablesList({ onAddVariable }) {
  const [selectedGroup, setSelectedGroup] = React.useState('DEFAULT');

  const groupedVariables = useMemo(() => {
    const validVariables = VARIABLES.filter((variable) => !variable.hidden);

    const grouped = groupBy(validVariables, 'group');

    return grouped;
  }, []);

  const groups = useMemo(() => Object.keys(groupedVariables), [groupedVariables]);

  const TRANSLATION = {
    DEFAULT: 'Sistema',
    COMPANY: 'Empresa',
    TRANSACTION: 'Transação',
    CONTACT: 'Contato',
  };

  return (
    <>
      <StyledVariableGroups>
        {groups.map((group) => (
          <StyledListGroupItem
            onClick={() => setSelectedGroup(group)}
            active={selectedGroup === group}
          >
            {TRANSLATION[group] || group}
            <span className="badge badge-primary badge-pill ml-2">
              {groupedVariables[group].length}
            </span>
          </StyledListGroupItem>
        ))}
      </StyledVariableGroups>
      <StyledListGroup>
        <small className="text-muted text-uppercase mt-2 mb-1">
          Variáveis de {TRANSLATION[selectedGroup] || TRANSLATION.DEFAULT}
        </small>
        {groupedVariables[selectedGroup].map((variable) => (
          <StyledListGroupItem onClick={() => onAddVariable(variable)}>
            <Button className="m-0 p-1 mr-3" variant="default">
              <FaArrowLeft />
            </Button>
            <div className="d-flex flex-column">
              <div>
                <strong role="button" tabIndex={0}>
                  {variable.name}
                </strong>
              </div>
              <small className="text-muted">
                {variable.description}
              </small>
            </div>
          </StyledListGroupItem>
        ))}
      </StyledListGroup>
    </>
  );
}

VariablesList.defaultProps = {};

VariablesList.propTypes = {
  onAddVariable: PropTypes.func.isRequired,
};

export default VariablesList;
