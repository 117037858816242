import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import Dropdown from 'react-bootstrap/Dropdown';

import CostCenterItem from './components/CostCenterItem/CostCenterItem';
import SelectAllCostCentersItem from './components/SelectAllCostCentersItem/SelectAllCostCentersItem';
import ManageCostCentersItem from './components/ManageCostCentersItem/ManageCostCentersItem';
import TriggerContent from './components/TriggerContent/TriggerContent';

import {
  Trigger,
  Content,
} from './styles';

function CostCenterSelect({
  value,
  onChange,
  costsCenter: allCostsCenter,
  isMultiple,
  variant,
  displayPrincipalStar,
  placeholder,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const costsCenter = useMemo(() => {
    if (isEmpty(allCostsCenter)) {
      return [];
    }

    return sortBy(allCostsCenter, 'description');
  }, [allCostsCenter]);

  const handleMenuToggle = useCallback((open, ev, metadata) => {
    if (metadata.source === 'select') {
      setIsMenuOpen(true);
      return;
    }

    setIsMenuOpen(open);
  }, []);

  const handleCostCenterSelected = useCallback((cost_center_id) => {
    if (isMultiple) {
      if (value.includes(cost_center_id)) {
        const newValue = value.filter((id) => id !== cost_center_id);

        onChange(newValue);
      } else {
        onChange([...value, cost_center_id]);
      }

      return;
    }

    onChange([cost_center_id]);
    setIsMenuOpen(false);
  }, [isMultiple, onChange, value]);

  const handleSelectAll = useCallback(() => {
    if (!isEmpty(value) && value.length === costsCenter.length) {
      onChange([]);
    } else {
      const costCenterIds = costsCenter.map((costCenter) => costCenter.id);

      onChange(costCenterIds);
      setIsMenuOpen(false);
    }
  }, [value, onChange, costsCenter]);

  const costCenterText = useMemo(() => {
    if (isEmpty(value)) {
      return (
        <span className="text-muted">
          {placeholder}
        </span>
      );
    }

    if (value.length === 0) {
      return (
        <span className="text-muted">
          {placeholder}
        </span>
      );
    }

    if (value.length === 1) {
      const found = costsCenter.find((costCenter) => costCenter.id === value[0]);
      const { description } = found || { };

      return description;
    }

    if (value.length === costsCenter.length) {
      return 'Todos os centros de custo';
    }

    return `${value.length} centros de custo selecionados`;
  }, [value, costsCenter, placeholder]);

  const isAllChecked = useMemo(() => {
    if (isEmpty(value)) {
      return false;
    }

    if (value.length === 0) {
      return false;
    }

    return costsCenter.every((costCenter) => value.includes(costCenter.id));
  }, [costsCenter, value]);

  return (
    <>
      <Dropdown show={isMenuOpen} onToggle={handleMenuToggle} drop="down" align="left">
        <Trigger as={Dropdown.Toggle} variant={variant} size="sm" className="d-flex align-items-center">
          <TriggerContent
            variant={variant}
            costCenterText={costCenterText}
          />
        </Trigger>
        <Content>
          <SelectAllCostCentersItem
            variant={variant}
            isAllChecked={isAllChecked}
            isMultiple={isMultiple}
            onSelectAllCostCenters={handleSelectAll}
          />
          <hr className="mb-0 mt-0" />
          {costsCenter.map((costCenter) => (
            <CostCenterItem
              key={costCenter.id}
              selectedCostCenterIds={value}
              variant={variant}
              isMultiple={isMultiple}
              displayPrincipalStar={displayPrincipalStar}
              costCenter={costCenter}
              onCostCenterSelected={handleCostCenterSelected}
            />
          ))}
          <hr className="mb-0 mt-0" />
          <ManageCostCentersItem />
        </Content>
      </Dropdown>
    </>
  );
}

CostCenterSelect.defaultProps = {
  variant: 'default',
  costsCenter: [],
  value: [],
  isMultiple: true,
  displayPrincipalStar: false,
  placeholder: 'Selecione...',
};

CostCenterSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,

  costsCenter: PropTypes.array,

  isMultiple: PropTypes.bool,
  displayPrincipalStar: PropTypes.bool,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CostCenterSelect;
