/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Container } from './styles';

const CodeInput = ({ length, onComplete }) => {
  const [values, setValues] = useState(Array(length).fill(''));
  const elements = useRef([]);

  useEffect(() => {
    if (elements.current[0]) {
      elements.current[0].focus();
    }
  }, []);

  const handleChange = (index, event) => {
    const newValues = [...values];

    if (event.key === 'Backspace' && event.target.value === '' && index > 0) {
      elements.current[index - 1].focus();
    } else {
      newValues[index] = event.target.value.toUpperCase();
      setValues(newValues);

      if (index < length - 1 && event.target.value) {
        elements.current[index + 1].focus();
      }
    }

    if (newValues.every((value) => value !== '')) {
      onComplete(newValues.join(''));
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !values[index]) {
      if (index > 0) {
        elements.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const pasteData = event.clipboardData.getData('text').split('').filter((_, index) => index < length).map((value) => value.toUpperCase());

    if (pasteData.length === length) {
      setValues(pasteData);

      onComplete(pasteData.join(''));
    }
  };

  return (
    <Container onPaste={handlePaste}>
      {Array(length).fill().map((_, index) => (
        <Form.Control
          autoFocus={index === 0}
          key={index}
          type="text"
          maxLength="1"
          value={values[index]}
          autoComplete="new-password"
          onClick={() => elements.current[index].select()}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(el) => elements.current[index] = el}
        />
      ))}
    </Container>
  );
};

CodeInput.propTypes = {
  length: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default CodeInput;
