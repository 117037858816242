/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { IoCopy, IoCopyOutline } from 'react-icons/io5';
import { Container } from './styles';

const CopyToClipboard = ({ text, emptyText, children }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await navigator.clipboard.writeText(text);

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    } catch (error) {
      setIsCopied(false);
    }
  };

  if (!text || isEmpty(text)) return emptyText;

  return (
    <Container role="button" onClick={handleCopy}>
      {children}
      {isCopied ? <IoCopy size="1.2em" className="ml-1 text-success" />
        : <IoCopyOutline size="1.2em" className="ml-1" />}
    </Container>
  );
};

CopyToClipboard.defaultProps = {
  text: '',
  emptyText: '',
  children: null,
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  emptyText: PropTypes.string,
  children: PropTypes.node,
};

export default CopyToClipboard;
