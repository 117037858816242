/* eslint-disable max-len */
import React from 'react';
import FORMATTERS from 'helpers/formatters';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import isNull from 'lodash/isNull';

export const TABLE_COLUMNS = [
  {
    key: 'is_valid',
    label: 'Status',
    formatter: (value, row) => {
      if (!isNull(row.possible_duplicate) && row.possible_duplicate) {
        return <FaExclamationCircle size="1.3em" className="text-yellow" data-place="bottom" data-tip="Essa movimentação pode já estar cadastrada." />;
      }

      if (isNull(value)) {
        return <FaExclamationCircle size="1.3em" className="text-danger" data-place="bottom" data-tip="Esta movimentação deve ser mais detalhada para que possamos importá-la!" />;
      }

      if (!isNull(value) && !value) {
        return <FaExclamationCircle size="1.3em" className="text-danger" data-place="bottom" data-tip="Esta movimentação deve ser mais detalhada para que possamos importá-la!" />;
      }

      if (!isNull(value) && value) {
        return <FaCheckCircle size="1.3em" className="text-success" data-place="bottom" data-tip="Esta movimentação está pronta para ser importada!" />;
      }

      return 'Não preenchido';
    },
    className: 'text-center',
    isEditable: false,
    width: '5%',
  },
  {
    key: 'event_date',
    label: 'Date',
    formatter: (value) => FORMATTERS.DATE_DDMMYYYY(value),
    isEditable: false,
    width: '5%',
  },
  {
    key: 'description',
    label: 'Description',
    isEditable: true,
    width: '25%',
  },
  {
    key: 'temp_type',
    label: 'Type',
    formatter: (value, row) => FORMATTERS.BANK_STATEMENT_TRANSACTION_TYPE(value, row.temp_sub_type),
    className: 'text-left',
    isEditable: true,
  },
  {
    key: 'amount',
    label: 'Amount',
    formatter: (value, row) => FORMATTERS.BANK_STATEMENT_TRANSACTION_AMOUNT(
      value,
      row.bank_statement_transaction_type,
    ),
    className: 'text-left',
    isEditable: false,
  },
  {
    key: 'recipient',
    label: 'Recipient',
    formatter: (value, row, accounts) => {
      if (row.temp_type === 'TRANSFER') {
        return FORMATTERS.TRANSACTION_TRANSFER_DESTINATION(accounts, row);
      }

      return FORMATTERS.TRANSACTION_RECIPIENT(value);
    },
    isEditable: true,
  },
  {
    key: 'category',
    label: 'Category',
    formatter: (value, row, accounts) => {
      if (row.temp_type === 'TRANSFER') {
        return FORMATTERS.TRANSACTION_TRANSFER_ORIGIN(accounts, row);
      }

      return FORMATTERS.TRANSACTION_CATEGORY(value);
    },
    isEditable: true,
  },
];

export default TABLE_COLUMNS;
