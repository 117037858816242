import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import Dropdown from 'react-bootstrap/Dropdown';

import TagItem from './components/TagItem/TagItem';
import SelectAllTagsItem from './components/SelectAllTagsItem/SelectAllTagsItem';
import ManageTagsItem from './components/ManageTagsItem/ManageTagsItem';
import TriggerContent from './components/TriggerContent/TriggerContent';

import {
  Trigger,
  Content,
} from './styles';

function TagSelect({
  value,
  onChange,
  tags: allTags,
  isMultiple,
  variant,
  displayPrincipalStar,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const tags = useMemo(() => {
    if (isEmpty(allTags)) {
      return [];
    }

    return sortBy(allTags, 'description');
  }, [allTags]);

  const handleMenuToggle = useCallback((open, ev, metadata) => {
    if (metadata.source === 'select') {
      setIsMenuOpen(true);
      return;
    }

    setIsMenuOpen(open);
  }, []);

  const handleTagSelected = useCallback((tag_id) => {
    if (isMultiple) {
      if (value.includes(tag_id)) {
        const newValue = value.filter((id) => id !== tag_id);

        onChange(newValue);
      } else {
        onChange([...value, tag_id]);
      }

      return;
    }

    onChange([tag_id]);
    setIsMenuOpen(false);
  }, [isMultiple, onChange, value]);

  const handleSelectAll = useCallback(() => {
    if (!isEmpty(value) && value.length === tags.length) {
      onChange([]);
    } else {
      const tagIds = tags.map((tag) => tag.id);

      onChange(tagIds);
      setIsMenuOpen(false);
    }
  }, [value, onChange, tags]);

  const tagText = useMemo(() => {
    if (isEmpty(value)) {
      return (
        <span className="text-muted">
          Selecione...
        </span>
      );
    }

    if (value.length === 0) {
      return (
        <span className="text-muted">
          Selecione...
        </span>
      );
    }

    if (value.length === 1) {
      const found = tags.find((tag) => tag.id === value[0]);
      const { description } = found || { };

      return description;
    }

    return `${value.length} tags selecionadas`;
  }, [value, tags]);

  const isAllChecked = useMemo(() => {
    if (isEmpty(value)) {
      return false;
    }

    if (value.length === 0) {
      return false;
    }

    return tags.every((tag) => value.includes(tag.id));
  }, [tags, value]);

  return (
    <>
      <Dropdown show={isMenuOpen} onToggle={handleMenuToggle} drop="down" align="left">
        <Trigger as={Dropdown.Toggle} variant={variant} size="sm" className="d-flex align-items-center">
          <TriggerContent
            variant={variant}
            tagText={tagText}
          />
        </Trigger>
        <Content>
          {tags.map((tag) => (
            <TagItem
              key={tag.id}
              selectedTagIds={value}
              variant={variant}
              isMultiple={isMultiple}
              displayPrincipalStar={displayPrincipalStar}
              tag={tag}
              onTagSelected={handleTagSelected}
            />
          ))}
          <hr className="mb-0 mt-0" />
          <SelectAllTagsItem
            variant={variant}
            isAllChecked={isAllChecked}
            isMultiple={isMultiple}
            onSelectAllTags={handleSelectAll}
          />
          <hr className="mb-1 mt-0" />
          <ManageTagsItem />
        </Content>
      </Dropdown>
    </>
  );
}

TagSelect.defaultProps = {
  variant: 'default',
  tags: [],
  value: [],
  isMultiple: true,
  displayPrincipalStar: false,
};

TagSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,

  tags: PropTypes.array,

  isMultiple: PropTypes.bool,
  displayPrincipalStar: PropTypes.bool,
  variant: PropTypes.string,
};

export default TagSelect;
