/* eslint-disable no-unused-vars */
const getFormattedFilters = (activeFilters, filtersConfiguration) => {
  if (!filtersConfiguration) {
    return {};
  }

  const sanitizedFilters = Object.fromEntries(
    Object.entries(activeFilters).filter(([_, v]) => v != null),
  );

  const formattedFilters = {};

  const filterKeys = Object.keys(sanitizedFilters);

  filterKeys.forEach((key) => {
    if (filtersConfiguration.hasOwnProperty(key)) {
      const { label, formatter } = filtersConfiguration[key];

      if (formatter) {
        formattedFilters[key] = {
          label,
          value: formatter(sanitizedFilters[key]),
        };
      } else {
        formattedFilters[key] = {
          label,
          value: String(sanitizedFilters[key]),
        };
      }
    }
  });

  return formattedFilters;
};

export default getFormattedFilters;
