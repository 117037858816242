import HttpService from './http.service';

export default {
  fetchAllCompanyMeis,
  createMei,
  updateMei,
  fetchMeiDas,
  deleteMei,
  executeDasSearch,
};

async function fetchAllCompanyMeis(params) {
  return HttpService.get('/company-mei', params);
}

async function createMei(params) {
  return HttpService.post('/company-mei', params);
}

async function updateMei(id, params) {
  return HttpService.put('/company-mei', params);
}

async function fetchMeiDas(id, params) {
  return HttpService.get(`/company-mei/${id}/das`, params);
}

async function executeDasSearch(id, params) {
  return HttpService.post(`/company-mei/${id}/das`, params);
}

async function deleteMei(id) {
  return HttpService.delete(`/company-mei/${id}`);
}
