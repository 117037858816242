import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FormControl from 'react-bootstrap/FormControl';
import { IoArrowBackOutline, IoArrowForwardOutline, IoCalendarOutline } from 'react-icons/io5';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { Button } from '_components/_core';

import CustomStyleLoader from './CustomStyleLoader';
import { StyledMonthPicker, StyledButtonGroup } from './styles';

export default function CustomMonthPicker({
  onChange,
  value,
  variant,
  minDate,
  placeholder,
  position,
  endOfMonth,
  ...restProps
}) {
  const [selectedDate, setSelectedDate] = useState(value);
  const monthPickerRef = React.useRef(null);

  const pickerLang = {
    months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    from: 'De',
    to: 'Até',
  };

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };

  const closePicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  const handlePickerChange = (year, month) => {
    let luxonDate = null;

    if (minDate) {
      const {
        year: minYear,
        month: minMonth,
        day: minDay,
      } = DateTime.fromISO(minDate, { zone: 'utc' });

      if (year === minYear && month === minMonth && !endOfMonth) {
        luxonDate = DateTime.fromObject({
          year,
          month,
          day: minDay,
        }, { zone: 'utc' });

        setSelectedDate(luxonDate);
        onChange(luxonDate);
        closePicker();

        return;
      }

      if (year === minYear && month === minMonth && endOfMonth) {
        luxonDate = DateTime.fromObject({
          year,
          month,
          day: minDay,
        }, { zone: 'utc' }).endOf('month');

        setSelectedDate(luxonDate);
        onChange(luxonDate);
        closePicker();

        return;
      }
    }

    if (endOfMonth) {
      luxonDate = DateTime.fromObject({
        year,
        month,
        day: 1,
      }, { zone: 'utc' }).endOf('month');
    } else {
      luxonDate = DateTime.fromObject({
        year,
        month,
        day: 1,
      }, { zone: 'utc' });
    }

    setSelectedDate(luxonDate);
    onChange(luxonDate);
    closePicker();
  };

  const formattedValue = useMemo(() => {
    const { year, month } = selectedDate || DateTime.now();

    return {
      year,
      month,
    };
  }, [selectedDate]);

  const handleMonthDecreased = useCallback(() => {
    const newDate = selectedDate.minus({ month: 1 });

    setSelectedDate(newDate);
    onChange(newDate);
  }, [selectedDate, onChange]);

  const handleMonthIncreased = useCallback(() => {
    const newDate = selectedDate.plus({ month: 1 });

    setSelectedDate(newDate);
    onChange(newDate);
  }, [selectedDate, onChange]);

  const displayValue = useMemo(() => {
    if (!selectedDate) {
      return '';
    }

    if (variant === 'transactions') {
      return selectedDate.toFormat('MMMM yyyy', { locale: 'pt-BR' });
    }

    return selectedDate.toFormat('MM/yyyy');
  }, [selectedDate, variant]);

  const content = useMemo(() => {
    if (variant === 'button') {
      return (
        <ButtonGroup>
          <Button variant="secondary" onClick={handleMonthDecreased}>
            <IoArrowBackOutline />
          </Button>
          <Button variant="secondary" onClick={showPicker}>
            {displayValue}
          </Button>
          <Button variant="secondary" onClick={handleMonthIncreased}>
            <IoArrowForwardOutline />
          </Button>
        </ButtonGroup>
      );
    }

    if (variant === 'transactions') {
      return (
        <StyledButtonGroup>
          <Button variant="secondary" onClick={handleMonthDecreased}>
            <FaChevronLeft />
          </Button>
          <Button
            style={{ minWidth: '160px' }}
            variant="secondary"
            onClick={showPicker}
          >
            <IoCalendarOutline className="mr-2" />
            {displayValue}
          </Button>
          <Button variant="secondary" onClick={handleMonthIncreased}>
            <FaChevronRight />
          </Button>
        </StyledButtonGroup>
      );
    }

    return (
      <FormControl
        type="text"
        value={displayValue}
        autoComplete="off"
        onClick={showPicker}
        placeholder={placeholder}
      />
    );
  }, [
    variant,
    displayValue,
    placeholder,
    handleMonthIncreased,
    handleMonthDecreased,
  ]);

  const years = useMemo(() => {
    if (minDate) {
      const { year, month } = DateTime.fromISO(minDate, { zone: 'utc' });

      return {
        min: {
          year,
          month,
        },
      };
    }

    const maxYears = 100;
    const years = [];

    Array.from({ length: maxYears }).forEach((_, index) => {
      years.push(2000 + index);
    });

    return years;
  }, [minDate]);

  const renderCustomStyle = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <CustomStyleLoader />;
    }

    return null;
  }, []);

  return (
    <>
      {renderCustomStyle()}
      <StyledMonthPicker
        key={JSON.stringify(selectedDate)}
        ref={monthPickerRef}
        years={years}
        lang={pickerLang.months}
        position={position}
        value={formattedValue}
        onChange={handlePickerChange}
        variant={variant}
        {...restProps}
      >
        {content}
      </StyledMonthPicker>
    </>
  );
}

CustomMonthPicker.defaultProps = {
  variant: 'button',
  value: null,
  minDate: null,
  placeholder: '',
  position: 'left',
  endOfMonth: false,
};

CustomMonthPicker.propTypes = {
  variant: PropTypes.string,
  position: PropTypes.string,
  endOfMonth: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  minDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultDate: PropTypes.any,
  handleMonthIncreased: PropTypes.func.isRequired,
  handleMonthDecreased: PropTypes.func.isRequired,
};
