import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import './custom-calendar.css';

import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import { TransactionForm } from '_components/_shared';
import alertActions from '_store/_actions/alert.actions';

import {
  Card,
  CardBody,
  CardHeader,
} from '_components/_core';

import { TransactionsList } from './components';
import { FlagsContainer, MonthName, StyledHeader } from './styles';

function MonthSchedule({
  transactions,
  selectedDate,
  selectedAccountIds,
  accounts,
  expiredTransactions,
  onToggleTransactionPaid,
  onFetchCashflow,
  onFetchBankAccountBalance,
  onFetchTransactions,
  onFetchExpiredTransactions,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, onChange] = useState(() => {
    if (!selectedDate) {
      return new Date();
    }

    return selectedDate.toJSDate();
  });
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);

  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );
  const dispatch = useDispatch();

  const handleTogglePaid = useCallback((e, transaction) => {
    const { type, sub_type } = transaction || {};

    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-SENT': 'aba_transferencia_edit',
      'TRANSFER-RECEIVED': 'aba_transferencia_edit',
    };

    const permissionName = permissionMap[`${type}-${sub_type}`];

    const allowed = hasPermissions({
      permissions: [permissionName],
      userPermissions,
      type: 'all',
    });

    if (!allowed) {
      e.stopPropagation();

      dispatch(alertActions.error('Você não tem permissão para editar este lançamento.'));

      return;
    }

    const date = DateTime.fromISO(transaction.event_date);
    const year = date.toFormat('yyyy');
    const month = date.toFormat('MM');

    const params = {
      year,
      month,
      account_ids: selectedAccountIds,
    };

    e.stopPropagation();

    onToggleTransactionPaid(transaction.id, !transaction.paid, () => {
      onFetchCashflow(params);
      onFetchBankAccountBalance(params);
    });
  }, [
    onToggleTransactionPaid,
    onFetchBankAccountBalance,
    onFetchCashflow,
    selectedAccountIds,
    dispatch,
    userPermissions,
  ]);

  const handleEditTransaction = useCallback((transaction) => {
    const { type, sub_type } = transaction || {};

    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-SENT': 'aba_transferencia_edit',
      'TRANSFER-RECEIVED': 'aba_transferencia_edit',
    };

    const permissionName = permissionMap[`${type}-${sub_type}`];

    const allowed = hasPermissions({
      permissions: [permissionName],
      userPermissions,
      type: 'all',
    });

    if (!allowed) {
      dispatch(alertActions.error('Você não tem permissão para editar este lançamento.'));

      return;
    }

    setSelectedTransaction(transaction);
    setIsOpen(!isOpen);
  }, [isOpen, dispatch, userPermissions]);

  const handleToggleTransactionForm = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const isTodaySelected = useMemo(() => {
    const luxonDate = DateTime.fromJSDate(value, { zone: 'utc' });
    const today = DateTime.local();

    return luxonDate.hasSame(today, 'day');
  }, [value]);

  const formattedMonthName = useMemo(() => {
    if (!selectedDate) {
      return '';
    }

    return selectedDate.toFormat('MMMM/yyyy', { locale: 'pt-BR' });
  }, [selectedDate]);

  const selectedDay = useMemo(() => {
    const luxonDate = DateTime.fromJSDate(value, { zone: 'utc' });

    return luxonDate.toFormat('d');
  }, [value]);

  const flags = useMemo(() => {
    const found_flags = {};

    transactions.forEach((transaction) => {
      const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });
      const transactionDay = transactionDate.toFormat('d');
      const transactionType = transaction.type;

      if (!found_flags[transactionDay]) {
        found_flags[transactionDay] = {
          income: false,
          expense: false,
          transfer: false,
        };
      }

      if (transactionType === 'INCOME') {
        found_flags[transactionDay].income = true;
      }

      if (transactionType !== 'INCOME' && transactionType !== 'TRANSFER') {
        found_flags[transactionDay].expense = true;
      }

      if (transactionType === 'TRANSFER') {
        found_flags[transactionDay].transfer = true;
      }
    });

    return found_flags;
  }, [transactions]);

  const renderFlags = useCallback((day) => {
    const dayNumber = day.getDate();

    return (
      <FlagsContainer>
        {has(flags, dayNumber) && flags[dayNumber].income && (
          <span className="transaction-flag income-flag" />
        )}
        {has(flags, dayNumber) && flags[dayNumber].expense && (
          <span className="transaction-flag expense-flag" />
        )}
        {has(flags, dayNumber) && flags[dayNumber].transfer && (
        <span className="transaction-flag transfer-flag" />
        )}
      </FlagsContainer>
    );
  }, [flags]);

  const Legend = () => (
    <div className="d-flex justify-content-center mt-2 mb-1">
      <div className="d-flex justify-content-center align-items-center">
        <span className="transaction-flag income-flag" />
        <small className="ml-1">
          recebimentos
        </small>
      </div>
      <div className="d-flex justify-content-center align-items-center ml-2">
        <span className="transaction-flag expense-flag" />
        <small className="ml-1">
          despesas
        </small>
      </div>
      <div className="d-flex justify-content-center align-items-center ml-2">
        <span className="transaction-flag transfer-flag" />
        <small className="ml-1">
          transferências
        </small>
      </div>
    </div>
  );

  const accountName = useMemo(() => {
    if (isEmpty(selectedAccountIds) || isEmpty(accounts)) {
      return '';
    }

    if (selectedAccountIds.length === 1) {
      const [accountId] = selectedAccountIds;

      const account = accounts.find((a) => a.id === accountId);

      return account ? account.description : '';
    }

    if (selectedAccountIds.length === accounts.length) {
      return 'Todas as contas';
    }

    const names = accounts
      .filter((a) => selectedAccountIds.includes(a.id))
      .map((a) => a.description);

    if (names.length > 5) {
      return `${names.slice(0, 5).join(', ')}...e mais ${names.length - 5} contas`;
    }

    return names.join(', ');
  }, [accounts, selectedAccountIds]);

  const dayTransactions = useMemo(() => transactions.filter((transaction) => {
    const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });
    const selectedDate = DateTime.fromJSDate(value, { zone: 'utc' });

    return transactionDate.hasSame(selectedDate, 'day');
  }), [transactions, value]);

  const handleAfterSaveTransaction = useCallback(() => {
    onFetchTransactions({
      year: selectedDate.year,
      month: selectedDate.month,
      account_ids: selectedAccountIds,
      paid: false,
    });

    onFetchExpiredTransactions({
      account_ids: selectedAccountIds,
    });
  }, [onFetchTransactions, onFetchExpiredTransactions, selectedAccountIds, selectedDate]);

  return (
    <>
      <TransactionForm
        isOpen={isOpen}
        transaction={selectedTransaction}
        onToggleForm={handleToggleTransactionForm}
        selectedDate={selectedDate}
        hiddenFields={[
          'type_sub_type',
        ]}
        mode="report-edit"
        onAfterSaveCallbackWithReset={handleAfterSaveTransaction}
        onAfterSaveSplitCallback={handleAfterSaveTransaction}
        transaction_id={selectedTransaction.id}
      />
      <Card className="mt-3 mt-lg-0">
        <CardHeader title="Agenda do mês" description={accountName} />
        <MonthName>
          {formattedMonthName}
        </MonthName>
        <Calendar
          onChange={onChange}
          value={value}
          showNavigation={false}
          showNeighboringMonth={false}
          locale="pt-BR"
          formatDay={(locale, date) => (
            <div className="day">
              {date.getDate()}
              {renderFlags(date)}
            </div>
          )}
        />
        <Legend />
        <CardBody noPaddingTop>
          <hr />
          <div className="d-flex align-items-center justify-content-between">
            <div className={!isEmpty(dayTransactions) ? 'mb-3' : ''}>
              <StyledHeader className={!isEmpty(dayTransactions) ? 'mb-1' : ''}>
                {`Vencimentos do dia ${selectedDay}`}
              </StyledHeader>
              {!isEmpty(dayTransactions) && (
                <small className="text-muted">
                  {`${dayTransactions.length} ${dayTransactions.length === 1 ? 'movimentação' : 'movimentações'}`}
                </small>
              )}
            </div>
          </div>
          <TransactionsList
            selectedItems={selectedItems}
            onSetSelectedItems={setSelectedItems}
            transactions={dayTransactions}
            emptyMessage="Você não tem lançamentos para este dia. =)"
            onEditTransaction={handleEditTransaction}
            onTogglePaid={handleTogglePaid}
          />
        </CardBody>
        {isTodaySelected && (
          <CardBody noPaddingTop>
            <div className={!isEmpty(expiredTransactions) ? 'mb-3' : ''}>
              <StyledHeader className={!isEmpty(expiredTransactions) ? 'mb-1' : ''}>
                Lançamentos em atraso
              </StyledHeader>
              {!isEmpty(expiredTransactions) && (
                <small className="text-muted">
                  {`${expiredTransactions.length} ${expiredTransactions.length === 1 ? 'movimentação' : 'movimentações'}`}
                </small>
              )}
            </div>
            <TransactionsList
              selectedItems={selectedItems}
              onSetSelectedItems={setSelectedItems}
              transactions={expiredTransactions}
              emptyMessage="Você não tem lançamentos atrasados. =)"
              incomesTotalMessage="Recebimentos atrasados:"
              expensesTotalMessage="Despesas atrasadas:"
              transfersTotalMessage="Transferências atrasadas:"
              onEditTransaction={handleEditTransaction}
              onTogglePaid={handleTogglePaid}
            />
          </CardBody>
        )}
      </Card>
    </>
  );
}

MonthSchedule.defaultProps = {
  transactions: [],
  selectedAccountIds: [],
  accounts: [],
};

MonthSchedule.propTypes = {
  transactions: PropTypes.array,
  selectedAccountIds: PropTypes.array,
  selectedDate: PropTypes.instanceOf(DateTime),
  onToggleTransactionPaid: PropTypes.func.isRequired,
  onFetchCashflow: PropTypes.func.isRequired,
  onFetchBankAccountBalance: PropTypes.func.isRequired,
  expiredTransactions: PropTypes.array,
  accounts: PropTypes.array,
  onFetchTransactions: PropTypes.func.isRequired,
  onFetchExpiredTransactions: PropTypes.func.isRequired,
};

export default MonthSchedule;
