import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { IoSettingsOutline } from 'react-icons/io5';
import { Dropdown } from 'react-bootstrap';
import { PrintOptionsContext } from '_components/Financial/Reports/components/PrintOptionsContext';

import { ActionSheet, Button } from '_components/_core';
import {
  StyledHeader,
  Option,
  StyledDropdownItem,
  StyledButton,
} from './styles';

function PrintOptions({ isMobile }) {
  const { value, setValue } = useContext(PrintOptionsContext);

  const [actionSheetOpen, setActionSheetOpen] = useState(false);

  const handleClose = useCallback(() => {
    setActionSheetOpen(false);
  }, []);

  const handleMenuToggle = useCallback((open, ev, metadata) => {
    if (metadata.source === 'select') {
      setValue((previous) => ({
        ...previous,
        isMenuOpen: true,
      }));
      return;
    }

    setValue((previous) => ({
      ...previous,
      isMenuOpen: open,
    }));
  }, [setValue]);

  const handleOptionChange = useCallback((option, newValue) => {
    setValue((previous) => ({
      ...previous,
      [option]: newValue,
    }));
  }, [setValue]);

  const renderContent = useCallback(() => {
    const mainContent = (
      <>
        <StyledHeader><b>Posição da logomarca</b></StyledHeader>
        <StyledDropdownItem onClick={() => handleOptionChange('logoPosition', 'start')}>
          <Option
            name="logoPosition"
            type="radio"
            checked={value.logoPosition === 'start'}
            onChange={() => handleOptionChange('logoPosition', 'start')}
          />
          Esquerda
        </StyledDropdownItem>
        <StyledDropdownItem onClick={() => handleOptionChange('logoPosition', 'center')}>
          <Option
            name="logoPosition"
            type="radio"
            checked={value.logoPosition === 'center'}
            onChange={() => handleOptionChange('logoPosition', 'center')}
          />
          Centro
        </StyledDropdownItem>
        <StyledDropdownItem onClick={() => handleOptionChange('logoPosition', 'end')}>
          <Option
            name="logoPosition"
            type="radio"
            checked={value.logoPosition === 'end'}
            onChange={() => handleOptionChange('logoPosition', 'end')}
          />
          Direita
        </StyledDropdownItem>
        <StyledDropdownItem onClick={() => handleOptionChange('logoPosition', 'hide')}>
          <Option
            name="logoPosition"
            type="radio"
            checked={value.logoPosition === 'hide'}
            onChange={() => handleOptionChange('logoPosition', 'hide')}
          />
          Esconder
        </StyledDropdownItem>
        <Dropdown.Divider />
        <StyledHeader><b>Dados da empresa</b></StyledHeader>
        <StyledDropdownItem onClick={() => handleOptionChange('showCompanyData', true)}>
          <Option
            name="showCompanyData"
            type="radio"
            checked={value.showCompanyData}
            onChange={() => handleOptionChange('showCompanyData', true)}
          />
          Exibir
        </StyledDropdownItem>
        <StyledDropdownItem
          onClick={() => handleOptionChange('showCompanyData', false)}
        >
          <Option
            name="showCompanyData"
            type="radio"
            checked={!value.showCompanyData}
            onChange={() => handleOptionChange('showCompanyData', false)}
          />
          Esconder
        </StyledDropdownItem>
        <Dropdown.Divider />
        <StyledHeader><b>Data e Hora</b></StyledHeader>
        <StyledDropdownItem onClick={() => handleOptionChange('showPageData', true)}>
          <Option
            name="showPageData"
            type="radio"
            checked={value.showPageData}
            onChange={() => handleOptionChange('showPageData', true)}
          />
          Exibir
        </StyledDropdownItem>
        <StyledDropdownItem onClick={() => handleOptionChange('showPageData', false)}>
          <Option
            name="showPageData"
            type="radio"
            checked={!value.showPageData}
            onChange={() => handleOptionChange('showPageData', false)}
          />
          Esconder
        </StyledDropdownItem>
      </>
    );

    if (isMobile) {
      return (
        <div>
          {mainContent}
        </div>
      );
    }

    return (
      <Dropdown.Menu>
        {mainContent}
      </Dropdown.Menu>
    );
  }, [
    handleOptionChange,
    value,
    isMobile,
  ]);

  const buttonProps = useMemo(() => ({
    className: 'no-print d-flex justify-content-center align-items-center',
    variant: 'default',
    size: 'sm',
  }), []);

  if (isMobile) {
    return (
      <>
        <StyledButton
          className="d-flex justify-content-center align-items-center"
          onClick={() => setActionSheetOpen(true)}
          variant="default"
          size="sm"
        >
          <IoSettingsOutline size="1.2em" />
        </StyledButton>
        <ActionSheet
          isOpen={actionSheetOpen}
          onToggle={() => setActionSheetOpen(!actionSheetOpen)}
          detent="content-height"
          prefersReducedMotion
        >
          <div>
            {renderContent()}
            <Button onClick={handleClose} className="w-100 m-0 p-0 mt-4 mb-4" variant="link">
              Fechar
            </Button>
          </div>
        </ActionSheet>
      </>
    );
  }

  return (
    <>
      <Dropdown
        show={value.isMenuOpen}
        onToggle={handleMenuToggle}
        drop="down"
        align="left"
        autoClose="outside"
      >
        <StyledButton
          as={Dropdown.Toggle}
          {...buttonProps}
        >
          <IoSettingsOutline size="1.2em" />
        </StyledButton>
        {renderContent()}
      </Dropdown>
    </>
  );
}

PrintOptions.defaultProps = {
  isMobile: false,
};

PrintOptions.propTypes = {
  isMobile: PropTypes.bool,
};

export default PrintOptions;
