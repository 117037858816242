/* eslint-disable no-return-assign */
import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import {
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';

import { Button, FormSelectField, PageHeader } from '_components/_core';

import { VARIABLES } from '_components/Settings/components/Receipts/utilities';

const EDITOR_SCRIPT_PATH = `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`;
const EDITOR_TRANSLATION_PATH = `${process.env.PUBLIC_URL}/assets/langs/pt_BR.js`;

function TransactionReceipt({
  activeCompany,
  user,
  transaction,
  receipts,
  onFetchTransaction,
  onFetchContact,
  onFetchAllReceipts,
}) {
  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);
  const transactionId = params.get('transaction_id');

  const [transactionFound, setTransactionFound] = useState(false);
  const [contact, setContact] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    onFetchTransaction(transactionId, (foundTransaction) => {
      if (!foundTransaction) {
        setTransactionFound(false);

        return;
      }

      if (foundTransaction.recipient_id) {
        onFetchContact(foundTransaction.recipient_id, (contact) => {
          setContact(contact);
        });
      }
      setTransactionFound(true);
    });

    onFetchAllReceipts();
  }, [
    transactionId,
    activeCompany,
    onFetchTransaction,
    onFetchAllReceipts,
    onFetchContact,
  ]);

  const receiptsOptions = useMemo(() => {
    if (!receipts || isEmpty(receipts) || !transaction) return [];

    const { type } = transaction || {};

    const validReceipts = sortBy(receipts.filter((r) => r.type === type), 'name');

    return validReceipts.map((receipt) => ({
      value: receipt._id,
      label: receipt.name,
    }));
  }, [receipts, transaction]);

  const handleReceiptChange = useCallback((option, setFieldValue) => {
    const receipt = receipts.find((r) => r._id === option.value);

    if (!receipt) return;

    setFieldValue('receipt_id', receipt._id);

    let original = receipt.content;

    VARIABLES.forEach((variable) => {
      const { name, getter } = variable;

      let value = 'Não informado';

      if (getter) {
        value = getter({
          transaction,
          company: activeCompany,
          user,
          contact: contact || {},
        });
      }

      original = original.replaceAll(name, value);
    });

    editorRef.current.setContent(original);
  }, [receipts, transaction, activeCompany, user, contact]);

  const handleGoToReceipts = useCallback(() => {
    history.push('/recibos');
  }, [history]);

  if (!transactionFound) {
    return (
      <Container fluid className="content-wrapper">
        <PageHeader
          title="Emitir recibo"
          variant="small"
        />
        <div className="text-center">
          <h3>Transação não encontrada</h3>
          <Button onClick={handleGoToReceipts} variant="link" className="p-0 m-0">
            Ir para meus modelos
          </Button>
        </div>
      </Container>
    );
  }

  return (

    <Container fluid className="content-wrapper">
      <PageHeader
        title="Emitir recibo"
        variant="small"
      />
      <Formik
        enableReinitialize
        initialValues={{
          receipt_id: null,
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => (
          <>
            <Row>
              <Col lg={{ span: 8, offset: 2 }}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Selecione um modelo</Form.Label>
                      <FormSelectField
                        isSearchable={false}
                        name="receipt_id"
                        options={receiptsOptions}
                        onChange={(option) => handleReceiptChange(option, setFieldValue)}
                        value={receiptsOptions.find((o) => o.value === values.receipt_id)}
                      />
                      {isEmpty(receiptsOptions) && (
                        <small className="text-muted d-flex align-items-center pt-2">
                          Não encontramos nenhum modelo cadastrado.
                          <Button onClick={handleGoToReceipts} variant="link" className="p-0 m-0 btn btn-sm ml-2">
                            Ir para meus modelos
                          </Button>
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={{
                span: 8,
                offset: 2,
              }}
              >
                {!isEmpty(receiptsOptions) && (
                  <Editor
                    tinymceScriptSrc={EDITOR_SCRIPT_PATH}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue=""
                    init={{
                      height: 700,
                      menubar: false,
                      toolbar_mode: 'wrap',
                      selector: 'textarea',
                      plugins: 'lists advlist table pagebreak searchreplace fullscreen',
                      toolbar: 'blocks fontsizeinput bold italic underline alignleft aligncenter alignright alignjustify lineheight numlist bullist table | searchreplace pagebreak | fullscreen print',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      language_url: EDITOR_TRANSLATION_PATH,
                      language: 'pt_BR',
                      browser_spellcheck: false,
                      paste_data_images: false,
                    }}
                  />
                )}
              </Col>
            </Row>

          </>
        )}
      </Formik>
      <Button onClick={handleGoToReceipts} variant="link" className="p-0 m-0">
        Ir para meus modelos
      </Button>
    </Container>
  );
}

TransactionReceipt.defaultProps = {
  transaction: {},
};

TransactionReceipt.propTypes = {
  transaction: PropTypes.object,
  activeCompany: PropTypes.object,
  user: PropTypes.object,
  onFetchTransaction: PropTypes.func.isRequired,
  onFetchAllReceipts: PropTypes.func.isRequired,
  receipts: PropTypes.array,
  onFetchContact: PropTypes.func,
};

export default TransactionReceipt;
