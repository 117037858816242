import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import TransactionRowForm from './TransactionRowForm';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  categories: state.companyTransactions.categories,
  recipients: state.companyTransactions.recipients,
  costsCenter: state.companyTransactions.costsCenter,
  tags: state.companyTransactions.tags,
});

const mapDispatchToProps = {
  onChangeTransactionRecipient: companyTransactionsActionCreators.changeTransactionRecipient,
  onChangeTransactionCategory: companyTransactionsActionCreators.changeTransactionCategory,
  onChangeOriginAccount: companyTransactionsActionCreators.changeTransferOriginAccount,
  onChangeDestinationAccount: companyTransactionsActionCreators.changeTransferDestinationAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRowForm);
