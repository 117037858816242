/* eslint-disable react/no-this-in-sfc */
import React, {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import classNames from 'classnames';
import { REPORT_FRIENDLY_NAMES } from '../utilities';
import { ReportContext } from './ReportContext';
import PrintHeader from './PrintHeaderContainer';
import { ReportOptionsContainer, StyledCheckbox, StyledTable } from '../styles';

function LineChartReport({
  name,
  formattedPeriod,
  reports,
  account_ids,
  cost_center_ids,
  isMobile,
}) {
  const { setValue } = useContext(ReportContext);

  const [showTable, setShowTable] = useState(true);
  const [showChart, setShowChart] = useState(true);
  const [showIncome, setShowIncome] = useState(true);
  const [showExpense, setShowExpense] = useState(true);
  const [showResult, setShowResult] = useState(true);

  const reportRef = useRef();

  useEffect(() => {
    setValue(reportRef);
  }, [setValue]);

  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports[name] || {};
    const { results } = report;

    return results || {};
  }, [reports, name]);

  const e = useMemo(() => {
    if (isEmpty(data)) {
      return [];
    }

    if (name === 'expenses_by_day') {
      return [[], data.expenses.map((item) => item[2]), []];
    }

    if (name === 'income_by_day') {
      return [data.incomes.map((item) => item[2]), [], []];
    }

    const { length } = data.incomes;
    const results = [];
    const incomes = [];
    const expenses = [];

    Array.from({ length }).forEach((_, index) => {
      incomes.push(data.incomes[index][2]);
      expenses.push(data.expenses[index][2]);
      results.push(data.incomes[index][2] - data.expenses[index][2]);
    });

    return [
      incomes,
      expenses,
      results,
    ];
  }, [data, name]);

  const defaultOptions = useMemo(() => (
    {
      chart: {
        plotShadow: false,
        spacingTop: 30,
        spacingBottom: 30,
        spacingLeft: 24,
        spacingRight: 24,
        style: {
          fontFamily: 'Poppins',
        },
      },
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: ['historical_income_expenses'].includes(name),
      },
      yAxis: {
        title: false,
        labels: {
          formatter() {
            return `R$ ${FORMATTERS.NUMBER(this.value)}`;
          },
        },
        lineColor: '#D4D7DC',
        gridLineColor: '#D4D7DC',
        minorGridLineColor: '#F4F5F8',
        minorGridLineWidth: 1,
        minorTickLength: 0,
        minorTickInterval: 'auto',
      },
      xAxis: {
        categories: data.categories,
        lineColor: '#D4D7DC',
        tickColor: '#D4D7DC',
      },
      tooltip: {
        borderRadius: 3,
        style: {
          fontFamily: 'Poppins',
        },
        formatter() {
          let content = '';
          content += `<span style="color: ${this.point.color}; font-weight: 400;">`;
          content += '\u2022';
          content += '</span>';
          content += '<p style="font-weight: 500;">';
          content += this.series.name;
          content += '</p>';
          content += '<br />';
          content += '<strong style="font-weight:500;">';
          content += FORMATTERS.NUMBER(this.y);
          content += '</strong>';

          return content;
        },
        shadow: false,
        followTouchMove: false,
        followPointer: false,
        crosshairs: true,
      },
      plotOptions: {
        series: {
          shadow: false,
          animation: true,
          cursor: 'pointer',
          marker: {
            enabled: true,
            radius: 4,
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: '#393A3D',
            symbol: 'circle',
          },
          events: {
            legendItemClick() {
              return true;
            },
          },
        },
        area: {
          tooltip: {
            followTouchMove: false,
          },
        },
      },
      series: [
        {
          animation: true,
          name: 'Recebimentos',
          color: '#53B700',
          data: e[0],
        },
        {
          animation: true,
          name: 'Despesas',
          color: '#FF6A00',
          data: e[1],
        },
        {
          animation: true,
          name: 'Resultado',
          color: '#92a8f0',
          data: e[2],
          visible: false,
        },
      ],
    }
  ), [e, data, name]);

  const dataForTable = useMemo(() => {
    if (isEmpty(data)) {
      return [];
    }

    if (name === 'expenses_by_day' && !isEmpty(data.expenses)) {
      let result = [];

      result = data.expenses.map((item) => ({
        event_date: item[1],
        value: item[2],
        isTotal: false,
      }));

      const totalValue = result.reduce((acc, item) => acc + item.value, 0);

      result.push({
        event_date: 'Total',
        value: totalValue,
        isTotal: true,
      });

      return result;
    }

    if (name === 'income_by_day' && !isEmpty(data.incomes)) {
      let result = [];

      result = data.incomes.map((item) => ({
        event_date: item[1],
        value: item[2],
        isTotal: false,
      }));

      const totalValue = result.reduce((acc, item) => acc + item.value, 0);

      result.push({
        event_date: 'Total',
        value: totalValue,
        isTotal: true,
      });

      return result;
    }

    if (name === 'historical_income_expenses' && !isEmpty(data.incomes)) {
      const { length } = data.incomes;
      const result = [];
      let total_incomes = 0;
      let total_expenses = 0;

      Array.from({ length }).forEach((_, index) => {
        result.push({
          event_date: data.incomes[index][1],
          income: data.incomes[index][2],
          expense: data.expenses[index][2],
          total: data.incomes[index][2] - data.expenses[index][2],
        });

        total_incomes += data.incomes[index][2];
        total_expenses += data.expenses[index][2];
      });

      result.push({
        total_incomes,
        total_expenses,
        total_result: total_incomes - total_expenses,
      });

      return result;
    }

    return [];
  }, [data, name]);

  return (
    <>
      <Report ref={reportRef}>
        <Card>
          <PrintHeader
            title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
            description={formattedPeriod}
            formattedPeriod={formattedPeriod}
            account_ids={account_ids}
            cost_center_ids={cost_center_ids}
          />
          <CardBody className="p-0 mt-0">
            {showChart && (
              <HighchartsReact
                highcharts={Highcharts}
                options={defaultOptions}
              />
            )}
            {showTable && (
              <div className={classNames({
                'table-responsive': isMobile,
              })}
              >
                <StyledTable className="table table-hover">
                  <thead>
                    {(name === 'expenses_by_day' || name === 'income_by_day') && (
                    <tr>
                      <th>Data</th>
                      <th width="80%" className="text-right">Valor</th>
                    </tr>
                    )}
                    {name === 'historical_income_expenses' && (
                    <tr>
                      <th width="50%">Data</th>
                      {showIncome && (
                        <th className="text-right">Receitas</th>
                      )}
                      {showExpense && (
                        <th className="text-right">Despesas</th>
                      )}
                      {showResult && (
                        <th className="text-right">Resultado</th>
                      )}
                    </tr>
                    )}
                  </thead>
                  {(name === 'expenses_by_day' || name === 'income_by_day') && (
                    <>
                      <tbody>
                        {dataForTable.slice(0, -1).map((item) => (
                          <tr key={item.event_date}>
                            <td>{FORMATTERS.DATE_DDMMYYYY(item.event_date)}</td>
                            <td className="text-right">{FORMATTERS.NUMBER(item.value)}</td>
                          </tr>
                        ))}
                        {!isEmpty(dataForTable) && (
                          <tr>
                            <td>
                              <strong>Total</strong>
                            </td>
                            <td className="text-right">
                              <strong>
                                {FORMATTERS.NUMBER(dataForTable[dataForTable.length - 1].value)}
                              </strong>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </>
                  )}
                  {name === 'historical_income_expenses' && (
                    <>
                      <tbody>
                        {dataForTable.slice(0, -1).map((item) => (
                          <tr key={item.event_date}>
                            <td>{FORMATTERS.DATE_DDMMYYYY(item.event_date)}</td>
                            {showIncome && (
                              <td className="text-right">{FORMATTERS.REPORT_AMOUNT(item.income, 'INCOME')}</td>
                            )}
                            {showExpense && (
                              <td className="text-right">{FORMATTERS.REPORT_AMOUNT(item.expense, 'EXPENSE')}</td>
                            )}
                            {showResult && (
                              <td className="text-right">{FORMATTERS.REPORT_BALANCE_AMOUNT(item.total, 'RESULT')}</td>
                            )}
                          </tr>
                        ))}
                        {!isEmpty(dataForTable) && (
                          <tr>
                            <td>
                              <strong>Total</strong>
                            </td>
                            {showIncome && (
                              <td className="text-right">
                                <strong>
                                  {FORMATTERS.REPORT_AMOUNT(dataForTable[dataForTable.length - 1].total_incomes, 'INCOME')}
                                </strong>
                              </td>
                            )}
                            {showExpense && (
                              <td className="text-right">
                                <strong>
                                  {FORMATTERS.REPORT_AMOUNT(dataForTable[dataForTable.length - 1].total_expenses, 'EXPENSE')}
                                </strong>
                              </td>
                            )}
                            {showResult && (
                              <td className="text-right">
                                <strong>
                                  {FORMATTERS.REPORT_BALANCE_AMOUNT(dataForTable[dataForTable.length - 1].total_result, 'RESULT')}
                                </strong>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </>
                  )}
                </StyledTable>
              </div>
            )}
          </CardBody>
          <ReportOptionsContainer isMobile={isMobile} className="no-print mt-2 mb-3 d-flex justify-content-center">
            <StyledCheckbox
              type="checkbox"
              label="Exibir gráfico"
              checked={showChart}
              onChange={(e) => setShowChart(e.target.checked)}
              className="mr-3"
              id="reportOptionsChart"
            />
            <StyledCheckbox
              type="checkbox"
              label="Exibir tabela"
              checked={showTable}
              onChange={(e) => setShowTable(e.target.checked)}
              className="mr-3"
              id="reportOptionsTable"
            />
            {name === 'historical_income_expenses' && (
              <>
                <StyledCheckbox
                  type="checkbox"
                  label="Exibir receitas"
                  checked={showIncome}
                  onChange={(e) => setShowIncome(e.target.checked)}
                  className="mr-3"
                  id="reportOptionsIncome"
                />
                <StyledCheckbox
                  type="checkbox"
                  label="Exibir despesas"
                  checked={showExpense}
                  onChange={(e) => setShowExpense(e.target.checked)}
                  className="mr-3"
                  id="reportOptionsExpense"
                />
                <StyledCheckbox
                  type="checkbox"
                  label="Exibir resultado"
                  checked={showResult}
                  onChange={(e) => setShowResult(e.target.checked)}
                  id="reportOptionsResult"
                />
              </>
            )}
          </ReportOptionsContainer>
        </Card>
      </Report>
    </>
  );
}

LineChartReport.defaultProps = {
  reports: {},
  formattedPeriod: '',
};

LineChartReport.propTypes = {
  formattedPeriod: PropTypes.string,
  name: PropTypes.string.isRequired,
  activeCompany: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  reports: PropTypes.object,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
};

export default LineChartReport;
