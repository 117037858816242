/* eslint-disable react/no-this-in-sfc */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import FORMATTERS from 'helpers/formatters';

function PieChart({
  data,
  colorType,
  size,
  isMobile,
}) {
  const total = useMemo(() => {
    if (isEmpty(data)) {
      return null;
    }

    return data.reduce((acc, item) => acc + item[1], 0);
  }, [data]);

  const SIZES = useMemo(() => ({
    SMALL: {
      size: 220,
      innerSize: 110,
    },
    MEDIUM: {
      size: 270,
      innerSize: 150,
    },
    LARGE: {
      size: 270,
      innerSize: 150,
    },
  }), []);

  const colors = useMemo(() => ({
    EXPENSE: ['#FF6A00', '#E31C9E', '#6436AF', '#34BFFF', '#008481', '#7FC000', '#FFBB00', '#D4D7DC'],
    INCOME: ['#7FC000', '#008481', '#34BFFF', '#6436AF', '#E31C9E', '#FF6A00', '#FFBB00', '#D4D7DC'],
  }), []);

  const defaultOptions = useMemo(() => ({
    colors: colors[colorType],
    chart: {
      style: {
        fontFamily: 'Poppins',
      },
      margin: [0, 0, 0, 0],
    },
    credits: {
      enabled: false,
    },
    title: {
      enabled: true,
      marginTop: 0,
      style: {
        color: '#404040',
        fontWeight: '500',
        fontFamily: 'Poppins',
        fontSize: '1.3em',
      },
      verticalAlign: 'middle',
      text: isMobile ? FORMATTERS.NUMBER(total) : null,
    },
    tooltip: {
      backgroundColor: '#000000',
      borderRadius: 3,
      style: {
        color: '#FFFFFF',
      },
      borderWidth: 0,
      shadow: 0,
      followTouchMove: 1,
      followPointer: 1,
      formatter() {
        let content = '';
        content += `<span style="color: ${this.point.color}">`;
        content += '\u2022';
        content += '</span>';
        content += this.point.name;
        content += '<br />';
        content += '<strong style="font-weight:500;">';
        content += `${FORMATTERS.NUMBER(this.y)} - ${FORMATTERS.CHART_PERCENTAGE(this.percentage)}`;
        content += '</strong>';

        return content;
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: !1,
        cursor: 'pointer',
        dataLabels: {
          style: {
            fontSize: '12px',
            color: '#8D9096',
            textOutline: '0px',
            fontWeight: 'normal',
          },
          formatter() {
            let content = '';
            content += `<span>${FORMATTERS.MAX_X_CHARS(this.point.name, 32)}</span>`;
            content += '<br />';
            content += `${FORMATTERS.CHART_PERCENTAGE(this.percentage)}`;

            if (isMobile) {
              return null;
            }

            return content;
          },
        },
        size: SIZES[size].size,
        innerSize: SIZES[size].innerSize,
        shadow: 0,
      },

    },
  }), [colorType, colors, SIZES, size, total, isMobile]);

  const options = useMemo(() => {
    if (isEmpty(data)) {
      return defaultOptions;
    }

    return {
      ...defaultOptions,
      series: [
        {
          animation: true,
          type: 'pie',
          name: 'Despesas por tipo',
          data,
        },
      ],
    };
  }, [data, defaultOptions]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

PieChart.defaultProps = {
  size: 'MEDIUM',
  data: [],
  isMobile: false,
};

PieChart.propTypes = {
  data: PropTypes.array,
  colorType: PropTypes.oneOf(['EXPENSE', 'INCOME']).isRequired,
  size: PropTypes.oneOf(['MEDIUM', 'LARGE']),
  isMobile: PropTypes.bool,
};

export default PieChart;
