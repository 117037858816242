import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const updateUserPermissions = (user_id, permissions) => {
  return (dispatch) => {
    dispatch(request(user_id, permissions));

    service.updateUserPermissions(user_id, permissions).then(
      (response) => {
        const userPermissions = response.data;

        dispatch(success(userPermissions));
        dispatch(alertActionCreators.success('Permissões atualizadas com sucesso!'));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return { type: constants.USER_UPDATE_PERMISSIONS_REQUEST };
  }
  function success(userPermissions) {
    return {
      type: constants.USER_UPDATE_PERMISSIONS_SUCCESS,
      payload: userPermissions,
    };
  }
  function failure(message) {
    return {
      type: constants.USER_UPDATE_PERMISSIONS_FAILURE,
      payload: { message },
    };
  }
};

export default updateUserPermissions;
