/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useContextMenu } from 'react-contexify';
import Form from 'react-bootstrap/Form';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Editor } from '@tinymce/tinymce-react';
import isEmpty from 'lodash/isEmpty';
import {
  FaCopy,
  FaPlus,
  FaSave,
  FaTrash,
} from 'react-icons/fa';

import {
  FormTextField,
  FormSelectField,
  Button,
  PageHeader,
} from '_components/_core';
import { RECEIPTS_TYPE_OPTIONS } from 'helpers/constants';

import { useMediaQuery } from 'helpers';
import { TEMPLATES, ValidationSchema } from './utilities';
import VariablesList from './VariablesList/VariablesList';
import { Toolbar, ToolbarItem } from './styles';

const EDITOR_SCRIPT_PATH = `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`;
const EDITOR_TRANSLATION_PATH = `${process.env.PUBLIC_URL}/assets/langs/pt_BR.js`;

function Receipts({
  onFetchReceipt,
  onFetchAllReceipts,
  onAddReceipt,
  onUpdateReceipt,
  onDeleteReceipt,
  receipts,
  activeCompany,
}) {
  const { isMobile } = useMediaQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);

  const editorRef = useRef(null);
  const MENU_ID = 'receiptMenu';

  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });

  const selectedReceipt = useMemo(
    () => receipts.find((receipt) => String(receipt._id) === selectedReceiptId),
    [receipts, selectedReceiptId],
  );

  const handleSaveReceipt = useCallback((values) => {
    setIsLoading(true);

    const content = editorRef.current.getContent();

    const formattedValues = {
      ...values,
      content,
    };

    const selectedReceipt = receipts.find((receipt) => String(receipt._id) === selectedReceiptId);

    const errorCallback = () => {
      setIsLoading(false);
    };

    if (selectedReceipt) {
      onUpdateReceipt(selectedReceipt._id, formattedValues, () => {
        setIsLoading(false);
      }, errorCallback);
    }
  }, [
    editorRef,
    receipts,
    selectedReceiptId,
    onUpdateReceipt,
  ]);

  const handleCreateDefaultReceipt = useCallback(() => {
    setIsLoading(true);

    const errorCallback = () => {
      setIsLoading(false);
    };

    const defaultReceipt = {
      name: `Meu Recibo #${receipts.length + 1}`,
      type: 'INCOME',
      content: '<p>Digite o conteúdo aqui...</p>',
    };

    onAddReceipt(defaultReceipt, (createdReceipt) => {
      setIsLoading(false);

      setSelectedReceiptId(createdReceipt._id);
    }, errorCallback);
  }, [receipts, onAddReceipt]);

  useEffect(() => {
    setIsLoading(true);

    onFetchAllReceipts((foundReceipts) => {
      const [firstReceipt] = foundReceipts || [];

      if (firstReceipt) {
        setSelectedReceiptId(firstReceipt._id);
      }

      setIsLoading(false);
    });
  }, [onFetchAllReceipts, onFetchReceipt, activeCompany]);

  const handleDuplicate = useCallback(() => {
    setIsLoading(true);

    if (!selectedReceipt) {
      return;
    }

    const newReceipt = {
      ...selectedReceipt,
      _id: null,
    };

    onAddReceipt(newReceipt, () => {
      setIsLoading(false);
    });
  }, [selectedReceipt, onAddReceipt]);

  const handleDelete = useCallback(() => {
    if (!selectedReceipt) {
      return;
    }

    const newList = receipts.filter((receipt) => receipt._id !== selectedReceipt._id);

    onDeleteReceipt(selectedReceipt._id, () => {
      const lastReceipt = !isEmpty(newList) ? newList[newList.length - 1] : null;

      if (lastReceipt) {
        setSelectedReceiptId(lastReceipt._id);
      } else {
        setSelectedReceiptId(null);
      }

      setIsLoading(false);
    });
  }, [onDeleteReceipt, receipts, selectedReceipt]);

  const handleAddVariable = useCallback((variable) => {
    editorRef.current.execCommand('mceInsertContent', false, variable.name);
  }, []);

  const getCursorPosition = useCallback(() => {
    if (!editorRef.current) {
      return {};
    }

    const rng = editorRef.current.selection.getRng();
    const rect = rng.getClientRects()[0];

    if (rect) {
      return {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };
    }

    return { top: 0, left: 0 };
  }, []);

  const handleOpenVariables = useCallback((e) => {
    if (e.key === '@') {
      e.preventDefault();

      const position = getCursorPosition();

      show(e, {
        position: {
          x: position.left + 480,
          y: position.top + 380,
        },
      });
    }
  }, [show, getCursorPosition]);

  if (isEmpty(receipts) && isLoading) {
    return (
      <Container fluid className="content-wrapper">
        <PageHeader
          title="Modelos de Recibos"
          variant="small"
          sideContent={(
            <Button
              onClick={handleCreateDefaultReceipt}
              disabled={isLoading}
              isLoading={isLoading}
              className="btn btn-sm d-flex justify-content-center align-items-center btn-success"
              variant="success-2"
            >
              <FaPlus className="mr-2" />
              Criar novo
            </Button>
          )}
        />
        <Row className="mt-5">
          <Col lg={12}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h4 className="text-center">
                Carregando...
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (isEmpty(receipts)) {
    return (
      <Container fluid className="content-wrapper">
        <PageHeader
          title="Modelos de Recibos"
          variant="small"
          sideContent={(
            <Button
              onClick={handleCreateDefaultReceipt}
              disabled={isLoading}
              isLoading={isLoading}
              className="btn btn-sm d-flex justify-content-center align-items-center btn-success"
              variant="success-2"
            >
              <FaPlus className="mr-2" />
              Criar novo
            </Button>
          )}
        />
        <Row className="mt-5">
          <Col lg={12}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h4 className="text-center">
                Você ainda não possui nenhum modelo de recibo.
              </h4>
              <Button
                onClick={handleCreateDefaultReceipt}
                disabled={isLoading}
                isLoading={isLoading}
                className="btn btn-sm d-flex justify-content-center align-items-center btn-success mt-3"
                variant="success-2"
              >
                <FaPlus className="mr-2" />
                Criar novo
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Modelos de Recibos"
        variant="small"
        sideContent={(
          <Button
            onClick={handleCreateDefaultReceipt}
            disabled={isLoading}
            isLoading={isLoading}
            className="btn btn-sm d-flex justify-content-center align-items-center btn-success"
            variant="success-2"
          >
            <FaPlus className="mr-2" />
            Criar novo
          </Button>
        )}
      />
      <Toolbar>
        {receipts.map((receipt) => {
          const isActive = selectedReceiptId === receipt._id;

          return (
            <ToolbarItem
              key={receipt._id}
              role="button"
              onClick={() => setSelectedReceiptId(receipt._id)}
              isActive={isActive}
            >
              <span>
                {receipt.name}
              </span>
            </ToolbarItem>
          );
        })}
      </Toolbar>
      <Formik
        enableReinitialize
        initialValues={{
          name: selectedReceiptId ? (receipts.find((receipt) => String(receipt._id) === selectedReceiptId)?.name || '') : '',
          type: selectedReceiptId ? (receipts.find((receipt) => String(receipt._id) === selectedReceiptId)?.type || '') : '',
        }}
        onSubmit={handleSaveReceipt}
        validationSchema={ValidationSchema}
      >
        {({ handleSubmit }) => (
          <Row>
            <Col
              lg={8}

            >
              <div>
                <Form onSubmit={handleSubmit}>
                  <Row className="mt-3">
                    <Form.Group as={Col}>
                      <Form.Label>Nome do Modelo</Form.Label>
                      <FormTextField name="name" placeholder="Dê um nome ao modelo" />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Tipo de Transação</Form.Label>
                      <FormSelectField isSearchable={false} name="type" options={RECEIPTS_TYPE_OPTIONS} />
                    </Form.Group>
                  </Row>
                  <Row className="mt-3">
                    <Form.Group as={Col}>
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label>Conteúdo (Corpo do documento)</Form.Label> <br />
                      </div>
                      <Editor
                        tinymceScriptSrc={EDITOR_SCRIPT_PATH}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={selectedReceipt ? selectedReceipt.content : ''}
                        init={{
                          height: 700,
                          menubar: false,
                          toolbar_mode: 'wrap',
                          selector: 'textarea',
                          plugins: 'lists advlist table pagebreak searchreplace template fullscreen',
                          toolbar: 'template blocks fontsizeinput  bold italic underline alignleft aligncenter alignright alignjustify lineheight numlist bullist table | searchreplace pagebreak | fullscreen print',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          language_url: EDITOR_TRANSLATION_PATH,
                          language: 'pt_BR',
                          browser_spellcheck: false,
                          paste_data_images: false,
                          templates: TEMPLATES,
                        }}
                        onClick={() => {
                          hideAll();
                        }}
                        onKeyDown={handleOpenVariables}
                      />
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="d-flex">
                          <Button
                            variant="outline-danger"
                            className="d-flex justify-content-center align-items-center btn btn-sm mr-3"
                            onClick={handleDelete}
                            disabled={isLoading}
                          >
                            <FaTrash className="mr-2" />
                            Excluir
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="d-flex justify-content-center align-items-center btn btn-sm"
                            onClick={handleDuplicate}
                            disabled={isLoading}
                          >
                            <FaCopy className="mr-2" />
                            Duplicar
                          </Button>
                        </div>
                        <Button
                          isLoading={isLoading}
                          disabled={isLoading}
                          onClick={handleSubmit}
                          variant="success"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <FaSave className="mr-2" />
                          Salvar alterações
                        </Button>
                      </div>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={4}>
              <div style={{
                position: 'sticky',
                top: isMobile ? '182px' : '80px',
                zIndex: 980,
                marginBottom: '400px',
              }}
              >
                <h4>
                  Variáveis
                </h4>
                <div className="pr-1">
                  <VariablesList onAddVariable={handleAddVariable} />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
}

Receipts.defaultProps = {
  receipts: [],
};

Receipts.propTypes = {
  activeCompany: PropTypes.object.isRequired,
  receipts: PropTypes.array,
  onFetchReceipt: PropTypes.func.isRequired,
  onFetchAllReceipts: PropTypes.func.isRequired,
  onAddReceipt: PropTypes.func.isRequired,
  onUpdateReceipt: PropTypes.func.isRequired,
  onDeleteReceipt: PropTypes.func.isRequired,
};

export default Receipts;
