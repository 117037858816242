import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import TransferForm from './TransferForm';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
});

const mapDispatchToProps = {
  onCreateTransferTransaction: companyTransactionsActionCreators.createTransferTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferForm);
