import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { TiArrowSortedDown } from 'react-icons/ti';
import { FaPlus } from 'react-icons/fa';
import { Dropdown, Form } from 'react-bootstrap';

import {
  CustomFilterButton,
  FilterLabel,
  FilterMenu,
  FilterDescription,
  FilterValue,
  FilterOption,
  InputContainer,
} from '../styles';

const FastFilterSelect = ({
  id,
  label,
  description,
  value,
  options: allOptions,
  onChange,
  className,
  hasGroupedOptions,
  hideLabelOnSelect,
  withFilter,
  onCreateFn,
  renderDoubleDots,
  isChanged,
  menuStyle,
  triggerStyle,
}) => {
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState('');

  const inputRef = React.useRef(null);

  const options = useMemo(() => {
    if (!filterText) {
      return allOptions;
    }

    // TODO need to fix grouped options find
    return allOptions.filter((option) => {
      if (hasGroupedOptions) {
        return option.options.some(
          (subOption) => subOption.label.toLowerCase().includes(
            filterText.toLowerCase(),
          ),
        );
      }

      return option.label.toLowerCase().includes(filterText.toLowerCase());
    });
  }, [allOptions, filterText, hasGroupedOptions]);

  const handleToggle = useCallback((isOpen, ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
  }, []);

  const handleClick = useCallback((e, option) => {
    e.preventDefault();
    e.stopPropagation();

    onChange(option.value, e);

    setOpen(false);
  }, [onChange]);

  const renderOptions = useCallback(() => {
    if (hasGroupedOptions) {
      return (
        <>
          {options.map((group) => (
            <FilterOption noStyling key={group.label}>
              <FilterOption groupHeader>
                {group.label}
              </FilterOption>
              {group.options.map((option) => (
                <FilterOption
                  key={option.value}
                  onClick={(e) => handleClick(e, option)}
                >
                  {option.icon}
                  {option.label}
                </FilterOption>
              ))}
            </FilterOption>
          ))}
        </>
      );
    }

    return (
      <>
        {options.map((option) => (
          <FilterOption
            role="menuitem"
            key={option.value}
            onClick={(e) => handleClick(e, option)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleClick(e, option);
              }
            }}
            isActive={option.value === value}
          >
            {option.icon}
            {option.label}
          </FilterOption>
        ))}
      </>
    );
  }, [hasGroupedOptions, options, value, handleClick]);

  const selectedOption = useMemo(() => {
    if (hasGroupedOptions) {
      const option = options
        .map((group) => group.options)
        .flat()
        .find((option) => option.value === value);

      return option || {};
    }

    const foundOption = options.find((option) => option.value === value) || {};

    return foundOption;
  }, [options, value, hasGroupedOptions]);

  const renderLabel = useCallback(() => {
    if (hideLabelOnSelect && value) return null;

    return (
      <FilterLabel>
        {label}{renderDoubleDots ? ':' : ''}
      </FilterLabel>
    );
  }, [value, label, hideLabelOnSelect, renderDoubleDots]);

  const renderCreateOption = useCallback(() => {
    if (!filterText) return null;

    return (
      <FilterOption
        role="menuitem"
        onClick={() => onCreateFn(filterText)}
      >
        <FaPlus size="1em" className="mr-1" />
        {`Cadastrar "${filterText}"`}
      </FilterOption>
    );
  }, [filterText, onCreateFn]);

  const handleFilterText = useCallback((e) => {
    setFilterText(e.target.value);
  }, []);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [open]);

  return (
    <>
      <Dropdown
        role="menu"
        id={id}
        show={open}
        onToggle={handleToggle}
        className={className}
        tabIndex={0}
      >
        <CustomFilterButton style={triggerStyle} isChanged={isChanged} variant="link" as={Dropdown.Toggle}>
          {renderLabel()}
          <FilterValue>
            {selectedOption.label}
          </FilterValue>
          <TiArrowSortedDown size="1.1em" className="ml-1" />
        </CustomFilterButton>
        <FilterMenu style={menuStyle}>
          {description && (
            <FilterDescription>
              {description}
            </FilterDescription>
          )}
          {withFilter && (
            <InputContainer>
              <Form.Control
                ref={inputRef}
                type="text"
                value={filterText}
                onChange={handleFilterText}
                placeholder="Filtre aqui ..."
                autoFocus
              />
            </InputContainer>
          )}
          {renderOptions()}
          {onCreateFn && renderCreateOption()}
        </FilterMenu>
      </Dropdown>
    </>
  );
};

export default FastFilterSelect;

FastFilterSelect.defaultProps = {
  hasGroupedOptions: false,
  hideLabelOnSelect: false,
  withFilter: false,
  onCreateFn: null,
  renderDoubleDots: false,
  isChanged: false,
  menuStyle: {},
};

FastFilterSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasGroupedOptions: PropTypes.bool,
  id: PropTypes.string.isRequired,
  hideLabelOnSelect: PropTypes.bool,
  withFilter: PropTypes.bool,
  onCreateFn: PropTypes.func,
  renderDoubleDots: PropTypes.bool,
  isChanged: PropTypes.bool,
  menuStyle: PropTypes.object,
  triggerStyle: PropTypes.object,
};
