import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

import paginateResults from './paginateResults';

export {
  paginateResults,
};

export const DEFAULT_COLUMNS = [
  'id',
  'account_classifier',
  'account_name',
  'account_nature',
  'account_level',
  'account_type',
  'previous_amount',
  'total_debit',
  'total_credit',
  'current_amount',
];

export const TABLE_COLUMNS = [
  {
    label: 'Classificador',
    keyName: 'account_classifier',
    align: 'left',
    width: 20,
    order: 1,
    cellClass: (column, row) => {
      const { account_level } = row;

      let classes = '';

      if (account_level !== 4) {
        classes += 'text-bold ';
      }

      if (account_level === 3) {
        classes += 'spacing-top';
      }

      return classes;
    },
  },
  {
    label: 'Conta',
    keyName: 'account_name',
    formatter: FORMATTERS.UPPERCASE,
    align: 'left',
    width: 20,
    order: 2,
    cellClass: (column, row) => {
      const { account_level } = row;

      let classes = '';

      classes += `account-level-${account_level} `;

      if (account_level !== 4) {
        classes += 'text-bold ';
      }

      if (account_level === 3) {
        classes += 'spacing-top';
      }

      return classes;
    },
    specialType: SPECIAL_COLUMNS.REDIRECT,
    redirectPath: '/contabil/relatorios/razao',
    redirectKey: 'id',
    redirectOptions: {
      openNewTab: true,
      styleOnHover: true,
      useQueryParams: true,
      storedFilter: 'balance_sheet_date_filters',
    },
  },
  {
    label: 'Saldo Anterior',
    keyName: 'previous_amount',
    formatter: FORMATTERS.NUMBER_WITH_ACCOUNTING_SIGN,
    align: 'right',
    width: 10,
    order: 3,
    cellClass: (column, row) => {
      const { account_level } = row;

      let classes = '';

      if (account_level !== 4) {
        classes += 'text-bold ';
      }

      if (account_level === 3) {
        classes += 'spacing-top';
      }

      return classes;
    },
  },
  {
    label: 'Débito',
    keyName: 'total_debit',
    align: 'right',
    formatter: FORMATTERS.BALANCE_SHEET_DEBIT_CREDIT,
    width: 10,
    sumOnFooter: true,
    order: 4,
    cellClass: (column, row) => {
      const { account_level } = row;

      let classes = '';

      if (account_level !== 4) {
        classes += 'text-bold ';
      }

      if (account_level === 3) {
        classes += 'spacing-top';
      }

      return classes;
    },
  },
  {
    label: 'Crédito',
    keyName: 'total_credit',
    align: 'right',
    formatter: FORMATTERS.BALANCE_SHEET_DEBIT_CREDIT,
    sumOnFooter: true,
    width: 10,
    order: 5,
    cellClass: (column, row) => {
      const { account_level } = row;

      let classes = '';

      if (account_level !== 4) {
        classes += 'text-bold ';
      }

      if (account_level === 3) {
        classes += 'spacing-top';
      }

      return classes;
    },
  },
  {
    label: 'Saldo Atual',
    keyName: 'current_amount',
    align: 'right',
    formatter: FORMATTERS.NUMBER_WITH_ACCOUNTING_SIGN,
    width: 15,
    order: 6,
    cellClass: (column, row) => {
      const { account_level } = row;

      let classes = '';

      if (account_level !== 4) {
        classes += 'text-bold ';
      }

      if (account_level === 3) {
        classes += 'spacing-top';
      }

      return classes;
    },
  },
];
