import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { DateTime } from 'luxon';

import { companyTransactionsConstants, companyConstants } from '../_constants';

const INITIAL_STATE = {
  selectedDate: DateTime.now(),
  accounts: [],
  transactions: [],
  backup_transactions: [],
  categories: [],
  recipients: [],
  paginatedRecipients: [],
  tags: [],
  isLoading: false,
  total: 0,
  recipientsTotal: 0,
  cashflow: {},
  reports: {},
  balance: {},
  forecast_balance: 0,
  financialData: {},
  selected_account_id: null,
  selected_account_ids: [],
  isAccountsLoaded: false,
  isLoadingBalance: false,
  isLoadingRecurringTransactions: false,
  bankStatementTransactions: [],
  bankStatementTransactionsTotal: 0,
  bankStatementSummary: {},
  hasLoadedTransactionsPageData: false,
  isEditing: false,
  isCreating: false,
  isUploadingContacts: false,
  sorting: {},
  isSearching: false,
  searchResults: {
    totals: {
      total: 0,
      transactions: 0,
      categories: 0,
      transactionsWithTags: 0,
      contacts: 0,
      costCenters: 0,
    },
    transactions: [],
    transactionsWithTags: [],
    categories: [],
    contacts: [],
    costCenters: [],
  },
  consumedStorage: {},
  monthScheduleTransactions: [],
  expiredTransactions: [],
  recentlyDuplicatedTransactionsIds: [],
  isLoadingReport: false,
};

export function companyTransactions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case companyConstants.COMPANY_SET_ACTIVE_REQUEST: {
      return {
        ...state,
        accounts: [],
        transactions: [],
        backup_transactions: [],
        categories: [],
        recipients: [],
        cashflow: {},
        reports: {},
        balance: 0,
        forecast_balance: 0,
        financialData: {},
        selected_account_id: null,
        selected_account_ids: [],
      };
    }

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTIONS_SUCCESS: {
      const transactions = action.payload;

      return {
        ...state,
        isLoading: false,
        transactions,
      };
    }
    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        entries: [],
      };
    }

    case companyTransactionsConstants.FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyTransactionsConstants.FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_SUCCESS: {
      const expiredTransactions = action.payload;

      return {
        ...state,
        isLoading: false,
        expiredTransactions,
      };
    }
    case companyTransactionsConstants.FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_BANK_ACCOUNTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyTransactionsConstants.FETCH_ALL_BANK_ACCOUNTS_SUCCESS: {
      const accounts = action.payload;

      const mainAccount = accounts.find((account) => account.is_principal);

      const user = JSON.parse(localStorage.getItem('user'));
      const { preferences } = user || {};
      const { show_all_bank_accounts = false } = preferences || {};

      const selectedIds = show_all_bank_accounts ? accounts.map(
        (account) => account.id,
      ) : [mainAccount ? mainAccount.id : accounts[0].id];

      return {
        ...state,
        isLoading: false,
        accounts,
        accountsForSelect: accounts.map((account) => ({
          label: account.description,
          value: account.id.toString(),
          isDisabled: false,
          isMainAccount: account.is_principal,
        })),
        selected_account_id: mainAccount ? mainAccount.id : accounts[0].id,
        selected_account_ids: selectedIds,
      };
    }
    case companyTransactionsConstants.FETCH_ALL_BANK_ACCOUNTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        accounts: [],
      };
    }

    case companyTransactionsConstants.SORT_TRANSACTIONS: {
      const { field, order } = action.payload;

      const fieldSorter = (transaction) => {
        const value = get(transaction, field);

        if (value && typeof value === 'string') {
          return value.toLowerCase();
        }

        return value;
      };

      const transactions = orderBy(state.transactions, [fieldSorter, 'created_at'], [order, order]);

      return {
        ...state,
        transactions: [
          ...transactions,
        ],
        sorting: {
          ...state.sorting,
          field,
          order,
        },
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_REQUEST: {
      const { id, values } = action.payload;

      // add a temporary transaction to the list
      const transactions = [
        {
          ...values,
          id,
        },
        ...state.transactions,
      ];

      return {
        ...state,
        isLoading: false,
        isCreating: true,
        transactions: [
          ...transactions,
        ],
      };
    }
    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_SUCCESS: {
      const { tempId, data } = action.payload;

      // replace the temporary transaction with the real one
      const transactions = state.transactions.map((transaction) => {
        if (transaction.id === tempId) {
          return data;
        }

        return transaction;
      });

      return {
        ...state,
        isLoading: false,
        isCreating: false,
        transactions: [
          ...transactions,
        ],
      };
    }
    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isCreating: false,
      };
    }

    case companyTransactionsConstants.UPDATE_COMPANY_TRANSACTION_REQUEST: {
      const { id, values, isSameMonth } = action.payload;

      // Update the transaction
      let transactions = state.transactions.map((transaction) => {
        if (transaction.id === id) {
          const correctCategory = state.categories.find(
            (category) => category.id === values.category_id,
          ) || {};
          const correctRecipient = state.recipients.find(
            (recipient) => recipient.id === values.recipient_id,
          ) || {};

          return {
            ...transaction,
            ...values,
            category: {
              id: correctCategory.id,
              description: correctCategory.description,
            },
            recipient: {
              id: correctRecipient.id,
              name: correctRecipient.name,
            },
          };
        }

        return transaction;
      });

      // If the transaction is not in the current month, remove it from the list
      if (!isSameMonth) {
        transactions = state.transactions.filter((transaction) => transaction.id !== id);
      }

      const bankStatementTransactions = state.bankStatementTransactions.map((transaction) => {
        if (transaction.id === id) {
          const correctCategory = state.categories.find(
            (category) => category.id === values.category_id,
          ) || {};
          const correctRecipient = state.recipients.find(
            (recipient) => recipient.id === values.recipient_id,
          ) || {};

          return {
            ...transaction,
            ...values,
            category: {
              id: correctCategory.id,
              description: correctCategory.description,
            },
            recipient: {
              id: correctRecipient.id,
              name: correctRecipient.name,
            },
          };
        }

        return transaction;
      });

      return {
        ...state,
        isEditing: true,
        transactions: [
          ...transactions,
        ],
        bankStatementTransactions: [
          ...bankStatementTransactions,
        ],
      };
    }
    case companyTransactionsConstants.UPDATE_COMPANY_TRANSACTION_SUCCESS: {
      const {
        transactions: updatedTransactions,
        selectedDate,
        originalValues,
      } = action.payload;

      const updatedTransactionsIds = updatedTransactions.map((transaction) => transaction.id);
      const existingTransactionsIds = state.transactions.map((transaction) => transaction.id);

      const newlyCreatedTransactions = updatedTransactions.filter(
        (transaction) => !existingTransactionsIds.includes(transaction.id),
      );

      let copyTransactions = [...state.transactions];

      copyTransactions = copyTransactions.map((transaction) => {
        if (updatedTransactionsIds.includes(transaction.id)) {
          const updatedTransaction = updatedTransactions.find(
            (updatedTransaction) => updatedTransaction.id === transaction.id,
          );

          return updatedTransaction;
        }

        return transaction;
      });

      let newState = [];

      if (originalValues
        && originalValues.type === 'TRANSFER'
        && !isEmpty(state.selected_account_ids)
        && state.selected_account_ids.length === 1) {
        newState = [
          ...copyTransactions,
        ];
      } else {
        newState = [
          ...newlyCreatedTransactions,
          ...copyTransactions,
        ];
      }

      if (selectedDate) {
        newState = newState.filter((transaction) => {
          const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });

          return transactionDate.month === selectedDate.month
          && transactionDate.year === selectedDate.year;
        });
      }

      const bankStatementTransactions = state.bankStatementTransactions.map((transaction) => {
        if (updatedTransactionsIds.includes(transaction.id)) {
          const updatedTransaction = updatedTransactions.find(
            (updatedTransaction) => updatedTransaction.id === transaction.id,
          );

          return updatedTransaction;
        }

        return transaction;
      });

      const expiredTransactions = state.expiredTransactions.map((transaction) => {
        if (updatedTransactionsIds.includes(transaction.id)) {
          const updatedTransaction = updatedTransactions.find(
            (updatedTransaction) => updatedTransaction.id === transaction.id,
          );

          return updatedTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        isEditing: false,
        isLoading: false,
        transactions: newState,
        bankStatementTransactions: [
          ...bankStatementTransactions,
        ],
        expiredTransactions: [
          ...expiredTransactions,
        ],
      };
    }
    case companyTransactionsConstants.UPDATE_COMPANY_TRANSACTION_FAILURE: {
      return {
        ...state,
        isEditing: false,
        isLoading: false,
      };
    }

    // CATEGORIES

    case companyTransactionsConstants.FETCH_ALL_CATEGORIES_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_CATEGORIES_SUCCESS: {
      const categories = action.payload;

      return {
        ...state,
        categories,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_CATEGORIES_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_CATEGORY_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_CATEGORY_SUCCESS: {
      const { data: category } = action.payload;

      return {
        ...state,
        categories: [
          ...state.categories,
          category,
        ],
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_CATEGORY_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_CATEGORY_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_CATEGORY_SUCCESS: {
      const { data: category } = action.payload;

      const categories = state.categories.map((c) => {
        if (c.id === category.id) {
          return category;
        }

        return c;
      });

      return {
        ...state,
        categories: [
          ...categories,
        ],
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_CATEGORY_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_CATEGORY_REQUEST: {
      const { id } = action.payload;

      const deletedCategory = state.categories.find(
        (category) => category.id === id,
      );
      const categories = state.categories.filter((category) => category.id !== id);

      return {
        ...state,
        categories,
        deletedCategory,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_CATEGORY_SUCCESS: {
      return {
        ...state,
        deletedCategory: null,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_CATEGORY_FAILURE: {
      const { deletedCategory } = state;

      return {
        ...state,
        categories: [
          ...state.categories,
          deletedCategory,
        ],
        deletedCategory: null,
      };
    }

    // COST_CENTER

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_SUCCESS: {
      const costsCenter = action.payload;

      return {
        ...state,
        isLoading: false,
        costsCenter,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_COST_CENTER_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_COST_CENTER_SUCCESS: {
      const { data: costCenter } = action.payload;

      const newCostCenters = [
        ...state.costsCenter,
        costCenter,
      ];

      return {
        ...state,
        isLoading: false,
        costsCenter: sortBy(newCostCenters, 'description'),
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_COST_CENTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_COST_CENTER_REQUEST: {
      const { id, values } = action.payload;

      const costsCenter = state.costsCenter.map((costCenter) => {
        if (costCenter.id === id) {
          return {
            ...costCenter,
            ...values,
          };
        }

        return costCenter;
      });

      return {
        ...state,
        costsCenter: [
          ...costsCenter,
        ],
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_COST_CENTER_SUCCESS: {
      const { data: costCenter } = action.payload;

      const costsCenter = state.costsCenter.map((c) => {
        if (c.id === costCenter.id) {
          return costCenter;
        }

        return c;
      });

      return {
        ...state,
        costsCenter: [
          ...costsCenter,
        ],
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_COST_CENTER_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_COST_CENTER_REQUEST: {
      const { id } = action.payload;

      const deletedConstCenter = state.costsCenter.find(
        (costCenter) => costCenter.id === id,
      );
      const costsCenter = state.costsCenter.filter((costCenter) => costCenter.id !== id);

      return {
        ...state,
        costsCenter,
        deletedConstCenter,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_COST_CENTER_SUCCESS: {
      return {
        ...state,
        deletedCategory: null,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_COST_CENTER_FAILURE: {
      const { deletedConstCenter } = state;

      return {
        ...state,
        costsCenter: [
          ...state.costsCenter,
          deletedConstCenter,
        ],
        deletedConstCenter: null,
      };
    }

    // TAGS

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTION_TAGS_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTION_TAGS_SUCCESS: {
      const tags = action.payload;

      return {
        ...state,
        isLoading: false,
        tags,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_COMPANY_TRANSACTION_TAGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_TAGS_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_TAGS_SUCCESS: {
      const { data: tag } = action.payload;

      const newTags = [
        ...state.tags,
        tag,
      ];

      return {
        ...state,
        isLoading: false,
        tags: sortBy(newTags, 'description'),
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_TAGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_TAGS_REQUEST: {
      const { id, values } = action.payload;

      const tags = state.tags.map((tag) => {
        if (tag.id === id) {
          return {
            ...tag,
            ...values,
          };
        }

        return tag;
      });

      return {
        ...state,
        tags: [
          ...tags,
        ],
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_TAGS_SUCCESS: {
      const { data: tag } = action.payload;

      const tags = state.tags.map((c) => {
        if (c.id === tag.id) {
          return tag;
        }

        return c;
      });

      return {
        ...state,
        tags: [
          ...tags,
        ],
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_TAGS_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_TAGS_REQUEST: {
      const { id } = action.payload;

      const deletedTag = state.tags.find(
        (tag) => tag.id === id,
      );
      const tags = state.tags.filter((tag) => tag.id !== id);

      return {
        ...state,
        tags,
        deletedTag,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_TAGS_SUCCESS: {
      return {
        ...state,
        deletedTag: null,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_TAGS_FAILURE: {
      const { deletedTag } = state;

      return {
        ...state,
        tags: [
          ...state.tags,
          deletedTag,
        ],
        deletedTag: null,
      };
    }

    // RECIPIENTS

    case companyTransactionsConstants.FETCH_ALL_RECIPIENTS_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_RECIPIENTS_SUCCESS: {
      const recipients = action.payload;

      return {
        ...state,
        recipients,
      };
    }

    case companyTransactionsConstants.FETCH_ALL_RECIPIENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    // case companyTransactionsConstants.FETCH_ALL_RECIPIENTS_SIMPLE_REQUEST: {
    //   return {
    //     ...state,
    //   };
    // }

    // case companyTransactionsConstants.FETCH_ALL_RECIPIENTS_SIMPLE_SUCCESS: {
    //   const recipients = action.payload;

    //   return {
    //     ...state,
    //     simpleRecipients: recipients,
    //   };
    // }

    // case companyTransactionsConstants.FETCH_ALL_RECIPIENTS_SIMPLE_FAILURE: {
    //   return {
    //     ...state,
    //   };
    // }

    case companyTransactionsConstants.FETCH_PAGINATED_RECIPIENTS_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_PAGINATED_RECIPIENTS_SUCCESS: {
      const paginatedRecipients = action.payload.data;
      const recipientsTotal = action.payload.total;

      return {
        ...state,
        paginatedRecipients,
        recipientsTotal,
      };
    }

    case companyTransactionsConstants.FETCH_PAGINATED_RECIPIENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_RECIPIENT_REQUEST: {
      const { tempId, values } = action.payload;

      // add a temporary category to the list
      const recipients = [
        {
          id: tempId,
          ...values,
        },
        ...state.recipients,
      ];

      return {
        ...state,
        recipients: [
          ...recipients,
        ],
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_RECIPIENT_SUCCESS: {
      const { tempId, data } = action.payload;

      // replace the temporary category with the real one
      const recipients = state.recipients.map((category) => {
        if (category.id === tempId) {
          return data;
        }

        return category;
      });

      return {
        ...state,
        recipients: [
          ...recipients,
        ],
      };
    }

    case companyTransactionsConstants.CREATE_COMPANY_TRANSACTION_RECIPIENT_FAILURE: {
      const { tempId } = action.payload;

      // remove the temporary category from the list
      const recipients = state.recipients.filter((category) => {
        if (category.id === tempId) {
          return false;
        }

        return true;
      });

      return {
        ...state,
        recipients: [
          ...recipients,
        ],
      };
    }

    case companyTransactionsConstants.TOGGLE_COMPANY_TRANSACTION_PAID_REQUEST: {
      const { id, paid } = action.payload;

      const transactions = state.transactions.map((transaction) => {
        if (transaction.id === id) {
          return {
            ...transaction,
            paid,
          };
        }

        return transaction;
      });

      return {
        ...state,
        transactions,
      };
    }
    case companyTransactionsConstants.TOGGLE_COMPANY_TRANSACTION_PAID_SUCCESS: {
      const updatedTransactions = action.payload;
      const updatedTransactionsIds = updatedTransactions.map((t) => t.id);

      const transactions = state.transactions.map((transaction) => {
        if (updatedTransactionsIds.includes(transaction.id)) {
          const foundTransaction = updatedTransactions.find((t) => t.id === transaction.id);

          return foundTransaction;
        }

        return transaction;
      });

      const expiredTransactions = state.expiredTransactions.map((transaction) => {
        if (updatedTransactionsIds.includes(transaction.id)) {
          const foundTransaction = updatedTransactions.find((t) => t.id === transaction.id);

          return foundTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        transactions,
        expiredTransactions,
      };
    }
    case companyTransactionsConstants.TOGGLE_COMPANY_TRANSACTION_PAID_FAILURE: {
      const { id, paid } = action.payload;

      const transactions = state.transactions.map((transaction) => {
        if (transaction.id === id) {
          return {
            ...transaction,
            paid: !paid,
          };
        }

        return transaction;
      });

      return {
        ...state,
        transactions,
      };
    }

    case companyTransactionsConstants.CHANGE_TRANSACTION_RECIPIENT_REQUEST: {
      const { transaction, recipient_id } = action.payload;

      const recipient = state.recipients.find((r) => r.id === recipient_id);

      const transactions = state.transactions.map((t) => {
        if (t.id === transaction.id) {
          return {
            ...t,
            recipient,
            recipient_id,
          };
        }

        return t;
      });

      return {
        ...state,
        transactions,
      };
    }

    case companyTransactionsConstants.CHANGE_TRANSACTION_RECIPIENT_SUCCESS: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.CHANGE_TRANSACTION_RECIPIENT_FAILURE: {
      const { transaction, recipient_id } = action.payload;

      const recipient = state.recipients.find((r) => r.id === recipient_id);

      const transactions = state.transactions.map((t) => {
        if (t.id === transaction.id) {
          return {
            ...t,
            recipient,
            recipient_id,
          };
        }

        return t;
      });

      return {
        ...state,
        transactions,
      };
    }

    case companyTransactionsConstants.CHANGE_TRANSACTION_CATEGORY_REQUEST: {
      const { transaction, category_id } = action.payload;

      const category = state.categories.find((c) => c.id === category_id);

      const transactions = state.transactions.map((t) => {
        if (t.id === transaction.id) {
          return {
            ...t,
            category,
            category_id,
          };
        }

        return t;
      });

      return {
        ...state,
        transactions,
      };
    }

    case companyTransactionsConstants.DELETE_COMPANY_TRANSACTION_REQUEST: {
      const { id } = action.payload;

      // backup the transaction
      const transaction = state.transactions.find((transaction) => {
        if (transaction.id === id) {
          return true;
        }

        return false;
      });

      // remove the transaction from the list
      const transactions = state.transactions.filter((transaction) => {
        if (transaction.id === id) {
          return false;
        }

        return true;
      });

      return {
        ...state,
        transactions,
        deletedTransaction: transaction,
      };
    }

    case companyTransactionsConstants.DELETE_COMPANY_TRANSACTION_SUCCESS: {
      const ids = action.payload;

      // remove the transaction from the list
      const transactions = state.transactions.filter((transaction) => {
        if (ids.includes(transaction.id)) {
          return false;
        }

        return true;
      });

      const bankStatementTransactions = state.bankStatementTransactions.filter(
        (transaction) => {
          if (ids.includes(transaction.id)) {
            return false;
          }

          return true;
        },
      );

      const expiredTransactions = state.expiredTransactions.filter(
        (transaction) => {
          if (ids.includes(transaction.id)) {
            return false;
          }

          return true;
        },
      );

      return {
        ...state,
        deletedTransaction: null,
        transactions: [
          ...transactions,
        ],
        bankStatementTransactions: [
          ...bankStatementTransactions,
        ],
        expiredTransactions: [
          ...expiredTransactions,
        ],
      };
    }

    case companyTransactionsConstants.DELETE_COMPANY_TRANSACTION_FAILURE: {
      // restore the transaction
      const transactions = [
        state.deletedTransaction,
        ...state.transactions,
      ];

      return {
        ...state,
        transactions,
        deletedTransaction: null,
      };
    }

    case companyTransactionsConstants.DELETE_MULTIPLE_COMPANY_TRANSACTION_REQUEST: {
      const { ids } = action.payload;

      const deletedTransactions = state.transactions.filter(
        (transaction) => ids.includes(transaction.id),
      );

      const transactions = state.transactions.filter(
        (transaction) => !ids.includes(transaction.id),
      );

      return {
        ...state,
        isLoading: false,
        transactions: [
          ...transactions,
        ],
        deletedTransactions,
      };
    }
    case companyTransactionsConstants.DELETE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS: {
      const { ids } = action.payload;

      const transactions = state.bankStatementTransactions.filter(
        (transaction) => !ids.includes(transaction.id),
      );

      return {
        isLoading: false,
        ...state,
        bankStatementTransactions: [
          ...transactions,
        ],
      };
    }
    case companyTransactionsConstants.DELETE_MULTIPLE_COMPANY_TRANSACTION_FAILURE: {
      const transactions = [
        ...state.deletedTransactions,
        ...state.transactions,
      ];

      return {
        ...state,
        isLoading: false,
        transactions,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_CASHFLOW_REQUEST: {
      return {
        ...state,
        isLoadingBalance: true,
      };
    }
    case companyTransactionsConstants.FETCH_COMPANY_CASHFLOW_SUCCESS: {
      const cashflow = action.payload;

      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          ...cashflow,
        },
        isLoadingBalance: false,
      };
    }
    case companyTransactionsConstants.FETCH_COMPANY_CASHFLOW_FAILURE: {
      return {
        ...state,
        isLoadingBalance: false,
      };
    }

    case companyTransactionsConstants.GENERATE_TRANSACTION_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoadingReport: true,
      };
    }

    case companyTransactionsConstants.GENERATE_TRANSACTION_REPORT_SUCCESS: {
      const { report_name } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingReport: false,
        reports: {
          ...state.reports,
          [report_name]: action.payload,
        },
      };
    }

    case companyTransactionsConstants.GENERATE_TRANSACTION_REPORT_FAILURE: {
      return {
        ...state,
        isLoadingReport: false,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.UPLOAD_ZEROPAPER_SPREADSHEET_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case companyTransactionsConstants.UPLOAD_ZEROPAPER_SPREADSHEET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.UPLOAD_ZEROPAPER_SPREADSHEET_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_ACCOUNT_BALANCE_REQUEST: {
      return {
        ...state,
        isLoadingBalance: true,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_ACCOUNT_BALANCE_SUCCESS: {
      return {
        ...state,
        balance: {
          ...state.balance,
          ...action.payload,
        },
        isLoadingBalance: false,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_ACCOUNT_BALANCE_FAILURE: {
      return {
        ...state,
        isLoadingBalance: false,
      };
    }

    case companyTransactionsConstants.GET_FINANCIAL_DATA_FOR_DELETION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case companyTransactionsConstants.GET_FINANCIAL_DATA_FOR_DELETION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        financialData: action.payload,
      };
    }

    case companyTransactionsConstants.GET_FINANCIAL_DATA_FOR_DELETION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.CREATE_TRANSACTION_ACCOUNT_REQUEST: {
      return {
        ...state,
      };
    }
    case companyTransactionsConstants.CREATE_TRANSACTION_ACCOUNT_SUCCESS: {
      const newAccount = action.payload;

      return {
        ...state,
        accounts: [
          ...state.accounts,
          newAccount,
        ],
      };
    }
    case companyTransactionsConstants.CREATE_TRANSACTION_ACCOUNT_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.UPDATE_TRANSACTION_ACCOUNT_REQUEST: {
      const { id, values } = action.payload;

      const accounts = state.accounts.map((account) => {
        if (account.id === id) {
          return {
            ...account,
            ...values,
          };
        }

        return account;
      });

      return {
        ...state,
        transactions: [
          ...accounts,
        ],
      };
    }
    case companyTransactionsConstants.UPDATE_TRANSACTION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyTransactionsConstants.UPDATE_TRANSACTION_ACCOUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.SET_SELECTED_ACCOUNT_REQUEST: {
      const { account_id } = action.payload;

      return {
        ...state,
        selected_account_id: account_id,
      };
    }

    case companyTransactionsConstants.SET_SELECTED_ACCOUNT_IDS_REQUEST: {
      const { account_ids } = action.payload;

      return {
        ...state,
        selected_account_ids: account_ids,
      };
    }

    case companyTransactionsConstants.FETCH_SEMESTER_OVERVIEW_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case companyTransactionsConstants.FETCH_SEMESTER_OVERVIEW_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        isLoading: false,
        semesterOverview: data,
      };
    }

    case companyTransactionsConstants.FETCH_SEMESTER_OVERVIEW_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.SET_FAVORITE_BANK_ACCOUNT_REQUEST: {
      const { id } = action.payload;

      const accounts = state.accounts.map((account) => {
        if (account.id === id) {
          return {
            ...account,
            is_principal: true,
          };
        }

        return {
          ...account,
          is_principal: false,
        };
      });

      return {
        ...state,
        accounts: [
          ...accounts,
        ],
      };
    }

    case companyTransactionsConstants.SET_FAVORITE_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.SET_FAVORITE_BANK_ACCOUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.DUPLICATE_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.DUPLICATE_TRANSACTIONS_SUCCESS: {
      const {
        transaction_id: original_transaction_id,
        transaction,
        type,
      } = action.payload;

      if (type === 'next_month') {
        return {
          ...state,
          isLoading: false,
        };
      }

      const index = state.transactions.findIndex(
        (transaction) => transaction.id === original_transaction_id,
      );

      const transactions = [
        ...state.transactions.slice(0, index + 1),
        transaction,
        ...state.transactions.slice(index + 1),
      ];

      return {
        ...state,
        isLoading: false,
        transactions,
        recentlyDuplicatedTransactionsIds: [
          transaction.id,
        ],
      };
    }

    case companyTransactionsConstants.DUPLICATE_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS: {
      const {
        params,
        transactions,
      } = action.payload;

      const { type, ids } = params;

      if (type === 'next_month') {
        return {
          ...state,
        };
      }

      const originalIdToNewTransaction = ids.reduce((acc, id, index) => {
        acc[id] = transactions[index];

        return acc;
      }, {});

      const newTransactions = [];

      state.transactions.forEach((transaction) => {
        newTransactions.push(transaction);

        if (ids.includes(transaction.id) && originalIdToNewTransaction[transaction.id]) {
          newTransactions.push(originalIdToNewTransaction[transaction.id]);
        }
      });

      return {
        ...state,
        transactions: newTransactions,
        recentlyDuplicatedTransactionsIds: transactions.map((transaction) => transaction.id),
      };
    }

    case companyTransactionsConstants.DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.CLEAR_DUPLICATED_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        recentlyDuplicatedTransactionsIds: [],
      };
    }

    case companyTransactionsConstants.CREATE_TRANSFER_TRANSACTION_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.CREATE_TRANSFER_TRANSACTION_SUCCESS: {
      const { selected_account_ids } = state;

      const { transfer } = action.payload;
      const { sent_transaction, received_transaction } = transfer;

      let transactions = [
        ...state.transactions,
      ];

      if (!isEmpty(selected_account_ids)
      && selected_account_ids.includes(sent_transaction.account_id)) {
        transactions = [
          ...transactions,
          sent_transaction,
        ];
      }

      if (!isEmpty(selected_account_ids)
      && selected_account_ids.includes(received_transaction.account_id)) {
        transactions = [
          ...transactions,
          received_transaction,
        ];
      }

      return {
        ...state,
        isLoading: false,
        transactions,
      };
    }

    case companyTransactionsConstants.CREATE_TRANSFER_TRANSACTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.SET_SELECTED_DATE_REQUEST: {
      const { date } = action.payload;

      return {
        ...state,
        selectedDate: date,
        transactions: [],
      };
    }

    case 'TOGGLE_TRANSFER_FORM': {
      return {
        ...state,
        isTransferFormOpen: !state.isTransferFormOpen,
      };
    }

    case companyTransactionsConstants.DELETE_CONTACT_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.DELETE_CONTACT_SUCCESS: {
      const { id } = action.payload;

      const recipients = state.recipients.filter((contact) => contact.id !== id);

      return {
        ...state,
        recipients,
      };
    }

    case companyTransactionsConstants.UPLOAD_CONTACTS_FROM_INVOICES_REQUEST: {
      return {
        ...state,
        isUploadingContacts: true,
      };
    }

    case companyTransactionsConstants.UPLOAD_CONTACTS_FROM_INVOICES_SUCCESS: {
      return {
        ...state,
        isUploadingContacts: false,
      };
    }

    case companyTransactionsConstants.UPLOAD_CONTACTS_FROM_INVOICES_FAILURE: {
      return {
        ...state,
        isUploadingContacts: false,
      };
    }

    case companyTransactionsConstants.DELETE_MULTIPLE_RECIPIENTS_REQUEST: {
      const { ids } = action.payload;

      const deletedRecipients = state.paginatedRecipients.filter(
        (recipient) => ids.includes(recipient.id),
      );

      const recipients = state.paginatedRecipients.filter(
        (recipient) => !ids.includes(recipient.id),
      );

      return {
        ...state,
        isLoading: false,
        paginatedRecipients: [
          ...recipients,
        ],
        deletedRecipients,
      };
    }
    case companyTransactionsConstants.DELETE_MULTIPLE_RECIPIENTS_SUCCESS: {
      const { ids } = action.payload;

      const newPaginatedRecipients = state.paginatedRecipients.filter(
        (recipient) => !ids.includes(recipient.id),
      );

      const newRecipients = state.recipients.filter(
        (recipient) => !ids.includes(recipient.id),
      );

      return {
        ...state,
        paginatedRecipients: [
          ...newPaginatedRecipients,
        ],
        recipients: [
          ...newRecipients,
        ],
        isLoading: false,
      };
    }
    case companyTransactionsConstants.DELETE_MULTIPLE_RECIPIENTS_FAILURE: {
      const paginatedRecipients = [
        ...state.deletedRecipients,
        ...state.recipients,
      ];

      return {
        ...state,
        isLoading: false,
        paginatedRecipients,
      };
    }

    case companyTransactionsConstants.DELETE_COMPANY_FINANCIAL_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case companyTransactionsConstants.DELETE_COMPANY_FINANCIAL_DATA_SUCCESS: {
      return {
        ...state,
        categories: [],
        recipients: [],
        accounts: [],
        transactions: [],
        cashflow: {},
        reports: {},
        selectedDate: DateTime.now(),
        balance: {},
        financialData: {},
        selected_account_ids: [],
        isLoading: false,
      };
    }

    case companyTransactionsConstants.DELETE_COMPANY_FINANCIAL_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.TOGGLE_MULTIPLE_TRANSACTIONS_PAID_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.TOGGLE_MULTIPLE_TRANSACTIONS_PAID_SUCCESS: {
      const { transactions: updatedTransactions = [] } = action.payload;

      const updatedTransactionsIds = updatedTransactions.map(
        (transaction) => transaction.id,
      );

      const newTransactions = state.transactions.map((transaction) => {
        if (updatedTransactionsIds.includes(transaction.id)) {
          const foundTransaction = updatedTransactions.find((t) => t.id === transaction.id);

          return foundTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        transactions: [
          ...newTransactions,
        ],
      };
    }

    case companyTransactionsConstants.FETCH_BANK_STATEMENT_TRANSACTIONS_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_STATEMENT_TRANSACTIONS_SUCCESS: {
      const { data, total } = action.payload;

      return {
        ...state,
        bankStatementTransactions: data,
        bankStatementTransactionsTotal: total,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_STATEMENT_TRANSACTIONS_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.UPDATE_MULTIPLE_CONTACTS_SUCCESS: {
      const { contacts } = action.payload;

      const updatedRecipients = state.recipients.map((recipient) => {
        const updatedRecipient = contacts.find((c) => c.id === recipient.id);

        if (updatedRecipient) {
          return updatedRecipient;
        }

        return recipient;
      });

      return {
        ...state,
        recipients: updatedRecipients,
      };
    }

    case companyTransactionsConstants.UPDATE_MULTIPLE_TRANSACTIONS_SUCCESS: {
      const { transactions } = action.payload;

      const updatedBankTransactions = state.bankStatementTransactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          const newTransaction = {};

          Object.assign(newTransaction, updatedTransaction);

          return newTransaction;
        }

        return transaction;
      });

      const updatedTransactions = state.transactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          const newTransaction = {};

          Object.assign(newTransaction, updatedTransaction);

          return newTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        bankStatementTransactions: updatedBankTransactions,
        transactions: [
          ...updatedTransactions,
        ],
      };
    }

    case companyTransactionsConstants.UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_SUCCESS: {
      const { transactions } = action.payload;

      const updatedTransactions = state.bankStatementTransactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          return updatedTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        bankStatementTransactions: updatedTransactions,
      };
    }

    case companyTransactionsConstants.UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_SUCCESS: {
      const { transactions } = action.payload;

      const updatedTransactions = state.bankStatementTransactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          return updatedTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        bankStatementTransactions: updatedTransactions,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_STATEMENT_SUMMARY_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_STATEMENT_SUMMARY_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        bankStatementSummary: data,
      };
    }

    case companyTransactionsConstants.FETCH_BANK_STATEMENT_SUMMARY_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.UPLOAD_BANK_STATEMENT_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case companyTransactionsConstants.UPLOAD_BANK_STATEMENT_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.UPLOAD_BANK_STATEMENT_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyTransactionsConstants.CREATE_RECURRING_TRANSACTION_REQUEST: {
      return {
        ...state,
        isLoadingRecurringTransactions: true,
      };
    }

    case companyTransactionsConstants.CREATE_RECURRING_TRANSACTION_SUCCESS: {
      const {
        transactions: updated_transactions,
        params,
      } = action.payload;
      const { selectedDate } = params;

      const updatedTransactionsIds = updated_transactions.map((transaction) => transaction.id);

      const other_transactions = state.transactions.filter(
        (transaction) => !updatedTransactionsIds.includes(transaction.id),
      );

      const sameMonthTransactions = updated_transactions.filter((transaction) => {
        const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });

        return transactionDate.month === selectedDate.month
          && transactionDate.year === selectedDate.year;
      });

      const newTransactions = [
        ...other_transactions,
        ...sameMonthTransactions,
      ];

      const orderedTransactions = [];

      state.transactions.forEach((transaction) => {
        const relatedTransaction = newTransactions.find((t) => t.id === transaction.id);

        if (relatedTransaction) {
          orderedTransactions.push(relatedTransaction);
        }
      });

      const remainingTransactions = newTransactions.filter((transaction) => {
        const ids = orderedTransactions.map((t) => t.id);

        return !ids.includes(transaction.id);
      });

      return {
        ...state,
        transactions: [
          ...remainingTransactions,
          ...orderedTransactions,
        ],
        isLoadingRecurringTransactions: false,
      };
    }

    case companyTransactionsConstants.CREATE_RECURRING_TRANSACTION_FAILURE: {
      return {
        ...state,
        isLoadingRecurringTransactions: false,
      };
    }

    case companyTransactionsConstants.UPDATE_RECURRING_TRANSACTION_REQUEST: {
      return {
        ...state,
        isLoadingRecurringTransactions: true,
      };
    }

    case companyTransactionsConstants.UPDATE_RECURRING_TRANSACTION_SUCCESS: {
      const {
        transactions: updated_transactions,
        params,
      } = action.payload;
      const { selectedDate } = params;

      const updatedTransactionsIds = updated_transactions.map((transaction) => transaction.id);

      const other_transactions = state.transactions.filter(
        (transaction) => !updatedTransactionsIds.includes(transaction.id),
      );

      const sameMonthTransactions = updated_transactions.filter((transaction) => {
        const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });

        return transactionDate.month === selectedDate.month
          && transactionDate.year === selectedDate.year;
      });

      const newTransactions = [
        ...other_transactions,
        ...sameMonthTransactions,
      ];

      const orderedTransactions = [];

      state.transactions.forEach((transaction) => {
        const relatedTransaction = newTransactions.find((t) => t.id === transaction.id);

        if (relatedTransaction) {
          orderedTransactions.push(relatedTransaction);
        }
      });

      const remainingTransactions = newTransactions.filter((transaction) => {
        const ids = orderedTransactions.map((t) => t.id);

        return !ids.includes(transaction.id);
      });

      return {
        ...state,
        transactions: [
          ...remainingTransactions,
          ...orderedTransactions,
        ],
        isLoadingRecurringTransactions: false,
      };
    }

    case companyTransactionsConstants.UPDATE_RECURRING_TRANSACTION_FAILURE: {
      return {
        ...state,
        isLoadingRecurringTransactions: false,
      };
    }

    case companyTransactionsConstants.FETCH_TRANSACTIONS_OVERVIEW_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_TRANSACTIONS_OVERVIEW_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        transactionsOverview: data,
      };
    }

    case companyTransactionsConstants.FETCH_TRANSACTIONS_OVERVIEW_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.MOVE_TRANSACTIONS_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.MOVE_TRANSACTIONS_SUCCESS: {
      const {
        type,
        sub_type,
        sourceType,
        sourceSubType,
        transactions,
        isMobile = false,
      } = action.payload;

      const ids = transactions.map((transaction) => transaction.id);

      if (isMobile) {
        const newTransactions = state.transactions.map((transaction) => {
          if (ids.includes(transaction.id)) {
            return {
              ...transaction,
              type,
              sub_type,
            };
          }

          return transaction;
        });

        return {
          ...state,
          transactions: newTransactions,
        };
      }

      // Abas estão fechadas e a aba 'despesas' está selecionada
      if (sourceType !== type) {
        let transactions = [...state.transactions];
        transactions = transactions.filter((transaction) => !ids.includes(transaction.id));

        return {
          ...state,
          transactions,
        };
      }

      // Aba despesa selecionada e Subtype de despesa selecionado
      if (sourceType === 'EXPENSE' && sourceSubType === null && type === 'EXPENSE') {
        return {
          ...state,
        };
      }

      // Aba de despesa selecionada, alterou Subtype da despesa
      if (sourceType === 'EXPENSE' && type === 'EXPENSE' && sourceSubType !== sub_type) {
        let transactions = [...state.transactions];
        transactions = transactions.filter((transaction) => !ids.includes(transaction.id));

        return {
          ...state,
          transactions,
        };
      }

      return state;
    }

    case companyTransactionsConstants.MOVE_TRANSACTIONS_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_TRANSACTION_REQUEST: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_TRANSACTION_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        transaction: response.data,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_TRANSACTION_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.APPLY_TAGS_TO_TRANSACTION_SUCCESS: {
      const { transaction_id, transaction_ids, tags } = action.payload;

      if (transaction_id) {
        const updatedTransactions = state.transactions.map((transaction) => {
          if (transaction.id === transaction_id) {
            return {
              ...transaction,
              tags,
            };
          }

          return transaction;
        });

        return {
          ...state,
          transactions: updatedTransactions,
        };
      }

      if (transaction_ids) {
        const updatedTransactions = state.transactions.map((transaction) => {
          if (transaction_ids.includes(transaction.id)) {
            return {
              ...transaction,
              tags: tags.filter((tag) => tag.transaction_id === transaction.id),
            };
          }

          return transaction;
        });

        return {
          ...state,
          transactions: updatedTransactions,
        };
      }

      return {
        ...state,
      };
    }

    case companyTransactionsConstants.FETCH_MONTH_COMPARISON_SUCCESS: {
      return {
        ...state,
        monthComparison: action.payload,
      };
    }

    case companyTransactionsConstants.UPLOAD_CONTACTS_SPREADSHEET_REQUEST: {
      return {
        ...state,
        isUploadingContacts: true,
      };
    }

    case companyTransactionsConstants.UPLOAD_CONTACTS_SPREADSHEET_SUCCESS: {
      return {
        ...state,
        isUploadingContacts: false,
      };
    }

    case companyTransactionsConstants.UPLOAD_CONTACTS_SPREADSHEET_FAILURE: {
      return {
        ...state,
        isUploadingContacts: false,
      };
    }

    case companyTransactionsConstants.RECONCILE_MULTIPLE_TRANSACTIONS_REQUEST: {
      const { params } = action.payload;
      const {
        ids,
        category_id,
        recipient_id,
        description,
        type,
        sub_type,
        temp_sub_type,
        temp_type,
      } = params;

      const category = state.categories.find((cat) => cat.id === category_id);
      const recipient = state.recipients.find((rec) => rec.id === recipient_id);

      const newTransactions = state.bankStatementTransactions.map((transaction) => {
        if (ids.includes(transaction.id)) {
          return {
            ...transaction,
            category_id,
            category,
            recipient_id,
            recipient,
            description,
            type,
            sub_type,
            temp_sub_type,
            temp_type,
          };
        }

        return transaction;
      });

      return {
        ...state,
        bankStatementTransactions: newTransactions,
      };
    }

    case companyTransactionsConstants.RECONCILE_MULTIPLE_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.RECONCILE_MULTIPLE_TRANSACTIONS_FAILURE: {
      return {
        ...state,
      };
    }

    case companyTransactionsConstants.UPLOAD_TRANSACTION_FILES_REQUEST: {
      return {
        ...state,
        isFetchingFiles: true,
      };
    }

    case companyTransactionsConstants.UPLOAD_TRANSACTION_FILES_SUCCESS: {
      const files = action.payload;

      const updatedTransactions = state.transactions.map((transaction) => {
        const file = files.find((file) => file.transaction_id === transaction.id);

        if (file) {
          transaction.has_attachments = true;
        }

        return transaction;
      });

      return {
        ...state,
        isFetchingFiles: false,
        transactions: updatedTransactions,
        files: [...state.files, ...files],
      };
    }

    case companyTransactionsConstants.UPLOAD_TRANSACTION_FILES_FAILURE: {
      return {
        ...state,
        isFetchingFiles: false,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_TRANSACTION_FILES_REQUEST: {
      return {
        ...state,
        isFetchingFiles: true,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_TRANSACTION_FILES_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        files: response.data,
        isFetchingFiles: false,
      };
    }

    case companyTransactionsConstants.FETCH_COMPANY_TRANSACTION_FILES_FAILURE: {
      return {
        ...state,
        isFetchingFiles: false,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_FILES_REQUEST: {
      const { id } = action.payload;

      const deletedFile = state.files.find(
        (file) => file.id === id,
      );
      const files = state.files.filter((file) => file.id !== id);

      return {
        ...state,
        files,
        deletedFile,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_FILES_SUCCESS: {
      return {
        ...state,
        deletedFile: null,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_FILES_FAILURE: {
      const { deletedFile } = state;

      return {
        ...state,
        files: [
          ...state.files,
          deletedFile,
        ],
        deletedFile: null,
      };
    }

    case companyTransactionsConstants.CLEAR_TRANSACTION_FILES: {
      return {
        ...state,
        files: [],
      };
    }

    case companyTransactionsConstants.SEARCH_REQUEST: {
      return {
        ...state,
        isSearching: true,
      };
    }

    case companyTransactionsConstants.SEARCH_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload.data,
        isSearching: false,
      };
    }

    case companyTransactionsConstants.SEARCH_FAILURE: {
      return {
        ...state,
        isSearching: false,
      };
    }

    case companyTransactionsConstants.CLEAR_SEARCH_RESULTS: {
      const newSearchResults = {
        totals: {
          total: 0,
          transactions: 0,
          categories: 0,
          transactionsWithTags: 0,
          contacts: 0,
          costCenters: 0,
        },
        transactions: [],
        transactionsWithTags: [],
        categories: [],
        contacts: [],
        costCenters: [],
      };

      return {
        ...state,
        searchResults: newSearchResults,
      };
    }

    case companyTransactionsConstants.GET_CONSUMED_STORAGE_SUCCESS: {
      return {
        ...state,
        consumedStorage: action.payload,
      };
    }

    case companyTransactionsConstants.CREATE_CONTACT_REQUEST: {
      const { id, values } = action.payload;

      return {
        ...state,
        recipients: [
          {
            ...values,
            id,
          },
          ...state.recipients,
        ],
      };
    }

    case companyTransactionsConstants.CREATE_CONTACT_SUCCESS: {
      const { id, values } = action.payload;

      const recipients = state.recipients.map((recipient) => {
        if (recipient.id === id) {
          return values;
        }

        return recipient;
      });

      return {
        ...state,
        recipients,
      };
    }

    case companyTransactionsConstants.UPDATE_CONTACT_SUCCESS: {
      const { data } = action.payload;

      const recipients = state.recipients.map((recipient) => {
        if (recipient.id === data.id) {
          return data;
        }

        return recipient;
      });

      return {
        ...state,
        recipients,
      };
    }

    case companyTransactionsConstants.CREATE_SPLIT_TRANSACTION_REQUEST: {
      return {
        ...state,
        isLoadingSplits: true,
      };
    }

    case companyTransactionsConstants.CREATE_SPLIT_TRANSACTION_SUCCESS: {
      const { transactions } = action.payload;

      const updatedTransactions = state.transactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          return updatedTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        transactions: updatedTransactions,
        isLoadingSplits: false,
      };
    }

    case companyTransactionsConstants.CREATE_SPLIT_TRANSACTION_FAILURE: {
      return {
        ...state,
        isLoadingSplits: false,
      };
    }

    case companyTransactionsConstants.UPDATE_SPLIT_TRANSACTION_REQUEST: {
      return {
        ...state,
        isLoadingSplits: true,
      };
    }

    case companyTransactionsConstants.UPDATE_SPLIT_TRANSACTION_SUCCESS: {
      const { transactions } = action.payload;

      const updatedTransactions = state.transactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          return updatedTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        transactions: updatedTransactions,
        isLoadingSplits: false,
      };
    }

    case companyTransactionsConstants.UPDATE_SPLIT_TRANSACTION_FAILURE: {
      return {
        ...state,
        isLoadingSplits: false,
      };
    }

    case companyTransactionsConstants.DELETE_SPLIT_TRANSACTION_SUCCESS: {
      const { transactions } = action.payload;

      const updatedTransactions = state.transactions.map((transaction) => {
        const updatedTransaction = transactions.find((t) => t.id === transaction.id);

        if (updatedTransaction) {
          return updatedTransaction;
        }

        return transaction;
      });

      return {
        ...state,
        transactions: updatedTransactions,
        isLoadingSplits: false,
      };
    }

    case companyTransactionsConstants.CLEAR_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        transactions: [],
      };
    }

    default:
      return state;
  }
}
