import React, {
  useMemo,
  useRef,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import { REPORT_FRIENDLY_NAMES, getReportDescription } from '../utilities';
import { ReportContext } from './ReportContext';
import PrintHeader from './PrintHeaderContainer';

import { StyledTable, StyledTransactionRow } from '../styles';
import useTransactionsHelpers from '../utilities/useTransactionsFormatters';
import EmptyState from './EmptyState/EmptyState';

function TableReport({
  name,
  formattedPeriod,
  reports,
  account_ids,
  cost_center_ids,
  values,
  onEditTransaction,
  isMobile,
  accounts,
}) {
  const { setValue } = useContext(ReportContext);

  const {
    getDescription,
    getDate,
    getPaidIcon,
  } = useTransactionsHelpers({ values });

  const reportRef = useRef();

  useEffect(() => {
    setValue(reportRef);
  }, [setValue]);

  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports[name] || {};
    const {
      results = {
        transactions: [],
      },
    } = report;

    return results;
  }, [reports, name]);

  if (isEmpty(data.transactions)) {
    return <EmptyState />;
  }

  return (
    <Report ref={reportRef}>
      <Card>
        <ReactTooltip />
        <PrintHeader
          title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
          description={formattedPeriod}
          formattedPeriod={formattedPeriod}
          account_ids={account_ids}
          cost_center_ids={cost_center_ids}
        />
        <CardBody className="p-0 mt-0">
          <div className={classNames({
            'table-responsive': isMobile,
          })}
          >
            <StyledTable className="table table-hover">
              <thead style={{
                display: 'table-row-group',
              }}
              >
                <tr>
                  <th colSpan={2} className="no-border">
                    {getReportDescription(name, formattedPeriod)}
                  </th>
                  <th colSpan={2} className="text-right font-weight-bold no-border">Saldo Anterior</th>
                  <th className="text-right font-weight-bold">
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.initial_balance)}
                  </th>
                </tr>
                <tr>
                  <th style={{ width: '130px' }}>Data</th>
                  <th>Descrição</th>
                  <th style={{ width: '160px' }}>Categoria</th>
                  <th style={{ width: '140px' }} className="text-right">Valor</th>
                  <th style={{ width: '140px' }} className="text-right">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {data.transactions.map((item) => (
                  <StyledTransactionRow onClick={() => onEditTransaction(item)}>
                    <td>
                      {getPaidIcon(item)}
                      {getDate(item)}
                    </td>
                    <td>
                      <span className="d-flex flex-column">
                        <span>
                          {getDescription(item)}
                        </span>
                        <span>
                          {item.type !== 'TRANSFER' && item.recipient.name && (
                            <small className="text-muted">
                              {item.type !== 'TRANSFER' && item.recipient.name && FORMATTERS.REPORT_CASH_FLOW_PREFIX(item)}
                            </small>
                          )}
                          {item.type === 'TRANSFER' && item.transfer_details && (
                            <small className="text-muted">
                              {item.type === 'TRANSFER' && item.transfer_details && FORMATTERS.REPORT_CASH_FLOW_TRANSFER(item, accounts)}
                            </small>
                          )}
                        </span>
                      </span>
                    </td>
                    <td>
                      {FORMATTERS.REPORT_CATEGORY_NAME(item)}
                    </td>
                    <td className="text-right">
                      {FORMATTERS.REPORT_AMOUNT(item.amount, item.type, item.sub_type)}
                    </td>
                    <td className="text-right">
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(item.balance)}
                    </td>
                  </StyledTransactionRow>
                ))}
              </tbody>
            </StyledTable>
          </div>
          <StyledTable>
            <tbody>
              <tr>
                <td colSpan={4}>
                  <strong>Saldo Anterior</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.initial_balance)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <strong>Total de Entradas no Período</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.total_incomes)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <strong>Total de Saídas no Período</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.total_expenses * -1)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <strong>Balanço no Período</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.total_balance)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <strong>Saldo Final</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.final_balance)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </CardBody>
      </Card>
    </Report>
  );
}

TableReport.defaultProps = {
  reports: {},
  formattedPeriod: '',
  values: {},
  isMobile: false,
  accounts: [],
};

TableReport.propTypes = {
  formattedPeriod: PropTypes.string,
  name: PropTypes.string.isRequired,
  reports: PropTypes.object,
  values: PropTypes.object,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
  onEditTransaction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  accounts: PropTypes.array,
};

export default TableReport;
