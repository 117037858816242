import HttpService from './http.service';

export default {
  getAll,
  getById,
  getActive,
  setActive,
  insert,
  update,
  remove,
  setActivePeriod,
  search,
  uploadCertificate,
  addCredential,
  updateCredential,
  deleteCredential,
  addFee,
  updateFee,
  deleteFee,
  activateInvoiceIssuing,
  getCompanyRevenue,
  createCompanyRevenue,
  addDigitalCertificate,
  deleteDigitalCertificate,
  addAvatar,
  deleteAvatar,
  getMarketSegments,
  addFinancialCompany,
  updateFinancialCompany,
  deleteFinancialCompany,
};

async function getAll(params) {
  return HttpService.get('/companies', params);
}

async function getById(id) {
  return HttpService.get(`/companies/${id}`);
}

async function getActive(params) {
  return HttpService.get('/active-company', params);
}

async function setActive(company) {
  return HttpService.put(`/active-company/${company.id}`);
}

async function setActivePeriod(startDate, finalDate) {
  return HttpService.put('/active-period', {
    start_date: startDate,
    end_date: finalDate,
  });
}

async function insert(company) {
  return HttpService.post('/companies', company);
}

async function update(id, company) {
  return HttpService.put(`/companies/${id}`, company);
}

async function remove(id) {
  return HttpService.delete(`/companies/${id}`);
}

async function search(cnpj) {
  return HttpService.get(`/search-company/${cnpj}`);
}

async function uploadCertificate(id, payload) {
  return HttpService.put(`/companies/${id}/upload-certificate`, payload);
}

async function addCredential(company_id, payload) {
  return HttpService.post(`/company/${company_id}/credentials`, payload);
}

async function updateCredential(company_id, id, payload) {
  return HttpService.put(`/company/${company_id}/credentials/${id}`, payload);
}

async function deleteCredential(company_id, id) {
  return HttpService.delete(`/company/${company_id}/credentials/${id}`);
}

async function addFee(company_id, fee) {
  return HttpService.post(`/company/${company_id}/fees`, fee);
}

async function updateFee(company_id, feeId, payload) {
  return HttpService.put(`/company/${company_id}/fees/${feeId}`, payload);
}

async function deleteFee(company_id, id) {
  return HttpService.delete(`/company/${company_id}/fees/${id}`);
}

async function activateInvoiceIssuing(company_id, values) {
  return HttpService.post(`/company/${company_id}/invoice-settings`, values);
}

async function getCompanyRevenue(company_id, year) {
  return HttpService.get(`/company/${company_id}/revenue?year=${year}`);
}

async function createCompanyRevenue(params) {
  return HttpService.post(`/company/${params.company_id}/revenue`, params);
}

async function addDigitalCertificate(company_id, formData) {
  return HttpService.upload(`/company/${company_id}/digital-certificate`, formData);
}

async function deleteDigitalCertificate(company_id) {
  return HttpService.delete(`/company/${company_id}/digital-certificate`);
}

async function addAvatar(company_id, formData) {
  return HttpService.post(`/company/${company_id}/avatar`, formData);
}

async function deleteAvatar(company_id) {
  return HttpService.delete(`/company/${company_id}/avatar`);
}

async function getMarketSegments() {
  return HttpService.get('/company-market-segments');
}

async function addFinancialCompany(financialCompany) {
  return HttpService.post('/financial-companies', financialCompany);
}

async function updateFinancialCompany(company_id, financialCompany) {
  return HttpService.put(`/financial-companies/${company_id}`, financialCompany);
}

async function deleteFinancialCompany(financialCompany) {
  return HttpService.delete(`/financial-companies/${financialCompany}`);
}
