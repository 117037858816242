/* eslint-disable max-len */
import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import ContactsUpload from './ContactsUpload';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onUploadContactsSpreadsheet: companyTransactionsActionCreators.uploadContactsSpreadsheet,
  onUploadContactsSpreadsheetConfirm: companyTransactionsActionCreators.uploadContactsSpreadsheetConfirm,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['import_files']),
)(ContactsUpload);
