/* eslint-disable no-alert */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IoPrintOutline } from 'react-icons/io5';

import { Button } from '_components/_core';

function PrintControls({ report_name }) {
  const buttonProps = useMemo(() => ({
    className: 'd-flex justify-content-center align-items-center',
    variant: 'default',
    size: 'sm',
  }), []);

  const handlePrint = () => {
    if (!report_name) {
      alert('Por favor, selecione um relatório antes de imprimir.');

      return;
    }

    window.print();
  };

  return (
    <>
      <Button onClick={handlePrint} {...buttonProps}>
        <IoPrintOutline size="1.2em" />
      </Button>
    </>
  );
}

PrintControls.defaultProps = {
  isMobile: false,
  report_name: null,
};

PrintControls.propTypes = {
  isMobile: PropTypes.bool,
  report_name: PropTypes.string,
};

export default PrintControls;
