/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { isAuthenticated } from '../services/auth.service';
import AuthorizedLayout from '../layouts/AuthorizedLayout';
import Page from '../_components/_core/Page/Page';

export default function AuthorizedRoute({
  title,
  showFooter,
  showFooterMobile,
  fullHeight,
  component: Component,
  ...rest
}) {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;

    ReactGA.send({
      hitType: 'pageview',
      page: page_path,
      title,
    });
  }, [location, title]);

  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated() ? (
        <AuthorizedLayout
          showFooter={showFooter}
          showFooterMobile={showFooterMobile}
          fullHeight={fullHeight}
        >
          <Page title={title}>
            <Component {...props} />
          </Page>
        </AuthorizedLayout>
      ) : (
        <Redirect
          to={{ pathname: '/entrar', state: { from: props.location } }}
        />
      ))}
    />
  );
}

AuthorizedRoute.defaultProps = {
  title: '',
  showFooter: true,
  showFooterMobile: true,
  fullHeight: false,
};

AuthorizedRoute.propTypes = {
  title: PropTypes.string,
  showFooter: PropTypes.bool,
  showFooterMobile: PropTypes.bool,
  fullHeight: PropTypes.bool,
};
