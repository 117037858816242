import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import { IoArrowForward } from 'react-icons/io5';
import sortBy from 'lodash/sortBy';
import { Button } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

import { isFormValuesChanged, getChangedValues } from 'helpers';
import {
  FormDateField,
  FormTextField,
  FormCurrencyField,
  Select,
  FormSwitchField,
} from '_components/_core';
import { TransactionForm } from '_components/_shared';

import useCategories from '_store/_hooks/useCategories';

import Calculator from '_components/_shared/Calculator/Calculator';

import { MdFormatListBulleted } from 'react-icons/md';
import FORMATTERS from 'helpers/formatters';
import CustomFormSelectField from '../CustomFormSelectField/CustomFormSelectField';
import FrequencyModal from '../FrequencyModal/FrequencyModalContainer';
import { StyledDetailsButton } from './styles';

import useTransactions from '../../utilities/useTransactions';

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      const isClickOnOption = event.target.id.includes('react-select');
      const isCreatableOption = event.target.id.includes('creatable-option');
      const isDetailButton = event.target.id.includes('details-button');
      const isModalOpen = document.querySelector('.modal.show');
      const isDatePicker = Array.from(event.target.classList).some((c) => c.includes('datepicker'));
      const isPreventClose = document.querySelector('.transaction-form-open');
      const isToggleButton = event.target.id.includes('toggle-payment-button');

      if (ref.current
        && !ref.current.contains(event.target)
        && !isClickOnOption
        && !isModalOpen
        && !isDatePicker
        && !isCreatableOption
        && !isDetailButton
        && !isPreventClose
        && !isToggleButton) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);

  return ref;
};

function TransactionRowForm({
  type,
  subType,
  transaction,
  transactionFormRef,
  onSubmit,
  categories,
  recipients,
  accounts,
  selected_account_id,
  selectedFieldName,
  onCancelEditTransaction,
  selectedDate,
  isCreationMode,
  onEditSplitTransaction,
}) {
  const eventDateRef = useRef();
  const descriptionRef = useRef();
  const categoryRef = useRef();
  const paymentPlanRef = useRef();
  const recipientRef = useRef();
  const amountRef = useRef();
  const originAccountRef = useRef();
  const destinationAccountRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] = useState(false);

  const { onRefreshBalance } = useTransactions();

  const {
    onLoadSuggestions: onLoadCategoriesSuggestions,
  } = useCategories({});

  const initialValues = useMemo(() => {
    if (transaction) {
      return transaction;
    }

    return {
      account_id: selected_account_id,
      event_date: DateTime.local().toISODate(),
      category_id: null,
      recipient_id: null,
      description: '',
      amount: null,
      paid: false,
      type,
      sub_type: subType,
    };
  }, [transaction, selected_account_id, subType, type]);

  const handleSubmitTransaction = useCallback((values) => {
    values.description = values.description.trim().replace(/\s+/g, ' ');

    if (!isCreationMode && !isFormValuesChanged(values, initialValues)) {
      return;
    }

    const changedValues = getChangedValues(values, initialValues);

    onSubmit({
      ...values,
      changedValues,
    });
  },
  [onSubmit, isCreationMode, initialValues]);

  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();

      onCancelEditTransaction();
    }
  }, [onCancelEditTransaction]);

  const handleClickOutside = useCallback(() => {
    if (transactionFormRef.current) {
      transactionFormRef.current.handleSubmit();
    }

    onCancelEditTransaction();
  }, [onCancelEditTransaction, transactionFormRef]);

  const rowRef = useOutsideClick(handleClickOutside);

  useEffect(() => {
    if (!isCreationMode) {
      const element1 = document.getElementById('btn-adicionar-recebimento');
      const element2 = document.getElementById('btn-adicionar-despesa');
      const element3 = document.getElementById('btn-adicionar-transferencia');

      if (element1) {
        element1.addEventListener('click', handleClickOutside);
      }

      if (element2) {
        element2.addEventListener('click', handleClickOutside);
      }

      if (element3) {
        element3.addEventListener('click', handleClickOutside);
      }

      return () => {
        if (element1) {
          element1.removeEventListener('click', handleClickOutside);
        }

        if (element2) {
          element2.removeEventListener('click', handleClickOutside);
        }

        if (element3) {
          element3.removeEventListener('click', handleClickOutside);
        }
      };
    }

    return () => {};
  }, [isCreationMode, handleClickOutside]);

  const handleCloseTransactionForm = useCallback(() => {
    onCancelEditTransaction();
  }, [onCancelEditTransaction]);

  const recipientsOptions = useMemo(() => {
    const formatted = sortBy(recipients, 'name').map((recipient) => ({
      value: recipient.id,
      label: recipient.name,
    }));

    formatted.unshift({
      value: null,
      label: '--',
    });

    return formatted;
  }, [recipients]);

  const TRANSLATIONS = useMemo(() => ({
    [null]: 'Receitas',
    FIXED_EXPENSE: 'Despesas Fixas',
    VARIABLE_EXPENSE: 'Despesas Variáveis',
    PEOPLE: 'Pessoas',
    TAXES: 'Impostos',
  }), []);

  const categoriesOptions = useMemo(() => {
    const filtered = categories.filter((c) => c.type === type);

    const groupedByType = groupBy(filtered, 'sub_type');

    const options = Object.keys(groupedByType).map((key) => ({
      label: TRANSLATIONS[key],
      options: sortBy(groupedByType[key], 'description').map((category) => ({
        value: category.id,
        label: category.description,
      })),
    }));

    // options.unshift({
    //   value: null,
    //   label: '--',
    // });

    return options;
  }, [categories, TRANSLATIONS, type]);

  const paymentPlanOptions = useMemo(() => {
    if (transaction && transaction.payment_plan === 'RECURRENT') {
      return [
        {
          label: 'À vista',
          value: 'ONE_TIME',
          isDisabled: true,
        },
        {
          label: 'Criar parcelas',
          value: 'INSTALMENT',
          isDisabled: true,
        },
        {
          label: 'Editar repetição',
          value: 'RECURRENT',
        },
      ];
    }

    if (transaction && transaction.payment_plan === 'INSTALMENT') {
      return [
        {
          label: 'À vista',
          value: 'ONE_TIME',
          isDisabled: true,
        },
        {
          label: 'Editar parcelas',
          value: 'INSTALMENT',
        },
        {
          isDisabled: true,
          label: 'Repetir transação',
          value: 'RECURRENT',
        },
      ];
    }

    const options = [
      {
        label: 'À vista',
        value: 'ONE_TIME',
      },
      {
        label: 'Criar parcelas',
        value: 'INSTALMENT',
      },
      {
        label: 'Repetir transação',
        value: 'RECURRENT',
      },
    ];

    return options;
  }, [transaction]);

  const originAccountsOptions = useMemo(() => {
    const formatted = accounts.map((account) => ({
      value: account.id,
      label: account.description,
    }));

    return formatted;
  }, [accounts]);

  const destinationAccountsOptions = useMemo(() => {
    if (selected_account_id) {
      const formatted = accounts.map((account) => ({
        value: account.id,
        label: account.description,
      }));

      return formatted;
    }

    const filtered = accounts.filter((a) => a.id !== selected_account_id);

    const formatted = filtered.map((account) => ({
      value: account.id,
      label: account.description,
    }));

    return formatted;
  }, [accounts, selected_account_id]);

  const handlePaymentPlanChange = useCallback((option) => {
    if (option.value === 'INSTALMENT' || option.value === 'RECURRENT') {
      setIsModalOpen(true);
      setPaymentPlan(option.value);
    }
  }, []);

  const handleModalToggle = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setPaymentPlan(null);
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedFieldName === 'event_date') {
      if (eventDateRef.current) {
        eventDateRef.current.setFocus(true);
      }
    }

    if (selectedFieldName === 'description') {
      if (descriptionRef.current) {
        descriptionRef.current.focus();
      }
    }

    if (selectedFieldName === 'amount') {
      if (amountRef.current) {
        amountRef.current.theInput.focus();
      }
    }

    if (selectedFieldName === 'recipient') {
      if (recipientRef.current) {
        recipientRef.current.onMenuOpen();
        recipientRef.current.focus();
      }
    }

    if (selectedFieldName === 'category') {
      if (categoryRef.current) {
        categoryRef.current.onMenuOpen();
        categoryRef.current.focus();
      }
    }

    if (selectedFieldName === 'payment_plan') {
      if (paymentPlanRef.current) {
        paymentPlanRef.current.onMenuOpen();
        paymentPlanRef.current.focus();
      }
    }
  }, [selectedFieldName, descriptionRef, eventDateRef, amountRef]);

  const handleToggleTransactionForm = useCallback(() => {
    setIsTransactionDetailsOpen(!isTransactionDetailsOpen);
  }, [isTransactionDetailsOpen]);

  const handleOpenTransactionDetails = useCallback(() => {
    setIsTransactionDetailsOpen(true);
  }, []);

  const handleEditSplitTransaction = useCallback(() => {
    onEditSplitTransaction(transaction);
  }, [transaction, onEditSplitTransaction]);

  return (
    <>
      <Formik
        innerRef={transactionFormRef}
        initialValues={initialValues}
        onSubmit={handleSubmitTransaction}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <>
            <TransactionForm
              title="Detalhes da transação"
              isOpen={isTransactionDetailsOpen}
              transaction={values}
              disabledFields={[]}
              onToggleForm={handleToggleTransactionForm}
              selectedDate={selectedDate}
              onAfterSaveCallback={() => {
                handleToggleTransactionForm();
                onRefreshBalance();
                onCancelEditTransaction(transaction.id);
              }}
              onAfterSaveSplitCallback={() => {
                onRefreshBalance();
              }}
              hiddenFields={[
                'type_sub_type',
              ]}
              side="right"
              defaultExpanded
            />
            <FrequencyModal
              transaction={{
                ...values,
              }}
              isVisible={isModalOpen}
              paymentPlan={paymentPlan}
              onModalToggle={handleModalToggle}
              onCloseTransactionForm={handleCloseTransactionForm}
              selectedDate={selectedDate}
            />
            <tr
              ref={rowRef}
              className="transaction-form-row"
              onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
            >
              <td className="checkbox-column">
                &nbsp;
              </td>
              <td className="p-0 m-0 event-date-column edit-mode-wrapper">
                <span>
                  <FormDateField
                    id={`event_date_${transaction.id}`}
                    innerRef={eventDateRef}
                    name="event_date"
                    placeholder="Data"
                    style={{
                      textAlign: 'center',
                      padding: 0,
                    }}
                    placement="bottom-start"
                    inputStyleProps={{
                      display: 'inline-block',
                      height: '32px',
                      padding: '4px 6px',
                      fontSize: '0.75rem',
                      lineHeight: '22px',
                      color: '#393A3D',
                      verticalAlign: 'middle',
                      borderRadius: '3px',
                    }}
                  />
                </span>
              </td>
              <td className="description-column">
                <FormTextField
                  innerRef={descriptionRef}
                  name="description"
                  placeholder="Descrição"
                  autoComplete="off"
                />
              </td>
              {type !== 'TRANSFER' && (
                <td className="recipient-column">
                  {transaction.split && (
                  <span>
                    (vários)
                  </span>
                  )}
                  {!transaction.split && (
                  <CustomFormSelectField
                    name="recipient_id"
                    innerRef={recipientRef}
                    options={recipientsOptions}
                    creatable="recipient"
                    metadata={{
                      type: null,
                    }}
                    loadingMessage={() => 'Carregando...'}
                    onCreateCallback={(created_recipient) => {
                      if (created_recipient) {
                        setFieldValue('recipient_id', created_recipient.id);
                      }
                    }}
                    customStyleName="transactions"
                    onChange={(option) => {
                      setFieldValue('recipient_id', option ? option.value : null);

                      if (!option) {
                        return;
                      }

                      const params = {
                        suggestion_for: 'category_id',
                        recipient_id: option.value,
                      };

                      onLoadCategoriesSuggestions(params, (found) => {
                        const [first] = found || [];

                        if (first && !values.category_id) {
                          setFieldValue('category_id', first.value);
                        }
                      });
                    }}
                  />
                  )}
                </td>
              )}
              <td className="amount-column main">
                {transaction.split && (
                  <span>
                    {FORMATTERS.TRANSACTION_AMOUNT(transaction)}
                  </span>
                )}
                {!transaction.split && (
                  <>
                    <FormCurrencyField
                      innerRef={amountRef}
                      name="amount"
                      placeholder="Valor"
                      inputStyleProps={{
                        paddingLeft: '8px',
                      }}
                      forceZero
                    />
                    <Calculator
                      value={String(values.amount)}
                      onChange={(value) => setFieldValue('amount', value)}
                    />
                  </>
                )}
              </td>
              {type !== 'TRANSFER' && (
                <td className="category-column">
                  {transaction.split && (
                    <span>
                      (vários)
                    </span>
                  )}
                  {!transaction.split && (
                    <CustomFormSelectField
                      name="category_id"
                      innerRef={categoryRef}
                      options={categoriesOptions}
                      creatable="category"
                      metadata={{
                        type,
                        sub_type: subType,
                        transaction_id: transaction.id,
                      }}
                      loadingMessage={() => 'Carregando...'}
                      onCreateCallback={(created) => {
                        if (created) {
                          setFieldValue('category_id', created.id);
                        }
                      }}
                      isClearable
                    />
                  )}
                </td>
              )}
              {type !== 'TRANSFER' && (
              <td className="payment_plan-column">
                {transaction.split && (
                  <span>
                    À Vista
                  </span>
                )}
                  {!transaction.split && (
                    <Select
                      name="payment_plan"
                      innerRef={paymentPlanRef}
                      options={paymentPlanOptions}
                      onChange={handlePaymentPlanChange}
                      value={paymentPlanOptions.find((c) => c.value === values.payment_plan)}
                      customStyles={{
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          width: '150%',
                        }),
                        option: (baseStyles, state) => {
                          let color = state.isFocused ? '#00784e' : 'rgb(0, 0, 0)';

                          if (state.isDisabled) {
                            color = '#babec5';
                          }

                          return {
                            ...baseStyles,
                            cursor: 'pointer',
                            backgroundColor: state.isFocused || state.isSelected ? '#eceef1' : 'transparent',
                            color,
                            fontWeight: state.isFocused ? 500 : 400,
                          };
                        },
                      }}
                    />
                  )}
              </td>
              )}
              {type === 'TRANSFER' && (
                <td className="account_id_origin-column">
                  <CustomFormSelectField
                    name="transfer_details.account_id_origin"
                    innerRef={originAccountRef}
                    options={originAccountsOptions}
                    metadata={{
                      type,
                      sub_type: subType,
                      transaction_id: transaction.id,
                    }}
                    disabled={transaction.id}
                  />
                </td>
              )}
              {type === 'TRANSFER' && (
                <td className="account_id_destination-column">
                  <CustomFormSelectField
                    name="transfer_details.account_id_destination"
                    innerRef={destinationAccountRef}
                    options={destinationAccountsOptions}
                    metadata={{
                      type,
                      sub_type: subType,
                      transaction_id: transaction.id,
                    }}
                    disabled={transaction.id}
                  />
                </td>
              )}
              <td className="text-center paid-column">
                {!transaction.split && (
                  <FormSwitchField name="paid" />
                )}
                {transaction.split && (
                  <span>
                    <MdFormatListBulleted onClick={handleEditSplitTransaction} size="1.5em" className="text-muted" />
                  </span>
                )}
              </td>
              <td className="action-column">
                <Button
                  noMargin
                  variant="success"
                  onClick={() => {
                    handleSubmit();

                    onCancelEditTransaction();
                  }}
                  className="p-2"
                >
                  <FaCheck />
                </Button>
              </td>
            </tr>
            {transaction.type !== 'TRANSFER' && (
              <StyledDetailsButton
                onClick={() => handleOpenTransactionDetails()}
                id="details-button"
              >
                Mais detalhes
                <IoArrowForward className="ml-2" />
              </StyledDetailsButton>
            )}
          </>
        )}
      </Formik>
    </>
  );
}

TransactionRowForm.defaultProps = {
  transaction: {},
  categories: [],
  recipients: [],
  selectedFieldName: null,
  accounts: [],
  selectedDate: null,
  isCreationMode: false,
};

TransactionRowForm.propTypes = {
  isCreationMode: PropTypes.bool,
  transaction: PropTypes.object,
  categories: PropTypes.array,
  recipients: PropTypes.array,
  type: PropTypes.string,
  subType: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancelEditTransaction: PropTypes.func.isRequired,
  transactionFormRef: PropTypes.object.isRequired,
  selectedFieldName: PropTypes.string,
  selected_account_id: PropTypes.string.isRequired,
  accounts: PropTypes.array,
  selectedDate: PropTypes.object,
  onEditSplitTransaction: PropTypes.func.isRequired,
};

export default TransactionRowForm;
