/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';

import { FastFilterSelect } from '_components/_core';

import { REPORT_FRIENDLY_NAMES } from '../../../../../utilities';
import {
  StyledNavigationHeaderRow,
  BreadcrumbItem,
  Separator,
} from '../../../../../styles';

function Header({
  name,
  breadcrumbs,
  onResetReport,
  isShowingNavigationHeader,
  isShowingTransactions,
  all_data,
  values,
}) {
  const handleExportToXLSX = () => {
    const { category_name } = values;

    const formattedData = all_data.map((item) => {
      const { transaction } = item;

      const {
        event_date,
        description,
        recipient = {},
        category = {},
        amount,
      } = transaction;

      const formattedEventDate = DateTime.fromISO(event_date, {
        zone: 'utc',
      }).set({ hour: 12 }).setLocale('pt-BR').toJSDate();

      let fieldName = 'Pago a';
      let value = recipient.name;

      if (['incomes_by_recipient', 'expenses_by_recipient'].includes(name)) {
        fieldName = 'Categoria';
        value = category.description;
      }

      return {
        Data: formattedEventDate,
        Descrição: description,
        [fieldName]: value,
        Valor: amount,
      };
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    const fileName = `${REPORT_FRIENDLY_NAMES[name]} - ${category_name}`;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Movimentações');

    XLSX.writeFile(workbook, `${fileName}.xlsx`, {
      type: 'array',
      bookType: 'xlsx',
    });
  };

  const DefaultHeader = () => (
    <>
      <tr>
        <th>Descrição</th>
        <th className="text-right">Valor</th>
      </tr>
    </>
  );

  const TransactionsHeader = () => {
    let fieldName = 'Pago a';

    if (['incomes_by_recipient', 'expenses_by_recipient'].includes(name)) {
      fieldName = 'Categoria';
    }

    if (['income_by_category'].includes(name)) {
      fieldName = 'Recebido de';
    }

    return (
      <>
        <tr>
          <th style={{ width: '135px' }} className="text-left">
            Data
          </th>
          <th className="text-left">
            Descrição
          </th>
          <th className="text-left">
            {fieldName}
          </th>
          <th style={{ width: '110px' }} className="text-right">
            Valor
          </th>
        </tr>
      </>
    );
  };

  const NavigationHeader = () => (
    <>
      <StyledNavigationHeaderRow>
        <th colSpan={isShowingTransactions ? 2 : 4}>
          <BreadcrumbItem isClickable onClick={() => onResetReport()}>
            {REPORT_FRIENDLY_NAMES[name]}
          </BreadcrumbItem>
          <span>
            {breadcrumbs.map((breadcrumb) => (
              <>
                <Separator>{' / '}</Separator>
                <BreadcrumbItem
                  onClick={() => breadcrumb.onClick && breadcrumb.onClick()}
                  isClickable={breadcrumb.onClick}
                >
                  {breadcrumb.label}
                </BreadcrumbItem>
              </>
            ))}
          </span>
        </th>
        {isShowingTransactions && (
          <th
            colSpan={2}
            style={{
              borderLeft: 'none',
            }}
          >
            <span className="float-right no-print">
              <FastFilterSelect
                name="quick_actions"
                label="Ações"
                options={[
                  { value: 'export_xlsx', label: 'Exportar para Excel' },
                ]}
                onChange={(value) => {
                  if (value === 'export_xlsx') {
                    handleExportToXLSX();
                  }
                }}
              />
            </span>
          </th>
        )}
      </StyledNavigationHeaderRow>
      {!isShowingTransactions && (<DefaultHeader />)}
      {isShowingTransactions && (<TransactionsHeader />)}
    </>
  );

  if (isShowingNavigationHeader) {
    return <NavigationHeader />;
  }

  return <DefaultHeader />;
}

Header.defaultProps = {
  isShowingNavigationHeader: false,
  isShowingTransactions: false,
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  isShowingNavigationHeader: PropTypes.bool,
  isShowingTransactions: PropTypes.bool,
  onResetReport: PropTypes.func.isRequired,
};

export default Header;
