import React from 'react';
import PropTypes from 'prop-types';

import { StyledCompanyData } from '../../styles';

function CompanyData({
  companyName,
  formattedPeriod,
  companyDocumentNumber,
  logoPosition,
}) {
  // eslint-disable-next-line react/prop-types
  const LeftLine = ({ description, className }) => (
    <p className={`${className} mb-0`}>{description}</p>
  );
  return (
    <StyledCompanyData logoPosition={logoPosition}>
      <div>
        <LeftLine className="font-weight-bold" description={companyName} />
      </div>
      <div>
        <LeftLine description={companyDocumentNumber} />
      </div>
      <div>
        <LeftLine description={formattedPeriod} />
      </div>
    </StyledCompanyData>
  );
}

CompanyData.defaultProps = {
  formattedPeriod: null,
  logoPosition: 'start',
};

CompanyData.propTypes = {
  companyName: PropTypes.any,
  formattedPeriod: PropTypes.string,
  companyDocumentNumber: PropTypes.any,
  logoPosition: PropTypes.string,
};

export default CompanyData;
