import React from 'react';
import { DateTime } from 'luxon';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';
import { Button } from '_components/_core';

export const FILTERS_TYPE_DROPDOWN = [
  {
    label: 'Recebimento',
    value: 'INCOME',
  },
  {
    label: 'Despesa',
    value: 'EXPENSE',
  },
];

export const FILTERS_SUB_TYPE_DROPDOWN = [
  {
    label: 'Despesas Fixas',
    value: 'FIXED_EXPENSE',
  },
  {
    label: 'Despesas Variáveis',
    value: 'VARIABLE_EXPENSE',
  },
  {
    label: 'Pessoas',
    value: 'PEOPLE',
  },
  {
    label: 'Impostos',
    value: 'TAXES',
  },
  {
    label: 'Enviados',
    value: 'SENT',
  },
  {
    label: 'Recebidos',
    value: 'RECEIVED',
  },
];

export const FILTERS_PAID_DROPDOWN = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const isPastDue = (transaction) => {
  const today = DateTime.now();

  return DateTime.fromISO(transaction.event_date) < today;
};

const getPaidIcon = (transaction) => {
  if (!transaction) {
    return null;
  }

  if (isPastDue(transaction) && !transaction.paid) {
    return (
      <span>
        <FaExclamationCircle
          className="text-danger ml-2"
          data-place="bottom"
          data-tip="Esta movimentação está com o pagamento atrasado."
        />
      </span>
    );
  }

  if (transaction.paid) {
    return (
      <span>
        <FaCheckCircle
          className="text-success ml-2"
          data-place="bottom"
          data-tip="Esta movimentação foi efetivada."
        />
      </span>
    );
  }

  return null;
};

export const TRANSACTIONS_DEFAULT_COLUMNS = [
  'event_date',
  'due_date',
  'type',
  'description',
  'recipient_id',
  'category_id',
  'amount',
];

export const TRANSACTIONS_TABLE_COLUMNS = [
  {
    label: 'Data',
    keyName: 'event_date',
    align: 'left',
    order: 1,
    formatter: (columnValue, transaction = {}) => (
      <span>
        {FORMATTERS.DATE_DDMMYYYY(transaction.event_date)}
        {getPaidIcon(transaction)}
      </span>
    ),
    sortingKey: 'event_date',
    width: 10,
  },
  {
    label: 'Tipo',
    keyName: 'type',
    align: 'left',
    order: 3,
    width: 8,
    formatter: (columnValue, row) => FORMATTERS.TRANSACTION_TYPE_STRING_SEARCH_PAGE(
      row.type, row.sub_type,
    ),
  },
  {
    label: 'Descrição',
    keyName: 'description',
    align: 'left',
    order: 4,
    formatter: (columnValue, row = {}) => (
      <div className="w-100">
        {FORMATTERS.TRANSACTION_DESCRIPTION(row, 'compact', true, false)}
      </div>
    ),
    sortingKey: 'description',
    width: 20,
  },
  {
    label: 'Recebido de / Pago a',
    keyName: 'recipient_id',
    align: 'left',
    formatter: (columnValue, row = {}) => FORMATTERS.TRANSACTION_RECIPIENT(row.recipient),
    order: 5,
    sortingKey: 'recipient_id',
    width: 10,
  },
  {
    label: 'Categoria',
    keyName: 'category_id',
    align: 'left',
    formatter: (columnValue, row = {}) => FORMATTERS.REPORT_CATEGORY_NAME(row),
    order: 6,
    sortingKey: 'category_id',
    width: 10,
  },
  {
    label: 'Valor',
    keyName: 'amount',
    align: 'right',
    order: 7,
    width: 10,
    formatter: (columnValue, row = {}) => FORMATTERS.REPORT_AMOUNT(
      row.amount, row.type, row.sub_type, false,
    ),
    sortingKey: 'amount',
  },
];

export const TAGS_DEFAULT_COLUMNS = [
  'description',
  'id',
];

export const TAGS_TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Descrição',
    keyName: 'description',
    sortingKey: 'description',
    align: 'left',
    width: 90,
    order: 2,
  },
];

export const CONTACTS_DEFAULT_COLUMNS = [
  'name',
  'id',
];

export const CONTACTS_TABLE_COLUMNS = [
  {
    label: 'Cliente / Fornecedor',
    keyName: 'name',
    align: 'left',
    width: 10,
    order: 3,
  },
];

export const CATEGORIES_DEFAULT_COLUMNS = [
  'description',
  'id',
];

export const CATEGORIES_TABLE_COLUMNS = [
  {
    label: 'Categoria',
    keyName: 'description',
    align: 'left',
    formatter: (columnValue, row) => (
      <span>
        <span style={{ fontWeight: 600 }}>
          {row.description}
        </span>
        &nbsp;
        em
        &nbsp;
        <span className={row.type === 'INCOME' ? 'text-success' : 'text-danger'}>
          {FORMATTERS.TRANSACTION_TYPE_STRING(row.type, row.sub_type)}
        </span>
      </span>
    ),
    width: 10,
    order: 1,
  },
];

export const COST_CENTERS_DEFAULT_COLUMNS = [
  'description',
  'id',
];

export const COST_CENTERS_TABLE_COLUMNS = [
  {
    label: 'Centro de Custo',
    keyName: 'description',
    align: 'left',
    width: 10,
    order: 1,
  },
];

export const FILES_DEFAULT_COLUMNS = [
  'file_name',
  'id',
];

export const FILES_TABLE_COLUMNS = [
  {
    label: 'Arquivo',
    keyName: 'file_name',
    align: 'left',
    width: 15,
    order: 1,
    formatter: (columnValue, row) => (
      <span className="d-flex align-items-center">
        <span className="d-flex flex-column">
          <span style={{ fontWeight: 600 }}>
            {row.file_name}
          </span>
          <span>
            <Button
              variant="link"
              className="p-0 m-0"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                window.open(row.signed_url, '_blank');
              }}
            >
              Fazer Download
            </Button>
          </span>
        </span>
      </span>
    ),
  },
  {
    label: 'Movimentação',
    keyName: 'file_name',
    align: 'left',
    width: 85,
    order: 1,
    formatter: (columnValue, row) => (
      <span className="d-flex align-items-center">
        <span className="d-flex flex-column">
          <span>
            Encontrado na movimentação <span style={{ fontWeight: 600 }}>{`"${row.transaction.description || 'sem descrição'}"`}</span>
          </span>
          <span className="text-muted">
            Data: <span style={{ fontWeight: 600 }}>{`${FORMATTERS.DATE_DDMMYYYY(row.transaction.event_date)}`}</span>
            &nbsp;
            -
            &nbsp;
            Valor: <span style={{ fontWeight: 600 }}>{`${FORMATTERS.NUMBER(row.transaction.amount)}`}</span>
            &nbsp;
            -
            &nbsp;
            Pago: <span style={{ fontWeight: 600 }}>{row.transaction.paid ? 'Sim' : 'Não'}</span>
          </span>
          <span>
            <Button
              variant="link"
              className="p-0 m-0"
            >
              Ver movimentação
            </Button>
          </span>
        </span>
      </span>
    ),
  },
];
