/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, useLocation, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { isAuthenticated } from '../services/auth.service';
import FullWidthLayout from '../layouts/FullWidthLayout';
import Page from '../_components/_core/Page/Page';

export default function FullWidthRoute({ title, component: Component, ...rest }) {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;

    ReactGA.send({
      hitType: 'pageview',
      page: page_path,
      title,
    });
  }, [location, title]);

  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated() ? (
        <FullWidthLayout>
          <Page title={title}>
            <Component {...props} />
          </Page>
        </FullWidthLayout>
      ) : (
        <Redirect
          to={{ pathname: '/Entrar', state: { from: props.location } }}
        />
      ))}
    />
  );
}
