/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import ReactTooltip from 'react-tooltip';
import { TiArrowSortedDown } from 'react-icons/ti';

import { TRANSACTION_FREQUENCY_OPTIONS } from 'helpers/constants';
import { CurrencyInput, Select } from '_components/_core';

import { LoadingIcon } from '_components/_shared';

import { INTALMENTS_OPTIONS } from '../../utilities';

import Table from './components/Table';
import useParcelamento from './utilities/useParcelamento';

import {
  StyledDropdownMenu,
  Option,
  StyledButton,
} from './styles';

function InstalmentsForm({
  isEditing,
  values,
  setFieldValue,
  handleSubmit,
  onFindInstalmentsTransactions,
  onChangeFormValid,
  onSelectInstalmentForEdit,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoadingInstalments, setIsLoadingInstalments] = useState(false);

  const {
    parcelasCalculadas,
    parcelasEditadasValor,
    parcelasEditadasData,

    tipoValorDescricao,
    tipoValorAjuda,
    valorTotal,
    qtdeParcelas,
    frequencia,
    valorTotalCalculado,

    onChangeValor,
    onChangeTipoValor,
    onChangeQtdeParcelas,
    onChangeFrequencia,

    renderSumWarningFor,
    renderRecalcWarningFor,
    renderRecalcTotalWarning,

    onCreateDefaultParcelas,
    onCreateExistingParcelas,

    onChangeParcela,
    onDeleteParcela,
    onClearDeletedParcelas,
    onRecalcularParcelas,
    onRecalcularTotal,
  } = useParcelamento({
    valorTotalInicial: values.amount,
    tipoValorInicial: values.type,
    qtdeParcelasInicial: values.instalment_count,
    frequenciaInicial: values.frequency,
    dataInicial: values.main_transaction.event_date,
    parcelasIniciais: values.child_transactions,
    onChangeFormValid,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [values.child_transactions]);

  useEffect(() => {
    if (!isMounted && !isEditing) {
      onCreateDefaultParcelas();

      setIsMounted(true);
    }

    if (!isMounted && isEditing && values.frequency_main_id) {
      setIsLoadingInstalments(true);

      onFindInstalmentsTransactions(values.frequency_main_id, (found) => {
        onCreateExistingParcelas(found);

        setIsLoadingInstalments(false);
        setIsMounted(true);
      });

      setIsMounted(true);
    }
  }, [
    isMounted,
    isEditing,
    values,
    onFindInstalmentsTransactions,
    onCreateDefaultParcelas,
    onCreateExistingParcelas,
  ]);

  useEffect(() => {
    if (isMounted) {
      setFieldValue('child_transactions', parcelasCalculadas);
    }
  }, [isMounted, setFieldValue, parcelasCalculadas]);

  const handleChangeFrequencia = useCallback(({ value: nova_frequencia }) => {
    setFieldValue('frequency', nova_frequencia);
    onChangeFrequencia({ value: nova_frequencia });
  }, [setFieldValue, onChangeFrequencia]);

  if (isLoadingInstalments) {
    return (
      <Row className="mt-3 mb-4">
        <Col>
          <LoadingIcon text="Carregando parcelas" />
        </Col>
      </Row>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <ReactTooltip />
        <Form.Group as={Col} md="3" xs="6">
          <Dropdown>
            <StyledButton variant="link" as={Dropdown.Toggle} className="pl-0 pb-0">
              {tipoValorDescricao}
              <TiArrowSortedDown size="1.1em" className="ml-1" />
            </StyledButton>
            <StyledDropdownMenu>
              <Dropdown.Item onClick={() => { onChangeTipoValor('TOTAL_AMOUNT'); }}>
                <Option>Valor total</Option>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onChangeTipoValor('INSTALMENT_AMOUNT'); }}>
                <Option>Valor de parcela</Option>
              </Dropdown.Item>
            </StyledDropdownMenu>
          </Dropdown>
          <CurrencyInput
            name="amount"
            placeholder={tipoValorAjuda}
            autoComplete="off"
            value={valorTotal}
            onChange={onChangeValor}
            className="form-control"
          />
        </Form.Group>
        <Form.Group as={Col} xs="6">
          <Form.Label>Número de parcelas</Form.Label>
          <Select
            name="instalment_count"
            options={INTALMENTS_OPTIONS}
            onChange={onChangeQtdeParcelas}
            value={INTALMENTS_OPTIONS.find(({ value }) => value === qtdeParcelas)}
            disabled={!qtdeParcelas || qtdeParcelas === 0}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label>Frequência</Form.Label>
          <Select
            name="frequency"
            options={TRANSACTION_FREQUENCY_OPTIONS}
            onChange={handleChangeFrequencia}
            value={TRANSACTION_FREQUENCY_OPTIONS.find(({ value }) => value === frequencia)}
          />
        </Form.Group>
      </Form.Row>
      {renderRecalcTotalWarning && (
        <Form.Row className="">
          <Form.Group as={Col} md="12">
            <small className="text-danger">
              A soma das parcelas não bate com o valor total.
            </small>
          </Form.Group>
        </Form.Row>
      )}
      <Table
        parcelasCalculadas={parcelasCalculadas}
        parcelasEditadasValor={parcelasEditadasValor}
        parcelasEditadasData={parcelasEditadasData}
        qtdeParcelas={qtdeParcelas}
        valorTotalCalculado={valorTotalCalculado}
        renderSumWarningFor={renderSumWarningFor}
        renderRecalcWarningFor={renderRecalcWarningFor}
        renderRecalcTotalWarning={renderRecalcTotalWarning}
        onChangeParcela={onChangeParcela}
        onDeleteParcela={onDeleteParcela}
        onClearDeletedParcelas={onClearDeletedParcelas}
        onRecalcularParcelas={onRecalcularParcelas}
        onRecalcularTotal={onRecalcularTotal}
        onSelectInstalmentForEdit={onSelectInstalmentForEdit}
      />
    </Form>
  );
}

InstalmentsForm.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onFindInstalmentsTransactions: PropTypes.func.isRequired,
  onChangeFormValid: PropTypes.func.isRequired,
  onSelectInstalmentForEdit: PropTypes.func.isRequired,
};

export default InstalmentsForm;
