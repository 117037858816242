/* eslint-disable consistent-return */
import { useMemo, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import asyncOperationsService from 'services/async-operations.service';
import confirmDialog from 'helpers/confirmDialog';

const useAsyncOperations = ({
  type,
  successTitle,
  successMessage,
  showConfirmation = true,
  redirectTo = null,
  downloadFile = false,
  onComplete = () => {},
  onError = () => {},
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const history = useHistory();

  const [asyncOperations, setAsyncOperations] = useState([]);

  const hasPending = useMemo(
    () => asyncOperations.some((operation) => ['pending', 'processing'].includes(operation.status)),
    [asyncOperations],
  );

  const pendingAsyncOperation = useMemo(
    () => asyncOperations.find((operation) => ['pending', 'processing'].includes(operation.status)) || {},
    [asyncOperations],
  );

  const startsDownload = (url) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', '');
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    if (!isMounted) {
      // Fetch initial async operation
      const fetchAsyncOperations = async (params) => {
        const { data } = await asyncOperationsService.fetchAsyncOperations(params);

        setAsyncOperations(data);
      };

      fetchAsyncOperations({ type });

      setIsMounted(true);
    }
  }, [isMounted, type]);

  useEffect(() => {
    if (isEmpty(asyncOperations) || !hasPending) {
      return;
    }

    const interval = setInterval(async () => {
      const { data } = await asyncOperationsService.fetchAsyncOperations({
        type,
      });

      const hadPending = asyncOperations.some(
        (operation) => ['pending', 'processing'].includes(operation.status),
      );
      const hasPendingOperations = data.some(
        (operation) => ['pending', 'processing'].includes(operation.status),
      );

      setAsyncOperations(data);

      if (hadPending && !hasPendingOperations) {
        const lastOperation = data[data.length - 1];
        const lastIsFailed = lastOperation.status === 'failed';

        if (lastIsFailed) {
          confirmDialog.open({
            icon: 'error',
            title: 'Atenção, houve um erro!',
            message: lastOperation.status_message,
            confirmButtonText: 'Confirmar',
            showCancelButton: false,
          });

          clearInterval(interval);

          if (onError) {
            onError(lastOperation);
          }

          return;
        }

        if (showConfirmation) {
          confirmDialog.open({
            icon: 'success',
            title: successTitle,
            message: successMessage,
            confirmButtonText: 'Confirmar',
            showCancelButton: false,
          });
        }

        if (downloadFile) {
          startsDownload(lastOperation.response.backup_url);
        }

        if (redirectTo) {
          history.push(redirectTo);
        }

        if (onComplete) {
          onComplete(lastOperation);
        }

        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [
    asyncOperations,
    successTitle,
    successMessage,
    redirectTo,
    history,
    hasPending,
    type,
    showConfirmation,
    downloadFile,
    onComplete,
    onError,
  ]);

  return {
    hasPending,
    pendingAsyncOperation,
    onSetAsyncOperations: setAsyncOperations,
  };
};

export default useAsyncOperations;
