import React, { useMemo } from 'react';
import has from 'lodash/has';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import round from 'lodash/round';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardBody,
  CardHeader,
  HintIcon,
} from '_components/_core';

import { CashflowOverview } from '_components/_shared';
import FORMATTERS from 'helpers/formatters';

function CashflowMonth({
  cashflow,
  accounts,
  selectedAccountIds,
  competence,
}) {
  const data = useMemo(() => {
    if (isEmpty(selectedAccountIds) || isEmpty(cashflow)) {
      return {};
    }

    let total_paid_incomes = 0;
    let total_income = 0;
    let total_paid_expenses = 0;
    let total_expense = 0;

    selectedAccountIds.forEach((accountId) => {
      if (!has(cashflow, accountId)) {
        return;
      }

      const accountCashflow = cashflow[accountId];

      if (!has(accountCashflow, competence)) {
        return;
      }

      const monthCashflow = accountCashflow[competence];

      total_paid_incomes += monthCashflow.total_paid_incomes;
      total_income += monthCashflow.total_income;
      total_paid_expenses += monthCashflow.total_paid_expenses;
      total_expense += monthCashflow.total_expense;
    });

    const income = round((total_paid_incomes / total_income) * 100);
    const expense = round((total_paid_expenses / total_expense) * 100);

    return {
      income,
      expense,
    };
  }, [cashflow, competence, selectedAccountIds]);

  const commonOptions = {
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      width: 110,
      height: 110,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        printButton: {
          enabled: false,
        },
        exportButton: {
          enabled: false,
        },
      },
    },
    title: {
      enabled: true,
      marginTop: 0,
      style: {
        color: '#404040',
        fontWeight: '500',
        fontFamily: 'Poppins',
        fontSize: '2em',
      },
      verticalAlign: 'middle',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        size: '100%',
        innerSize: '80%',
        slicedOffset: 0,
        borderWidth: 0,
      },
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const income_chart = {
    ...commonOptions,
    title: {
      ...commonOptions.title,
      text: FORMATTERS.PERCENTAGE(data.income),
    },
    series: [{
      type: 'pie',
      data: [
        {
          name: 'Atual',
          color: '#4CCEAC',
          y: data.income,
        },
        {
          name: 'Previsto',
          color: '#D4D7DC',
          y: data.income ? (100 - data.income) : 100,
        },
      ],
    }],
  };

  const expense_chart = {
    ...commonOptions,
    title: {
      ...commonOptions.title,
      text: FORMATTERS.PERCENTAGE(data.expense),
    },
    series: [{
      type: 'pie',
      data: [
        {
          name: 'Atual',
          color: '#DB504A',
          y: data.expense,
        },
        {
          name: 'Previsto',
          color: '#D4D7DC',
          y: data.expense ? (100 - data.expense) : 100,
        },
      ],
    }],
  };

  // eslint-disable-next-line no-unused-vars
  const dummy_chart = {
    ...commonOptions,
    title: {
      ...commonOptions.title,
      text: '65%',
    },
    series: [{
      type: 'pie',
      data: [
        {
          name: 'Atual',
          color: '#8D9096',
          y: 65,
        },
        {
          name: 'Previsto',
          y: 35,
          color: '#D4D7DC',
        },
      ],
    }],
  };

  const accountName = useMemo(() => {
    if (isEmpty(selectedAccountIds) || isEmpty(accounts)) {
      return '';
    }

    if (selectedAccountIds.length === 1) {
      const [accountId] = selectedAccountIds;

      const account = accounts.find((a) => a.id === accountId);

      return account ? account.description : '';
    }

    if (selectedAccountIds.length === accounts.length) {
      return 'Todas as contas';
    }

    const names = accounts
      .filter((a) => selectedAccountIds.includes(a.id))
      .map((a) => a.description);

    if (names.length > 5) {
      return `${names.slice(0, 5).join(', ')}...e mais ${names.length - 5} contas`;
    }

    return names.join(', ');
  }, [accounts, selectedAccountIds]);

  return (
    <Card>
      <CardHeader
        title="Previsto x Realizado"
        description={(
          <span className="text-muted">
            {accountName}
          </span>
        )}
        leftColSize={10}
        rightColSize={2}
        sideContent={(
          <HintIcon hint="Aqui você pode ver o quanto sua empresa já pagou e recebeu no mês, e o quanto ainda falta a pagar e receber." />
        )}
        adjustSideContentMobile={false}
      />
      <CardBody noPaddingTop className="pt-3">
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <HighchartsReact
              highcharts={Highcharts}
              options={income_chart}
            />
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <HighchartsReact
              highcharts={Highcharts}
              options={expense_chart}
            />
          </Col>
        </Row>
        <Row>
          <CashflowOverview
            account_ids={selectedAccountIds}
            competence={competence}
            total_paid_incomes_label="Realizado:"
            total_income_label="Previsto:"
            total_paid_expenses_label="Realizado:"
            total_expense_label="Previsto:"
            displayUnpaid
          />
        </Row>
      </CardBody>
    </Card>
  );
}

CashflowMonth.defaultProps = {
  cashflow: {},
  accounts: [],
  selectedAccountIds: [],
};

CashflowMonth.propTypes = {
  cashflow: PropTypes.object,
  accounts: PropTypes.array,
  selectedAccountIds: PropTypes.array,
  competence: PropTypes.string,
};

export default CashflowMonth;
