import { connect } from 'react-redux';

import MobileTransactionForm from './MobileTransactionForm';

const mapStateToProps = (state) => ({
  categories: state.companyTransactions.categories,
  recipients: state.companyTransactions.recipients,
  accounts: state.companyTransactions.accounts,
});

export default connect(mapStateToProps, null)(MobileTransactionForm);
