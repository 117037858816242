/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';

import useTransactionsHelpers from '../../../../../utilities/useTransactionsFormatters';
import {
  StyledShowOthersButton,
  StyledReportTableRow,
  StyledTransactionRow,
} from '../../../../../styles';

function Body({
  all_data,
  main_data,
  other_data,
  onNavigate,
  isShowingTransactions,
  onEditTransaction,
  values,
  name: report_name,
}) {
  const [showMore, setShowMore] = useState(false);

  const {
    getDescription,
    getDate,
    getPaidIcon,
  } = useTransactionsHelpers({ values });

  const DefaultRow = (item) => {
    const {
      description,
      amount,
      path,
      value,
      label,
    } = item;

    return (
      <StyledReportTableRow
        isClickable={path}
        onClick={() => onNavigate({
          path,
          value,
          label,
        })}
      >
        <td>
          <span>
            {description}
          </span>
        </td>
        <td className="text-right">
          {FORMATTERS.NUMBER(amount)}
        </td>
      </StyledReportTableRow>
    );
  };

  const ShowMoreRow = () => {
    let colSpan = showMore ? 2 : 0;

    if (isShowingTransactions) {
      colSpan = showMore ? 4 : 3;
    }

    return (
      <tr>
        <td colSpan={colSpan}>
          <StyledShowOthersButton variant="link" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Mostrar menos' : 'Mostrar outros'}
          </StyledShowOthersButton>
        </td>
        {!showMore && (
          <td className="text-right">
            {FORMATTERS.NUMBER(other_data.reduce((acc, item) => acc + item.amount, 0))}
          </td>
        )}
      </tr>
    );
  };

  const DefaultBody = () => (
    <>
      {main_data.map((item) => <DefaultRow {...item} />)}
      {showMore && other_data.map((item) => <DefaultRow {...item} />)}
      {!isEmpty(other_data) && <ShowMoreRow />}
      <tr>
        <td>
          <strong>Total</strong>
        </td>
        <td className="text-right">
          <strong>
            {FORMATTERS.NUMBER(all_data.reduce((acc, item) => acc + item.amount, 0))}
          </strong>
        </td>
      </tr>
    </>
  );

  const TransactionRow = (item) => {
    const {
      transaction,
    } = item;
    const {
      recipient,
      category,
      amount,
    } = transaction || {};

    const isByRecipientReport = ['incomes_by_recipient', 'expenses_by_recipient'].includes(report_name);

    const categoryOrRecipient = isByRecipientReport ? category?.description : recipient?.name;

    return (
      <StyledTransactionRow onClick={() => onEditTransaction(transaction)}>
        <td className="text-left">
          <span>
            {getPaidIcon(transaction)}
            {getDate(transaction)}
          </span>
        </td>
        <td className="text-left">
          <span>
            {getDescription(transaction)}
          </span>
        </td>
        <td className="text-left">
          <span>
            {categoryOrRecipient}
          </span>
        </td>
        <td className="text-right">
          <span>
            {FORMATTERS.NUMBER(amount)}
          </span>
        </td>
      </StyledTransactionRow>
    );
  };

  const TransactionsBody = () => (
    <>
      {main_data.map((item) => <TransactionRow {...item} />)}
      {showMore && other_data.map((item) => <TransactionRow {...item} />)}
      {!isEmpty(other_data) && <ShowMoreRow />}
      <tr>
        <td colSpan={3}>
          <strong>Total</strong>
        </td>
        <td className="text-right">
          <strong>
            {FORMATTERS.NUMBER(all_data.reduce((acc, item) => acc + item.amount, 0))}
          </strong>
        </td>
      </tr>
    </>
  );

  if (isShowingTransactions) {
    return <TransactionsBody />;
  }

  return <DefaultBody />;
}

Body.defaultProps = {
  all_data: [],
  main_data: [],
  other_data: [],
  isShowingTransactions: false,
};

Body.propTypes = {
  all_data: PropTypes.array,
  main_data: PropTypes.array,
  other_data: PropTypes.array,
  onNavigate: PropTypes.func.isRequired,
  isShowingTransactions: PropTypes.bool,
};

export default Body;
