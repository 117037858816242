/* eslint-disable no-prototype-builtins */
const groupByYear = (all) => {
  const grouped = all.reduce((finalObject, resultLine) => {
    const { competence } = resultLine;
    const year = competence.split('/')[1];

    if (finalObject.hasOwnProperty(year)) {
      finalObject[year].push(resultLine);
    } else {
      finalObject[year] = [resultLine];
    }

    return finalObject;
  }, {});

  return grouped;
};

export default groupByYear;
