import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import {
  TRANSACTION_TYPE_SUB_TYPE_OPTIONS,
  typeSubtypeStringToObject,
  getChangedValues,
} from 'helpers';

import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import { FloatingCard, Button, Tabs } from '_components/_core';

import FORMATTERS from 'helpers/formatters';
import { LoadingIcon } from '..';
import { TransactionDetails, TransactionFiles } from './components';
import { FormSchema } from './utilities';
import { StyledButton } from './styles';

function TransactionForm({
  isOpen,
  isEditing,
  isCreating,
  transaction: oldTransaction,
  disabledFields,
  hiddenFields,
  availableTypeSubTypes,
  mode,
  title,
  selectedDate,
  side,
  transaction_id,
  onToggleForm,
  onCreateTransaction,
  onEditTransaction,
  onBeforeSaveCallback,
  onAfterSaveCallback,
  onAfterSaveCallbackWithReset,
  onDeleteTransaction,
  onFetchTransaction,
  onFilesUpload,
  onAfterSaveSplitCallback,
}) {
  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('MAIN');

  useEffect(() => {
    if (!isOpen) {
      setActiveTab('MAIN');
    }
  }, [isOpen]);

  const [newTransaction, setNewTransaction] = useState({});
  const [tempFiles, setTempFiles] = useState([]);

  const transaction = useMemo(() => {
    if (transaction_id) {
      return newTransaction;
    }

    return oldTransaction;
  }, [transaction_id, newTransaction, oldTransaction]);

  const isRecurring = useMemo(() => transaction.payment_plan === 'RECURRING', [transaction]);

  const canDelete = useMemo(() => {
    const { type, sub_type } = transaction || {};

    const permissionMap = {
      'INCOME-null': 'aba_recebimento_delete',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_delete',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_delete',
      'EXPENSE-PEOPLE': 'aba_pessoal_delete',
      'EXPENSE-TAXES': 'aba_imposto_delete',
      'TRANSFER-SENT': 'aba_transferencia_delete',
      'TRANSFER-RECEIVED': 'aba_transferencia_delete',
    };

    const permissionName = permissionMap[`${type}-${sub_type}`];

    const allowed = hasPermissions({
      permissions: [permissionName],
      userPermissions,
      type: 'all',
    });

    return allowed;
  }, [
    transaction,
    userPermissions,
  ]);

  const initialValues = useMemo(() => ({
    id: transaction.id,
    event_date: transaction.event_date,
    description: transaction.description,
    recipient_id: transaction.recipient_id,
    category_id: transaction.category_id,
    amount: transaction.amount,
    paid: transaction.paid,
    due_date: transaction.due_date,
    payment_form: transaction.payment_form,
    document_number: transaction.document_number,
    cost_center_id: transaction.cost_center_id,
    tag_ids: !isEmpty(transaction.tags) ? transaction.tags.map((tag) => tag.id) : [],
    account_id: transaction.account_id,
    type: transaction.type,
    sub_type: transaction.sub_type,
    comment: transaction.comment,
    type_sub_type: `${transaction.type === 'INCOME' ? 'INCOME::' : `${transaction.type}::${transaction.sub_type}`}`,
    payment_plan: transaction.payment_plan,
    frequency_main_id: transaction.frequency_main_id,
    frequency_number: transaction.frequency_number,
    frequency_total: transaction.frequency_total,
    frequency_type: transaction.frequency_type,
    show_recurring_form: isRecurring,
    is_instalment: transaction.payment_plan === 'INSTALMENT',
    split: transaction.split,
    split_id: transaction.split_id,
    splits: transaction.splits,
  }), [transaction, isRecurring]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!transaction_id) {
      return;
    }

    setIsLoading(true);

    onFetchTransaction(transaction_id, (found_transaction) => {
      setNewTransaction(found_transaction);

      setIsLoading(false);
    });
  }, [transaction_id, onFetchTransaction, isOpen]);

  const handleSubmitTransaction = useCallback((values, { resetForm }) => {
    if (values.type_sub_type) {
      const { type, sub_type } = typeSubtypeStringToObject(values.type_sub_type);

      values.type = type;
      values.sub_type = sub_type;
      values.temp_type = type;
      values.temp_sub_type = sub_type;
    }

    delete values.type_sub_type;
    delete values.show_split_modal;

    const changedValues = getChangedValues(values, initialValues);

    const formattedValues = {
      ...values,
      changedValues,
      selectedDate,
    };

    if (onBeforeSaveCallback) {
      onBeforeSaveCallback(formattedValues);
    }

    if (formattedValues.id) {
      onEditTransaction(formattedValues.id, formattedValues, (updated_transaction) => {
        if (onAfterSaveCallback) {
          onAfterSaveCallback(updated_transaction);
        }

        if (onAfterSaveCallbackWithReset) {
          onAfterSaveCallbackWithReset(updated_transaction);

          resetForm();
          onToggleForm();
        }

        if (!onBeforeSaveCallback && !onAfterSaveCallback) {
          resetForm();
          onToggleForm();
        }
      });
    } else {
      onCreateTransaction(formattedValues, (created_transaction) => {
        if (!isEmpty(tempFiles)) {
          const formData = new FormData();

          tempFiles.forEach((file) => {
            formData.append('receipt', file);
          });

          onFilesUpload(formData, created_transaction.id);
        }

        if (onAfterSaveCallback) {
          onAfterSaveCallback(created_transaction);
        }

        if (onAfterSaveCallbackWithReset) {
          onAfterSaveCallbackWithReset(created_transaction);

          resetForm();
          onToggleForm();
        }

        if (!onBeforeSaveCallback && !onAfterSaveCallback) {
          resetForm();
          onToggleForm();
        }
      });
    }
  }, [
    onCreateTransaction,
    onEditTransaction,
    onBeforeSaveCallback,
    onAfterSaveCallback,
    onAfterSaveCallbackWithReset,
    onToggleForm,
    initialValues,
    selectedDate,
    tempFiles,
    onFilesUpload,
  ]);

  const handleDeleteTransaction = useCallback(() => {
    onDeleteTransaction(transaction, () => {
      if (onAfterSaveCallback) {
        onAfterSaveCallback();
        return;
      }

      onToggleForm();
    });
  }, [onDeleteTransaction, onAfterSaveCallback, onToggleForm, transaction]);

  const renderFooter = useCallback((handleSubmit, isValid) => (
    <>
      <div className="d-flex">
        <StyledButton
          variant="success"
          className="mr-2 flex-fill"
          isLoading={isEditing || isCreating || isLoading}
          disabled={!isValid}
          onClick={handleSubmit}
          type="submit"
        >
          Salvar Alterações
        </StyledButton>
        <Button
          className="flex-fill"
          variant="inverse-dark"
          onClick={onToggleForm}
        >
          Cancelar
        </Button>
      </div>
    </>
  ), [onToggleForm, isEditing, isCreating, isLoading]);

  const renderDeleteButton = useCallback(() => {
    if (!canDelete) {
      return null;
    }

    return (
      <div className="d-flex align-items-center justify-content-between mb-1 mt-1">
        <small className="ml-3 text-muted">
          {transaction && transaction.id && (
            `Criado em: ${FORMATTERS.DATE_DDMMYYYYHHMMSS(transaction.created_at)}`
          )}
          {transaction && !transaction.id && 'Este item ainda não foi salvo.'}
        </small>
        {transaction && transaction.id && (
          <Button
            variant="link"
            className="text-danger"
            type="submit"
            onClick={handleDeleteTransaction}
          >
            Excluir
          </Button>
        )}
      </div>
    );
  }, [handleDeleteTransaction, transaction, canDelete]);

  const handleFilesSelected = useCallback((formData) => {
    setTempFiles(formData);
  }, []);

  const handleSelectNewTransaction = useCallback((newTransactionId) => {
    setIsLoading(true);

    onFetchTransaction(newTransactionId, (found_transaction) => {
      setNewTransaction(found_transaction);

      setIsLoading(false);
    });
  }, [onFetchTransaction]);

  const handleAfterSaveSplitCallback = useCallback(() => {
    if (!transaction) {
      return;
    }

    setIsLoading(true);

    onFetchTransaction(transaction.id, (found_transaction) => {
      setNewTransaction(found_transaction);

      setIsLoading(false);
      onAfterSaveSplitCallback();
    });
  }, [transaction, onFetchTransaction, onAfterSaveSplitCallback]);

  const getTabs = useCallback(({
    handleSubmit,
    setFieldValue,
    values,
  }) => {
    const props = {
      disabledFields,
      hiddenFields,
      availableTypeSubTypes,
      mode,
      transaction,
      handleSubmit,
      setFieldValue,
      values,
      onFilesSelected: handleFilesSelected,
      onAfterSaveSplitCallback: handleAfterSaveSplitCallback,
      onSelectNewTransaction: handleSelectNewTransaction,
    };

    const tabs = [
      {
        id: 'MAIN',
        title: 'Detalhes',
        content: activeTab === 'MAIN' && <TransactionDetails {...props} />,

      },
    ];

    if (transaction.type !== 'TRANSFER') {
      tabs.push(
        {
          id: 'FILES',
          title: 'Arquivos',
          content: activeTab === 'FILES' && <TransactionFiles {...props} tempFiles={tempFiles} />,
        },
      );
    }

    return tabs;
  }, [
    activeTab,
    transaction,
    disabledFields,
    hiddenFields,
    availableTypeSubTypes,
    mode,
    handleFilesSelected,
    tempFiles,
    handleSelectNewTransaction,
    handleAfterSaveSplitCallback,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitTransaction}
      validationSchema={FormSchema}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        isValid,
      }) => (
        <>
          <FloatingCard
            title={title}
            fullHeight
            isVisible={isOpen}
            onToggleVisibility={onToggleForm}
            beforeFooterContent={renderDeleteButton()}
            footerContent={renderFooter(handleSubmit, isValid)}
            side={side}
            className={isOpen ? 'transaction-form-open' : 'transaction-form-closed'}
            bodyClassName="p-0"
            withCloseButton
            blockEscapeKey={values.show_split_modal}
          >
            {isLoading && (
              <div className="h-100 d-flex justify-content-center">
                <LoadingIcon text="Aguarde ..." />
              </div>
            )}
            {!isLoading && (
              <>
                <Tabs
                  variant="secondary"
                  tabs={getTabs({
                    handleSubmit,
                    setFieldValue,
                    values,
                  })}
                  activeTab={activeTab}
                  onTabChange={(tab) => {
                    setActiveTab(tab);
                  }}
                />
              </>
            )}
          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

TransactionForm.defaultProps = {
  transaction: {},
  disabledFields: [],
  hiddenFields: [],
  onBeforeSaveCallback: null,
  onAfterSaveCallback: null,
  isEditing: false,
  isCreating: false,
  title: 'Editar Transação',
  side: 'left',
  accounts: [],
  tags: [],
  costsCenter: [],
  availableTypeSubTypes: TRANSACTION_TYPE_SUB_TYPE_OPTIONS,
  selectedDate: null,
  onAfterSaveCallbackWithReset: null,
  transaction_id: null,
};

TransactionForm.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  isCreating: PropTypes.bool,
  transaction: PropTypes.object,
  disabledFields: PropTypes.array,
  hiddenFields: PropTypes.array,
  recipients: PropTypes.array,
  side: PropTypes.string,
  accounts: PropTypes.array,
  tags: PropTypes.array,
  costsCenter: PropTypes.array,
  availableTypeSubTypes: PropTypes.array,
  onToggleForm: PropTypes.func.isRequired,
  onCreateTransaction: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  onBeforeSaveCallback: PropTypes.func,
  onAfterSaveCallback: PropTypes.func,
  onDeleteTransaction: PropTypes.func.isRequired,
  onFilesUpload: PropTypes.func.isRequired,
  mode: PropTypes.string,
  transaction_id: PropTypes.string,
  selectedDate: PropTypes.object,
  onAfterSaveCallbackWithReset: PropTypes.func,
  onFetchTransaction: PropTypes.func.isRequired,
  onAfterSaveSplitCallback: PropTypes.func.isRequired,
};

export default TransactionForm;
