import styled, { css } from 'styled-components';
import { darken } from 'polished';
import Button from 'react-bootstrap/Button';

export const Container = styled(Button)`

  &:focus {
    box-shadow: 0 0 0 2px #2684FF;
  }

  ${({ variant }) => variant === 'link' && css`
    &:focus {
      box-shadow: none;
    }
  `}

  ${({ size }) => size === 'sm' && css`
    font-size: .800rem !important;
  `}

  ${({ disabled }) => disabled && css`
    pointer-events: none !important;
    cursor: not-allowed !important;
  `}

  ${({ variant }) => variant === 'outline-success' && css`
    &:hover {
      color: #FFF;
    }
  `}

  ${({ variant }) => variant === 'default' && css`
    background-color: #f0f0f0;

    &:hover {
      background-color: ${darken(0.05, '#f0f0f0')};
    }

    &:active {
      background-color: ${darken(0.05, '#f0f0f0')};
    }

    &.active {
      background-color: ${darken(0.05, '#f0f0f0')};
    }
  `}

  ${({ variant }) => variant === 'icon' && css`
    background: none;
  `}

  ${({ variant }) => variant === 'success-2' && css`
    background-color: #2ecc71;
    color: #FFF;
    border: none;

    &:hover {
      background-color: #27ae60;
    }

    &:active {
      background-color: #169c49;
    }

    &:focus {
      outline: none;
    }
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 8px;

    ${({ iconPosition }) => iconPosition === 'left' && css`
      margin-left: 0px !important;
      margin-right: 8px !important;
    `}

    ${({ noMargin }) => noMargin && css`
      margin-left: 0px !important;
    `}
  }
`;
