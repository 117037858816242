/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';
import { IoMdPeople } from 'react-icons/io';
import {
  IoAttach,
  IoBookmark,
  IoPricetags,
  IoSwapHorizontalOutline,
  IoTrashBin,
} from 'react-icons/io5';
import { FaSitemap } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { TRANSACTION_TYPE_SUB_TYPE_OPTIONS, useMediaQuery } from 'helpers';
import { TransactionForm, ContactForm, PermissionsGate } from '_components/_shared';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import alertActions from '_store/_actions/alert.actions';
import {
  Card,
  Tabs,
  Tag,
  FastFilterSelect,
} from '_components/_core';
import { useTableV2 } from '_components/_core/Table/utils';
import { ItemsPerPage, Pagination } from '_components/_core/Table/components';

import { StyledTable, StyledFormCheck } from './styles';

import {
  TRANSACTIONS_DEFAULT_COLUMNS,
  TRANSACTIONS_TABLE_COLUMNS,
  CATEGORIES_TABLE_COLUMNS,
  CATEGORIES_DEFAULT_COLUMNS,
  CONTACTS_DEFAULT_COLUMNS,
  CONTACTS_TABLE_COLUMNS,
  COST_CENTERS_DEFAULT_COLUMNS,
  COST_CENTERS_TABLE_COLUMNS,
  FILES_TABLE_COLUMNS,
  FILES_DEFAULT_COLUMNS,
} from './utilities';

import { AdvancedSearch } from './components';
import Summary from './components/Summary/Summary';

pluralize.addIrregularRule('item', 'itens');

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const tableConfig = {
  defaultFilters: {
    searchTerm: '',
  },
  defaultSorting: { field: 'event_date', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Search({
  isSearching,
  activeCompany,
  onSearchResults,
  searchResults,
  onFetchAccounts,
  onFetchCategories,
  onFetchRecipients,
  onFetchTags,
  onFetchCostCenters,
  onClearSearchResults,
  onDeleteMultipleTransactions,
}) {
  const query = useQuery();
  const history = useHistory();

  const { isMobile, isTablet } = useMediaQuery();

  const [isMounted, setIsMounted] = useState(false);

  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [selectedContact, setSelectedContact] = useState({});

  const [advancedFilters, setAdvancedFilters] = useState({});
  const [considerTransfers, setConsiderTransfers] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [searchBy, setSearchBy] = useState('DESCRIPTION');
  const [tempSearchTerm, setTempSearchTerm] = useState(() => {
    const searchTerm = query.get('q');

    if (searchTerm) {
      return searchTerm;
    }

    return '';
  });
  const [searchTerm, setSearchTerm] = useState(() => {
    const searchTerm = query.get('q');

    if (searchTerm) {
      return searchTerm;
    }

    return '';
  });
  // eslint-disable-next-line no-unused-vars
  const [dateSearchTerm, setDateSearchTerm] = useState(null);

  const {
    sorting,
    pagination,
    selectedItems,
    onFilter,
    onSorting,
    onPageChange,
    onPageSizeChange,
    onItemSelected,
    onSelectAll,
    onClearSelectedItems,
  } = useTableV2({
    ...tableConfig,
    data: searchResults.transactions,
    onFetchData: onSearchResults,
    withSavedFilters: false,
    withInitialLoading: false,
  });

  useEffect(() => {
    const urlSearchTerm = query.get('q');

    if (!isMounted && urlSearchTerm) {
      onFilter({
        searchTerm: urlSearchTerm,
        searchBy: 'DESCRIPTION',
        ...advancedFilters,
      });

      setIsMounted(true);
    }
  }, [query, searchTerm, onFilter, isMounted, advancedFilters]);

  const handleSearch = useCallback(() => {
    window.history.replaceState({}, document.title, `${window.location.pathname}`);

    let finalSearchTerm = tempSearchTerm;

    if (searchBy === 'DATE' && dateSearchTerm) {
      finalSearchTerm = DateTime.fromJSDate(dateSearchTerm).toFormat('yyyy-MM-dd');
    }

    const params = {
      searchTerm: finalSearchTerm,
      searchBy,
      ...advancedFilters,
    };

    onClearSelectedItems();

    onFilter(params, () => {
      setSearchTerm(finalSearchTerm);
    });
  }, [
    onFilter,
    tempSearchTerm,
    dateSearchTerm,
    searchBy,
    advancedFilters,
    onClearSelectedItems,
  ]);

  useEffect(() => {
    onFetchAccounts();
    onFetchCategories();
    onFetchRecipients();
    onFetchTags();
    onFetchCostCenters();
  }, [
    onFetchAccounts,
    onFetchCategories,
    onFetchRecipients,
    onFetchTags,
    onFetchCostCenters,
    activeCompany,
  ]);

  useEffect(() => {
    onClearSearchResults();
  }, [activeCompany, onClearSearchResults]);

  const handleToggleForm = useCallback(() => {
    const newState = !isOpen;

    if (!newState) {
      setSelectedTransaction({});
    }

    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleToggleContactForm = useCallback(() => {
    const newState = !isOpenContact;

    if (!newState) {
      setSelectedContact({});
    }

    setIsOpenContact(!isOpenContact);
  }, [isOpenContact]);

  const avaialableTypeSubTypes = useMemo(() => {
    if (!selectedTransaction) {
      return TRANSACTION_TYPE_SUB_TYPE_OPTIONS;
    }

    return TRANSACTION_TYPE_SUB_TYPE_OPTIONS.filter((option) => option.value !== 'TRANSFER::');
  }, [selectedTransaction]);

  const handleFinishEditing = useCallback(() => {
    handleToggleForm();

    onClearSelectedItems();

    onFilter({
      searchTerm,
      searchBy,
      ...advancedFilters,
    }, () => {

    });
  }, [handleToggleForm, onFilter, searchTerm, searchBy, advancedFilters, onClearSelectedItems]);

  const handleFinishEditingSplit = useCallback(() => {
    onClearSelectedItems();

    onFilter({
      searchTerm,
      searchBy,
      ...advancedFilters,
    }, () => {

    });
  }, [onFilter, searchTerm, searchBy, advancedFilters, onClearSelectedItems]);

  const handleFinishEditingContact = useCallback(() => {
    handleToggleContactForm();

    onClearSelectedItems();

    onFilter({
      searchTerm,
      searchBy,
      ...advancedFilters,
    }, () => {

    });
  }, [handleToggleContactForm, onFilter, searchTerm, searchBy, advancedFilters, onClearSelectedItems]);

  const handleEditTransaction = useCallback((id, source = 'transactions') => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-SENT': 'aba_transferencia_edit',
      'TRANSFER-RECEIVED': 'aba_transferencia_edit',
    };

    if (source === 'transactions') {
      const transaction = searchResults.transactions.find(
        (transaction) => transaction.id === id,
      );

      const { type, sub_type } = transaction || {};

      const permissionName = permissionMap[`${type}-${sub_type}`];

      const allowed = hasPermissions({
        permissions: [permissionName],
        userPermissions,
        type: 'all',
      });

      if (!allowed) {
        dispatch(alertActions.error('Você não tem permissão para editar este lançamento.'));

        return;
      }

      setSelectedTransaction(transaction);
      setIsOpen(true);
    }

    if (source === 'transactionsWithTags') {
      const transaction = searchResults.transactionsWithTags.find(
        (transaction) => transaction.id === id,
      );

      const { type, sub_type } = transaction || {};

      const permissionName = permissionMap[`${type}-${sub_type}`];

      const allowed = hasPermissions({
        permissions: [permissionName],
        userPermissions,
        type: 'all',
      });

      if (!allowed) {
        dispatch(alertActions.error('Você não tem permissão para editar este lançamento.'));

        return;
      }

      setSelectedTransaction(transaction);
      setIsOpen(true);
    }

    if (source === 'transactionsWithFiles') {
      const file = searchResults.transactionsWithFiles.find(
        (file) => file.id === id,
      );

      const { transaction } = file || {};

      const { type, sub_type } = transaction || {};

      const permissionName = permissionMap[`${type}-${sub_type}`];

      const allowed = hasPermissions({
        permissions: [permissionName],
        userPermissions,
        type: 'all',
      });

      if (!allowed) {
        dispatch(alertActions.error('Você não tem permissão para editar este lançamento.'));

        return;
      }

      setSelectedTransaction(transaction);
      setIsOpen(true);
    }
  }, [searchResults, dispatch, userPermissions]);

  const handleEditPerson = useCallback((id) => {
    const foundContact = searchResults.contacts.find((contact) => contact.id === id);

    setSelectedContact(foundContact);
    setIsOpenContact(true);
  }, [searchResults]);

  const handleEditCategory = useCallback((id) => {
    const category = searchResults.categories.find(
      (category) => category.id === id,
    ) || {};

    history.push(`/categorias?category_id=${id}&type=${category.type}&sub_type=${category.sub_type}`);
  }, [history, searchResults]);

  const handleEditCostCenter = useCallback((id) => {
    history.push(`/centros-de-custo?cost_center_id=${id}`);
  }, [history]);

  const handleDeleteMultipleTransactions = useCallback(() => {
    const selectedTransactions = searchResults.transactions.filter(
      (transaction) => selectedItems.includes(transaction.id),
    );

    const hasChidTransactions = selectedTransactions.some((transaction) => transaction.split_id !== null);

    if (hasChidTransactions) {
      dispatch(alertActions.error('Não é possível excluir itens que fazem parte de uma divisão. Retire esses itens da seleção e tente novamente.'));

      return;
    }

    onDeleteMultipleTransactions({ ids: selectedItems }, () => {
      onClearSelectedItems();
      handleSearch();
    });
  }, [
    dispatch,
    selectedItems,
    handleSearch,
    searchResults,
    onClearSelectedItems,
    onDeleteMultipleTransactions,
  ]);

  const handleChangeTempSearchTerm = useCallback((value) => {
    setTempSearchTerm(value);
  }, []);

  const selectionSum = useMemo(() => {
    const selectedTransactions = searchResults.transactions.filter(
      (transaction) => selectedItems.includes(transaction.id),
    );

    const selectionSum = selectedTransactions.reduce((acc, transaction) => {
      switch (transaction.type) {
        case 'INCOME':
          acc.total_income += transaction.amount;
          break;
        case 'EXPENSE':
          acc.total_expenses += transaction.amount;
          break;
        case 'TRANSFER':
          if (transaction.sub_type === 'SENT') {
            acc.total_expenses += transaction.amount;
          }
          if (transaction.sub_type === 'RECEIVED') {
            acc.total_income += transaction.amount;
          }
          break;
        default:
          break;
      }

      acc.total_balance = acc.total_income - acc.total_expenses;
      acc.total_income_with_transfers = acc.total_income + acc.total_transfers_received;
      acc.total_expenses_with_transfers = acc.total_expenses + acc.total_transfers_sent;
      acc.total_balance_with_transfers = acc.total_income_with_transfers - acc.total_expenses_with_transfers;

      return acc;
    }, {
      total_income: 0,
      total_expenses: 0,
      total_balance: 0,
      total_income_with_transfers: 0,
      total_expenses_with_transfers: 0,
      total_balance_with_transfers: 0,
    });

    return selectionSum;
  }, [selectedItems, searchResults]);

  const Transactions = useCallback(() => {
    const firstTransaction = searchResults.transactions[0] || {};

    const total_income = firstTransaction.total_income || 0;
    const total_expenses = firstTransaction.total_expenses || 0;
    const total_balance = firstTransaction.total_income - firstTransaction.total_expenses || 0;

    const total_income_with_transfers = firstTransaction.total_income + firstTransaction.total_transfers_received || 0;
    const total_expenses_with_transfers = firstTransaction.total_expenses + firstTransaction.total_transfers_sent || 0;
    const total_balance_with_transfers = total_income_with_transfers - total_expenses_with_transfers || 0;

    const itemsText = selectedItems.length === 0
      ? `${firstTransaction.total} itens`
      : `${selectedItems.length} ${pluralize('item', selectedItems.length)} selecionado(s)`;

    const hasItemSelected = selectedItems.length > 0;

    return (
      <>
        <Card
          className="p-2 pl-3 pr-3"
          style={{
            position: 'sticky',
            top: '65px',
            zIndex: 980,
          }}
        >
          <Row>
            <Col
              xs={{ span: 6, order: 1 }}
              md={{ span: 4, order: 1 }}
              className="d-flex justify-content-start align-items-center"
            >
              <StyledFormCheck
                type="checkbox"
                className="no-print"
                checked={searchResults.transactions.length > 0 && (selectedItems.length === searchResults.transactions.length)}
                onClick={(e) => {
                  if (selectedItems.length === searchResults.transactions) {
                    onClearSelectedItems();
                  } else {
                    onSelectAll(e);
                  }
                }}
              />
              <p className="m-0 p-0 items-count" style={{ fontSize: '1.1em', fontWeight: '500' }}>
                {itemsText}
              </p>
            </Col>
            <Col
              xs={{ span: 12, order: 3 }}
              md={{ span: 4, order: 2 }}
              className="mt-4 mt-md-0"
            >
              <Summary
                considerTransfers={considerTransfers}
                total_income={hasItemSelected ? selectionSum.total_income : total_income}
                total_expenses={hasItemSelected ? selectionSum.total_expenses : total_expenses}
                total_balance={hasItemSelected ? selectionSum.total_balance : total_balance}
                total_income_with_transfers={hasItemSelected ? selectionSum.total_income_with_transfers : total_income_with_transfers}
                total_expenses_with_transfers={hasItemSelected ? selectionSum.total_expenses_with_transfers : total_expenses_with_transfers}
                total_balance_with_transfers={hasItemSelected ? selectionSum.total_balance_with_transfers : total_balance_with_transfers}
              />
            </Col>
            <Col
              xs={{ span: 6, order: 2 }}
              md={{ span: 4, order: 3 }}
              className="d-flex justify-content-end align-items-center"
            >
              {selectedItems.length === 0 && (
                <Form inline className="mt-0 no-print">
                  <StyledFormCheck
                    inline
                    id="consider_transfers"
                    label="Somar Transferências?"
                    type="checkbox"
                    checked={considerTransfers}
                    onClick={() => setConsiderTransfers(!considerTransfers)}
                  />
                </Form>
              )}
              {selectedItems.length > 0 && (
                <FastFilterSelect
                  triggerStyle={{
                    '&:hover': {
                      backgroundColor: '#ffd !important',
                    },
                  }}
                  menuStyle={{ minWidth: '280px' }}
                  label="Ações"
                  value={`${selectedItems.length} selecionados`}
                  options={[
                    {
                      icon: (
                        <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant="danger">
                          <IoTrashBin size="1.2em" />
                        </Tag>
                      ),
                      value: 'DELETE',
                      label: `Excluir ${selectedItems.length} ${pluralize('item', selectedItems.length)}`,
                    },
                  ]}
                  onChange={(value) => {
                    switch (value) {
                      case 'DELETE': {
                        handleDeleteMultipleTransactions();
                        break;
                      }
                      default:
                        break;
                    }
                  }}
                />
              )}
            </Col>
          </Row>
        </Card>
        <Row>
          <Col>
            <Card className={classNames({
              'mt-3': true,
              'table-responsive': isMobile || isTablet,
            })}
            >
              <StyledTable
                keyName="id"
                name="search-page-transactions-lookup-table"
                data={searchResults.transactions}
                columns={TRANSACTIONS_TABLE_COLUMNS}
                defaultColumns={TRANSACTIONS_DEFAULT_COLUMNS}
                sorting={sorting}
                selectedItems={selectedItems}
                onSorting={onSorting}
                onItemSelected={onItemSelected}
                onSelectAll={onSelectAll}
                onRowClick={(id) => handleEditTransaction(id, 'transactions')}
                onDeleteMultipleTransactions={handleDeleteMultipleTransactions}
                hover
              />
            </Card>
          </Col>
        </Row>
        <Row className="mt-3 mt-md-0">
          <Col xs={12} lg={7} className="d-flex justify-content-start">
            <ItemsPerPage
              itemsPerPage={pagination.itemsPerPage}
              onChange={onPageSizeChange}
              noMarginsOnTotals
              className="mr-3"
              total={searchResults.totals.transactions}
              totalBeingShown={searchResults.transactions.length}
              maxItemsPerPage={100}
            />
          </Col>
          <Col xs={12} lg={5} className="d-flex justify-content-end">
            <Pagination
              {...pagination}
              total={searchResults.totals.transactions}
              onPageChange={onPageChange}
            />
          </Col>
        </Row>
      </>
    );
  }, [
    searchResults,
    onPageSizeChange,
    onPageChange,
    pagination,
    handleEditTransaction,
    sorting,
    onSorting,
    onItemSelected,
    onSelectAll,
    selectedItems,
    handleDeleteMultipleTransactions,
    isMobile,
    isTablet,
    considerTransfers,
    selectionSum,
    onClearSelectedItems,
  ]);

  const TransactionsWithTags = useCallback(() => (
    <>
      <Card className="mt-3">
        <StyledTable
          keyName="id"
          name="search-page-transactions-with-tags-lookup-table"
          data={searchResults.transactionsWithTags}
          columns={TRANSACTIONS_TABLE_COLUMNS}
          onItemSelected={onItemSelected}
          onSelectAll={onSelectAll}
          defaultColumns={TRANSACTIONS_DEFAULT_COLUMNS}
          onRowClick={(id) => handleEditTransaction(id, 'transactionsWithTags')}
          hover
        />
      </Card>
    </>
  ), [searchResults, handleEditTransaction, onItemSelected, onSelectAll]);

  const TransactionsWithFiles = useCallback(() => (
    <>
      <Card className="mt-3">
        <StyledTable
          keyName="id"
          name="search-page-transactions-with-files-lookup-table"
          data={searchResults.transactionsWithFiles}
          columns={FILES_TABLE_COLUMNS}
          defaultColumns={FILES_DEFAULT_COLUMNS}
          onRowClick={(id) => handleEditTransaction(id, 'transactionsWithFiles')}
          hover
        />
      </Card>
    </>
  ), [searchResults, handleEditTransaction]);

  const Contacts = useCallback(() => (
    <Card>
      <StyledTable
        keyName="id"
        name="search-page-contacts-lookup-table"
        data={searchResults.contacts}
        columns={CONTACTS_TABLE_COLUMNS}
        defaultColumns={CONTACTS_DEFAULT_COLUMNS}
        onRowClick={handleEditPerson}
        hover
      />
    </Card>
  ), [searchResults, handleEditPerson]);

  const Categories = useCallback(() => (
    <Card>
      <StyledTable
        keyName="id"
        name="search-page-categories-lookup-table"
        data={searchResults.categories}
        columns={CATEGORIES_TABLE_COLUMNS}
        defaultColumns={CATEGORIES_DEFAULT_COLUMNS}
        onRowClick={handleEditCategory}
        hover
      />
    </Card>
  ), [searchResults, handleEditCategory]);

  const CostCenters = useCallback(() => (
    <Card>
      <StyledTable
        keyName="id"
        name="search-page-cost-centers-lookup-table"
        data={searchResults.costCenters}
        columns={COST_CENTERS_TABLE_COLUMNS}
        defaultColumns={COST_CENTERS_DEFAULT_COLUMNS}
        onRowClick={handleEditCostCenter}
        hover
      />
    </Card>
  ), [searchResults, handleEditCostCenter]);

  const getTabs = useCallback(() => {
    const final_tabs = [];

    if (!isEmpty(searchResults.transactions)) {
      final_tabs.push(
        {
          id: 'transactions',
          title: (
            <div>
              <span className="d-flex justify-content-center align-items-center">
                Movimentações
                <Tag variant="default" className="ml-3">
                  {searchResults.totals.transactions}
                </Tag>
              </span>
            </div>
          ),
          icon: <IoSwapHorizontalOutline className="mr-2" />,
          content: <Transactions />,
        },
      );
    }

    if (!isEmpty(searchResults.transactionsWithTags)) {
      final_tabs.push(
        {
          id: 'transactions_with_tags',
          title: (
            <div>
              <span className="d-flex justify-content-center align-items-center">
                {`Movimentações com a Tag "${searchTerm}"`}
                <Tag variant="default" className="ml-3">
                  {searchResults.totals.transactionsWithTags}
                </Tag>
              </span>
            </div>
          ),
          icon: <IoPricetags className="mr-3" />,
          content: <TransactionsWithTags />,
        },
      );
    }

    if (!isEmpty(searchResults.transactionsWithFiles)) {
      final_tabs.push(
        {
          id: 'transactions_with_files',
          title: (
            <div>
              <span className="d-flex justify-content-center align-items-center">
                {`Anexos com o termo "${searchTerm}"`}
                <Tag variant="default" className="ml-3">
                  {searchResults.totals.transactionsWithFiles}
                </Tag>
              </span>
            </div>
          ),
          icon: <IoAttach size="1.2em" className="mr-3" />,
          content: <TransactionsWithFiles />,
        },
      );
    }

    if (!isEmpty(searchResults.categories)) {
      final_tabs.push(
        {
          id: 'categories',
          title: (
            <div>
              <span className="d-flex justify-content-center align-items-center">
                Categorias
                <Tag variant="default" className="ml-3">
                  {searchResults.totals.categories}
                </Tag>
              </span>
            </div>
          ),
          icon: <IoBookmark className="mr-2" />,
          content: <Categories />,
        },
      );
    }

    if (!isEmpty(searchResults.contacts)) {
      final_tabs.push(
        {
          id: 'contacts',
          title: (
            <div>
              <span className="d-flex justify-content-center align-items-center">
                Contatos
                <Tag variant="default" className="ml-3">
                  {searchResults.totals.contacts}
                </Tag>
              </span>

            </div>
          ),
          icon: <IoMdPeople className="mr-2" />,
          content: <Contacts />,
        },
      );
    }

    if (!isEmpty(searchResults.costCenters)) {
      final_tabs.push(
        {
          id: 'costCenters',
          title: (
            <div>
              <span className="d-flex justify-content-center align-items-center">
                Centros de Custo
                <Tag variant="default" className="ml-3">
                  {searchResults.totals.costCenters}
                </Tag>
              </span>

            </div>
          ),
          icon: <FaSitemap className="mr-2" />,
          content: <CostCenters />,
        },
      );
    }

    return final_tabs;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, searchTerm, selectedItems, considerTransfers]);

  const handleSetAdvancedFilters = useCallback((values) => {
    setAdvancedFilters(values);
  }, []);

  const handleChangeDate = useCallback((value) => {
    if (searchBy === 'DATE') {
      setDateSearchTerm(value);

      const date = DateTime.fromJSDate(value);

      setTempSearchTerm(date.isValid ? date.toFormat('yyyy-MM-dd') : '');
    } else {
      setTempSearchTerm(value);
    }
  }, [searchBy]);

  const handleClearSearchTerm = useCallback(() => {
    setSearchTerm('');
    setTempSearchTerm('');
    setDateSearchTerm(null);
  }, []);

  return (
    <Container fluid className="content-wrapper">
      <AdvancedSearch
        searchBy={searchBy}
        dateSearchTerm={dateSearchTerm}
        onChangeSearchBy={setSearchBy}
        onChangeDate={handleChangeDate}
        onClearSearchTerm={handleClearSearchTerm}
        advancedFilters={advancedFilters}
        isSearching={isSearching}
        totalFound={searchResults.totals.total}
        tempSearchTerm={tempSearchTerm}
        onChangeTempSearchTerm={handleChangeTempSearchTerm}
        onSetAdvancedFilters={handleSetAdvancedFilters}
        onSearch={handleSearch}
      />
      <TransactionForm
        isOpen={isOpen}
        transaction={selectedTransaction}
        transaction_id={selectedTransaction ? selectedTransaction.id : null}
        onToggleForm={handleToggleForm}
        availableTypeSubTypes={avaialableTypeSubTypes}
        onAfterSaveCallback={handleFinishEditing}
        onAfterSaveSplitCallback={handleFinishEditingSplit}
        mode="report-edit"
      />
      <PermissionsGate permissions={['manager_contacts']} type="all">
        <ContactForm
          isOpen={isOpenContact}
          selectedContact={selectedContact}
          contact_id={selectedTransaction ? selectedContact.id : null}
          onToggleForm={handleToggleContactForm}
          onAfterSaveCallback={handleFinishEditingContact}
        />
      </PermissionsGate>
      <Row>
        <Col>
          <Tabs
            tabs={getTabs()}
            variant="secondary"
            className="no-print"
          />
        </Col>
      </Row>
      {!isSearching && searchResults.totals.total === 0 && (
        <Row className="mt-3">
          <Col>
            <div className="d-flex flex-column">
              <h4>
                Não encontramos nenhum resultado para sua busca.
              </h4>
              <p>
                Algumas dicas para melhorar sua busca:
              </p>
              <ul>
                <li>Verifique se a palavra está escrita corretamente.</li>
                <li>Tente palavras-chave diferentes.</li>
                <li>Tente palavras-chave mais genéricas.</li>
                <li>Verifique se os filtros aplicados estão corretos.</li>
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

Search.defaultProps = {
  searchResults: {},
  isSearching: false,
};

Search.propTypes = {
  onSearchResults: PropTypes.func.isRequired,
  searchResults: PropTypes.object,
  activeCompany: PropTypes.string,
  onFetchTags: PropTypes.func.isRequired,
  onFetchRecipients: PropTypes.func.isRequired,
  onFetchCategories: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
  onFetchCostCenters: PropTypes.func.isRequired,
  onClearSearchResults: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  costCenters: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  recipients: PropTypes.array.isRequired,
  isSearching: PropTypes.bool,
  onDeleteMultipleTransactions: PropTypes.func.isRequired,
};

export default Search;
