import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { ListGroup } from 'react-bootstrap';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';

import FORMATTERS from 'helpers/formatters';

import { StyledListGroupItem } from './styles';

function TransactionsList({
  transactions,
  emptyMessage,
  incomesTotalMessage,
  expensesTotalMessage,
  transfersTotalMessage,
  onTogglePaid,
  onEditTransaction,
  selectedItems,
}) {
  const totals = useMemo(() => {
    const incomes = transactions.filter((transaction) => transaction.type === 'INCOME');
    const expenses = transactions.filter((transaction) => transaction.type === 'EXPENSE');
    const transfers = transactions.filter((transaction) => transaction.type === 'TRANSFER');

    const totalIncomes = incomes.reduce((acc, curr) => acc + curr.amount, 0);
    const totalExpenses = expenses.reduce((acc, curr) => acc + curr.amount, 0);
    const totalTransfers = transfers.reduce((acc, curr) => acc + curr.amount, 0);

    return {
      incomes: totalIncomes,
      expenses: totalExpenses,
      transfers: totalTransfers,
    };
  }, [transactions]);

  const getItemDescription = useCallback((transaction) => {
    if (!transaction) {
      return null;
    }

    if (transaction.payment_plan === 'INSTALMENT') {
      return `${transaction.description} (${transaction.frequency_number}/${transaction.frequency_total})`;
    }

    return transaction.description;
  }, []);

  const handleEditTransaction = useCallback((transaction) => {
    onEditTransaction(transaction);
  }, [onEditTransaction]);

  if (isEmpty(transactions)) {
    return (
      <small className="no-transactions text-muted">
        {emptyMessage}
      </small>
    );
  }

  return (
    <>
      <ListGroup>
        {transactions.map((transaction) => {
          let classColor = '';

          const prefix = transaction.type === 'EXPENSE' ? 'para:' : 'de:';

          switch (transaction.type) {
            case 'INCOME':
              classColor = 'text-success';
              break;
            case 'EXPENSE':
              classColor = 'text-danger';
              break;
            case 'TRANSFER':
              classColor = 'text-info';
              break;
            default:
              classColor = '';
              break;
          }

          return (
            <StyledListGroupItem
              key={transaction.id}
              onClick={() => handleEditTransaction(transaction)}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              active={selectedItems.includes(transaction.id)}
            >
              <div className="d-flex flex-row align-items-center">
                {/* <div className="mr-3">
                  <StyledFormCheck
                    type="checkbox"
                    checked={selectedItems.includes(transaction.id)}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (selectedItems.includes(transaction.id)) {
                        onSetSelectedItems(selectedItems.filter((item) => item !== transaction.id));
                      } else {
                        onSetSelectedItems([...selectedItems, transaction.id]);
                      }
                    }}
                  />
                </div> */}
                <div>
                  <small className={!transaction.description && 'text-muted'}>
                    {getItemDescription(transaction) || 'Sem descrição'} - <strong className={classColor}>{`${FORMATTERS.NUMBER(transaction.amount)}`}</strong>
                  </small>
                  <br />
                  <small className="text-muted">
                    <small>
                      {FORMATTERS.DATE_DDMMYYYY(transaction.event_date)}
                    </small>
                    {transaction.type !== 'TRANSFER' && (
                      <small>
                        {`, ${prefix} ${transaction.recipient.name || 'Não informado'}`}
                      </small>
                    )}
                  </small>
                </div>

              </div>
              <div className="ml-auto d-flex align-items-center justify-content-center">
                {!transaction.paid && (
                  <BsToggleOff size="1.8em" className="text-muted" onClick={(e) => onTogglePaid(e, transaction)} />
                )}
                {transaction.paid && (
                  <BsToggleOn size="1.8em" className="text-success" onClick={(e) => onTogglePaid(e, transaction)} />
                )}
              </div>
            </StyledListGroupItem>
          );
        })}
      </ListGroup>
      <div className="mt-3 d-flex flex-column">
        {totals.incomes > 0 && (
          <small>
            {incomesTotalMessage}&nbsp;&nbsp;
            <strong>
              <span className="text-success">
                {FORMATTERS.NUMBER(totals.incomes)}
              </span>
            </strong>
          </small>
        )}
        {totals.expenses > 0 && (
          <>
            <small>
              {expensesTotalMessage}&nbsp;&nbsp;
              <strong>
                <span className="text-danger">
                  {FORMATTERS.NUMBER(totals.expenses)}
                </span>
              </strong>
            </small>
          </>
        )}
        {totals.transfers > 0 && (
          <>
            <small>
              {transfersTotalMessage}&nbsp;&nbsp;
              <strong>
                <span className="text-info">
                  {FORMATTERS.NUMBER(totals.transfers)}
                </span>
              </strong>
            </small>
          </>
        )}
      </div>
    </>
  );
}

TransactionsList.defaultProps = {
  transactions: [],
  emptyMessage: 'Nenhuma transação encontrada',
  incomesTotalMessage: 'Recebimentos do dia:',
  expensesTotalMessage: 'Despesas do dia:',
  transfersTotalMessage: 'Transferências do dia:',
  selectedItems: [],
};

TransactionsList.propTypes = {
  transactions: PropTypes.array,
  onEditTransaction: PropTypes.func,
  onTogglePaid: PropTypes.func,
  emptyMessage: PropTypes.string,
  incomesTotalMessage: PropTypes.string,
  expensesTotalMessage: PropTypes.string,
  transfersTotalMessage: PropTypes.string,
  selectedItems: PropTypes.array,
};

export default TransactionsList;
