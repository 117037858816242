import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { DateTime } from 'luxon';

import { DatePicker } from '_components/_core';
import FORMATTERS from 'helpers/formatters';

function FormDateField({
  placeholder,
  disabled,
  style,
  placement,
  innerRef,
  inputStyleProps,
  withPortal,
  allowClearWithPortal,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  const error = useMemo(
    () => ((meta.touched && meta.error) ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const handleChange = useCallback((value) => {
    if (value) {
      const luxonDate = DateTime.fromJSDate(value);

      if (!luxonDate.isValid) {
        helpers.setError('Data inválida');
        return;
      }

      helpers.setValue(luxonDate.toFormat('yyyy-MM-dd'));
    } else {
      helpers.setValue(value);
    }
  }, [helpers]);

  const value = useMemo(() => {
    if (typeof field.value === 'string') {
      return FORMATTERS.DATE_FROM_STRING_DATE(field.value);
    }

    if (field.value) {
      const formattedDate = DateTime.fromJSDate(field.value, { zone: 'utc' }).toFormat('yyyy-MM-dd');

      return FORMATTERS.DATE_FROM_STRING_DATE(formattedDate);
    }

    return field.value || null;
  }, [field]);

  return (
    <>
      <DatePicker
        placeholder={placeholder}
        onFocus={(e) => e.target.select()}
        selectedDate={value}
        isInvalid={!!error}
        onChange={handleChange}
        disabled={disabled}
        hideCalendar={false}
        style={style}
        placement={placement}
        innerRef={innerRef}
        inputStyleProps={inputStyleProps}
        withPortal={withPortal}
        allowClearWithPortal={allowClearWithPortal}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormDateField.defaultProps = {
  disabled: false,
  style: null,
  placement: 'bottom-start',
  innerRef: null,
  inputStyleProps: {},
  withPortal: false,
  allowClearWithPortal: false,
};

FormDateField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  placement: PropTypes.string,
  innerRef: PropTypes.object,
  inputStyleProps: PropTypes.object,
  withPortal: PropTypes.bool,
  allowClearWithPortal: PropTypes.bool,
};

export default FormDateField;
