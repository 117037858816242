/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime, Settings } from 'luxon';
import { FaCheckCircle, FaChevronDown, FaExclamationCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { MdFormatListBulleted } from 'react-icons/md';
import Switch from 'react-switch';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';

import { Button } from '_components/_core';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import { StyledFormCheck } from '../styles';
import { StyledRow } from './styles';
import useTransactions from '../utilities/useTransactions';
import TransactionRowForm from './TransactionRowForm/TransactionRowFormContainer';

Settings.defaultZoneName = 'America/Sao_Paulo';

function TransactionRow({
  type,
  subType,
  transaction,
  transactionFormRef,
  selectedItems,
  viewType,
  preferences,
  onItemSelected,
  onTransactionContextMenu,
  onUpdateMultipleTransactions,
  onEditSplitTransaction,
}) {
  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  const {
    accounts,
    selectedAccountIds,
    duplicatedTransactionIds,
    selected_account_id,
    selectedDate,
    onToggleTransactionPaid,
    onCreateUpdateTransaction,
  } = useTransactions();

  const [isEditing, setIsEditing] = useState(false);
  const [fieldName, setFieldName] = useState(null);

  const hasEditPermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-null': 'aba_transferencia_edit',
    };

    const permissionName = permissionMap[`${type}-${subType}`];

    return hasPermissions({
      permissions: [permissionName],
      userPermissions,
      type: 'all',
    });
  }, [type, subType, userPermissions]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setFieldName(null);
  }, []);

  const handleEditTransaction = useCallback((fieldName) => {
    if (transactionFormRef.current) {
      transactionFormRef.current.handleSubmit();
    }

    if (!hasEditPermission) {
      return;
    }

    setIsEditing(true);
    setFieldName(fieldName);
  }, [hasEditPermission, transactionFormRef]);

  const isPastDue = useMemo(() => {
    const { year, month, day } = DateTime.now();

    const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });

    if (transactionDate.year < year) {
      return true;
    }

    if (transactionDate.year === year && transactionDate.month < month) {
      return true;
    }

    if (transactionDate.year === year
      && transactionDate.month === month
       && transactionDate.day < day) {
      return true;
    }

    return false;
  }, [transaction]);

  const isDueToday = useMemo(() => {
    const { year, month, day } = DateTime.now();

    const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });

    if (transactionDate.year < year) {
      return true;
    }

    if (transactionDate.year === year && transactionDate.month < month) {
      return true;
    }

    if (transactionDate.year === year
      && transactionDate.month === month
       && transactionDate.day === day) {
      return true;
    }

    return false;
  }, [transaction]);

  const isSelected = useMemo(
    () => selectedItems.includes(transaction.id),
    [selectedItems, transaction],
  );

  const Icon = useCallback(() => {
    if (isPastDue && !transaction.paid) {
      return (
        <span>
          <FaExclamationCircle className="text-danger mb-0" data-place="bottom" data-tip="Esta movimentação está com o pagamento atrasado." />
        </span>
      );
    }

    if (isDueToday && !transaction.paid) {
      return (
        <span>
          <FaExclamationCircle className="text-yellow mb-0" data-place="bottom" data-tip="Esta movimentação vence hoje." />
        </span>
      );
    }

    if (transaction.paid) {
      return (
        <span>
          <FaCheckCircle className="text-success" data-place="bottom" data-tip="Esta movimentação foi efetivada." />
        </span>
      );
    }

    return null;
  }, [transaction, isPastDue, isDueToday]);

  const renderDescription = useCallback(() => {
    if (selectedAccountIds.length > 1) {
      return FORMATTERS.TRANSACTION_DESCRIPTION(transaction, viewType, true, !hasEditPermission);
    }

    return FORMATTERS.TRANSACTION_DESCRIPTION(transaction, viewType, false, !hasEditPermission);
  }, [transaction, selectedAccountIds, viewType, hasEditPermission]);

  const handleContextMenu = (e, type = 'normal') => {
    e.preventDefault();

    onTransactionContextMenu(e, transaction, type);
  };

  const handleEditSplitTransaction = useCallback(() => {
    if (!hasEditPermission) {
      return;
    }

    onEditSplitTransaction(transaction);
  }, [transaction, onEditSplitTransaction, hasEditPermission]);

  const handleToggleTransactionPaid = useCallback(() => {
    if (!hasEditPermission) {
      return;
    }

    const isPaid = !transaction.paid;

    onToggleTransactionPaid(transaction.id, isPaid, () => {
      const { show_old_items_payment_confirmation } = preferences || {};
      const isSameDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' }).toFormat('yyyy-MM-dd') === DateTime.now().toFormat('yyyy-MM-dd');

      if (isPaid && !isSameDate && show_old_items_payment_confirmation) {
        confirmDialog.open({
          title: 'Atualizar Data de Pagamento?',
          message: 'Você gostaria que o Zenply atualizasse a Data de Pagamento deste item para o dia de hoje? <br /><br /> <small>Desative esse alerta no menu Configurações -> Preferências</small>',
          confirmButtonText: 'SIM, atualize!',
          cancelButtonText: 'NÃO, deixe como está!',
          onConfirm: () => {
            onUpdateMultipleTransactions({
              ids: [transaction.id],
              event_date: DateTime.now().toFormat('yyyy-MM-dd'),
            });
          },
          onCancel: () => {},
        });
      }
    });
  }, [
    onToggleTransactionPaid,
    onUpdateMultipleTransactions,
    transaction,
    preferences,
    hasEditPermission,
  ]);

  if (isEditing) {
    return (
      <TransactionRowForm
        key={`form-row-${transaction.id}`}
        type={type}
        subType={subType}
        transaction={transaction}
        transactionFormRef={transactionFormRef}
        selectedDate={selectedDate}
        selectedFieldName={fieldName}
        selected_account_id={selected_account_id}
        onSubmit={onCreateUpdateTransaction}
        onCancelEditTransaction={handleCancelEdit}
        onEditSplitTransaction={onEditSplitTransaction}
      />
    );
  }

  return (
    <StyledRow
      name="transaction-row-normal"
      onContextMenu={handleContextMenu}
      className={classNames({
        'text-center': true,
        active: isSelected,
        duplicated: duplicatedTransactionIds.includes(transaction.id),
        disabled: !hasEditPermission,
      })}
      viewType={viewType}
      id={transaction.id}
    >
      <td className="checkbox-column">
        <StyledFormCheck
          type="checkbox"
          checked={selectedItems.includes(transaction.id)}
          onClick={() => onItemSelected(transaction.id)}
        />
      </td>
      <td className="event-date-column main" onClick={() => handleEditTransaction('event_date')}>
        {FORMATTERS.DATE_DDMM(transaction.event_date)}
        <Icon />
      </td>
      <td className="description-column" onClick={() => handleEditTransaction('description')}>
        <div className="w-100">
          {renderDescription()}
        </div>
      </td>
      {type !== 'TRANSFER' && (
        <td className="recipient-column" onClick={() => handleEditTransaction('recipient')}>
          {FORMATTERS.TRANSACTION_RECIPIENT(transaction.recipient, transaction.split)}
        </td>
      )}
      <td className="amount-column main" onClick={() => handleEditTransaction('amount')}>
        {FORMATTERS.TRANSACTION_AMOUNT(transaction)}
      </td>
      {type !== 'TRANSFER' && (
        <td className="category-column" onClick={() => handleEditTransaction('category')}>
          {FORMATTERS.TRANSACTION_CATEGORY(transaction.category, transaction.split)}
        </td>
      )}
      {type !== 'TRANSFER' && (
        <td className="payment_plan-column" onClick={() => handleEditTransaction('payment_plan')}>
          {FORMATTERS.TRANSACTION_PAYMENT_PLAN(transaction.payment_plan)}
        </td>
      )}
      {type === 'TRANSFER' && (
        <td className="account_id_origin-column" onClick={() => handleEditTransaction('account_id_origin')}>
          {FORMATTERS.TRANSACTION_TRANSFER_ORIGIN(accounts, transaction)}
        </td>
      )}
      {type === 'TRANSFER' && (
        <td className="account_id_destination-column" onClick={() => handleEditTransaction('account_id_destination')}>
          {FORMATTERS.TRANSACTION_TRANSFER_DESTINATION(accounts, transaction)}
        </td>
      )}
      <td className="paid-column">
        {!transaction.split && (
          <div className="d-flex justify-content-center align-items-center">
            <Switch
              name="paid"
              onChange={handleToggleTransactionPaid}
              checked={transaction.paid || false}
              disabled={false}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#00ab6f"
              offColor="#d4d7dc"
              onHandleColor="#fff"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              handleDiameter={22}
              height={18}
              width={44}
            />
          </div>
        )}
        {transaction.split && (
          <span className="d-flex justify-content-center align-items-center">
            <MdFormatListBulleted
              onClick={handleEditSplitTransaction}
              size="1.5em"
              className="text-muted"
            />
          </span>
        )}
      </td>
      <td className="action-column">
        <Button
          variant="link"
          className="m-0 p-0"
          onClick={(e) => handleContextMenu(e, 'different')}
        >
          <FaChevronDown size="1.1em" className="text-muted" />
        </Button>
      </td>
    </StyledRow>
  );
}

TransactionRow.defaultProps = {
  preferences: {},
};

TransactionRow.propTypes = {
  type: PropTypes.string.isRequired,
  subType: PropTypes.string,
  viewType: PropTypes.string.isRequired,
  selectedItems: PropTypes.array,
  transaction: PropTypes.object,
  preferences: PropTypes.object,
  onItemSelected: PropTypes.func.isRequired,
  onTransactionContextMenu: PropTypes.func.isRequired,
  transactionFormRef: PropTypes.object.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onEditSplitTransaction: PropTypes.func.isRequired,
};

export default React.memo(TransactionRow);
