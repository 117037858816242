/* eslint-disable react/no-unescaped-entities */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PageHeader, Menu } from '_components/_core';

import { SearchPDFInvoices, SearchInvoicesConfiguration } from './components';
import { MenuCategory } from './styles';

function Automations({ onFetchAutomationSettings }) {
  const location = useLocation();

  const tabs = useMemo(() => ({
    search_pdf_invoices: () => (<SearchPDFInvoices />),
    search_invoices: () => (<SearchInvoicesConfiguration />),
  }), []);

  const [activeTab, setActiveTab] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const tab = urlParams.get('tab');

    if (tab && Object.keys(tabs).includes(tab)) {
      return tab;
    }

    return 'search_invoices';
  });

  const renderActiveTab = useCallback(() => {
    const ActiveTab = tabs[activeTab];

    return <ActiveTab />;
  }, [tabs, activeTab]);

  useEffect(() => {
    onFetchAutomationSettings();
  }, [onFetchAutomationSettings]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Automações"
        description="Economize horas de trabalho manual utilizando as automações disponíveis no Contabills"
      />
      <Tab.Container defaultActiveKey={activeTab}>
        <Row>
          <Col sm={3}>
            <Menu>
              <MenuCategory>
                Executar manualmente
              </MenuCategory>
              <ListGroup.Item as="button" action eventKey="search_pdf_invoices" onClick={() => setActiveTab('search_pdf_invoices')}>
                Busca de PDFs (NFS-e)
              </ListGroup.Item>
            </Menu>
            <br />
            <Menu>
              <MenuCategory>
                Configurar Automações
              </MenuCategory>
              <ListGroup.Item as="button" action eventKey="search_invoices" onClick={() => setActiveTab('search_invoices')}>
                Busca automática de NFS-e
              </ListGroup.Item>
            </Menu>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                {renderActiveTab(activeTab)}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

Automations.propTypes = {
  onFetchAutomationSettings: PropTypes.func.isRequired,
};

export default Automations;
