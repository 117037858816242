import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { TiArrowSortedDown } from 'react-icons/ti';

import { Button } from '_components/_core';

import {
  CustomFilterButton,
  FilterLabel,
  FilterMenu,
  FilterDescription,
  FilterValue,
  InputContainer,
} from '../styles';

const FastFilterTextReplace = ({
  id,
  label,
  value,
  onChange,
  className,
  description,
  renderDoubleDots,
  renderEmptyText,
  showValue,
}) => {
  const [open, setOpen] = useState(false);

  const [tempReplaceText, setTempReplaceText] = useState(null);
  const [tempReplaceWith, setTempReplaceWith] = useState('');

  const inputRef = React.useRef(null);

  const handleToggle = useCallback((isOpen, ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
  }, []);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [open]);

  const handleSubmit = useCallback(() => {
    onChange({
      replace_text: tempReplaceText,
      replace_with: tempReplaceWith,
    });

    setOpen(false);
  }, [onChange, tempReplaceText, tempReplaceWith]);

  return (
    <Dropdown
      id={id}
      show={open}
      onToggle={handleToggle}
      className={className}
    >
      <CustomFilterButton variant="link" as={Dropdown.Toggle}>
        <FilterLabel>
          {label}{renderDoubleDots ? ':' : ''}
        </FilterLabel>
        <FilterValue>
          {renderEmptyText && !value && <i className="text-muted">Não informado</i>}
          {showValue && value && `"${value}"`}
        </FilterValue>
        <TiArrowSortedDown size="1.1em" className="ml-1" />
      </CustomFilterButton>
      <FilterMenu>
        {description && (
          <FilterDescription>
            {description}
          </FilterDescription>
        )}
        <InputContainer>
          <Form.Control
            ref={inputRef}
            type="text"
            value={tempReplaceText}
            onChange={(e) => setTempReplaceText(e.target.value)}
            autoComplete="off"
            placeholder="Substitua o texto..."
            autoFocus
          />
          <small className="text-danger">
            Ou deixe em branco para alterar todos
          </small>
          <Form.Control
            type="text"
            value={tempReplaceWith}
            onChange={(e) => setTempReplaceWith(e.target.value)}
            autoComplete="off"
            placeholder="... pelo texto"
            className="mt-2"
          />
          <Button
            className="mt-3 mr-2"
            size="sm"
            variant="dark"
            onClick={handleSubmit}
          >
            Encontrar e Substituir
          </Button>
          <Button
            className="mt-3 float-right"
            size="sm"
            variant="link"
            onClick={() => {
              setTempReplaceText(null);
              setTempReplaceWith('');

              setOpen(false);
            }}
          >
            Limpar
          </Button>
        </InputContainer>
      </FilterMenu>
    </Dropdown>
  );
};

export default FastFilterTextReplace;

FastFilterTextReplace.defaultProps = {
  renderDoubleDots: true,
  renderEmptyText: true,
  showValue: true,
};

FastFilterTextReplace.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  renderDoubleDots: PropTypes.bool,
  renderEmptyText: PropTypes.bool,
  showValue: PropTypes.bool,
  id: PropTypes.string,
};
