import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import Dropdown from 'react-bootstrap/Dropdown';

import FORMATTERS from 'helpers/formatters';

import AccountItem from './components/AccountItem/AccountItem';
import SelectAllAccountsItem from './components/SelectAllAccountsItem/SelectAllAccountsItem';
import ManageBankAccountsItem from './components/ManageBankAccountsItem/ManagerBankAccountsItem';
import TriggerContent from './components/TriggerContent/TriggerContent';

import {
  Trigger,
  Content,
} from './styles';

function BankAccountSelect({
  value,
  onChange,
  accounts,
  isMultiple,
  displayBalance,
  balance,
  variant,
  displayPrincipalStar,
  shortenAccountNames,
  canViewBalances,
  placeholder,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = useCallback((open, ev, metadata) => {
    if (metadata.source === 'select') {
      setIsMenuOpen(true);
      return;
    }

    setIsMenuOpen(open);
  }, []);

  const handleAccountSelected = useCallback((account_id) => {
    if (isMultiple) {
      if (value.includes(account_id)) {
        const newValue = value.filter((id) => id !== account_id);

        onChange(newValue);
      } else {
        onChange([...value, account_id]);
      }

      return;
    }

    onChange([account_id]);
    setIsMenuOpen(false);
  }, [isMultiple, onChange, value]);

  const handleSelectAll = useCallback(() => {
    if (!isEmpty(value) && value.length === accounts.length) {
      onChange([]);
    } else {
      const accountIds = accounts.map((account) => account.id);

      onChange(accountIds);
      setIsMenuOpen(false);
    }
  }, [value, onChange, accounts]);

  const accountText = useMemo(() => {
    if (isEmpty(value)) {
      return placeholder;
    }

    if (value.length === 0) {
      return placeholder;
    }

    if (value.length === 1) {
      const found = accounts.find((account) => account.id === value[0]);
      const { description } = found || { };

      if (shortenAccountNames && description) {
        return FORMATTERS.MAX_X_CHARS(description, 18);
      }

      return description;
    }

    if (value.length === accounts.length) {
      return 'Todas as contas';
    }

    return `${value.length} contas selecionadas`;
  }, [value, accounts, placeholder, shortenAccountNames]);

  const calculatedBalance = useMemo(() => {
    if (isEmpty(value) || !displayBalance) {
      return 0;
    }

    if (value.length === 1) {
      const [account_id] = value;

      if (!has(balance, account_id)) {
        return 0;
      }

      return balance[account_id].balance;
    }

    if (value.length > 1) {
      const selectedAccounts = value.map((account_id) => balance[account_id]);

      const balanceSum = selectedAccounts.reduce(
        (acc, account = {}) => acc + account.balance, 0,
      );

      return balanceSum;
    }

    return 0;
  }, [balance, value, displayBalance]);

  const balanceClassName = useMemo(() => {
    if (calculatedBalance < 0) {
      return 'text-danger';
    }

    if (calculatedBalance === 0) {
      return 'text-muted';
    }

    return 'text-success';
  }, [calculatedBalance]);

  const isAllChecked = useMemo(() => {
    if (isEmpty(value)) {
      return false;
    }

    if (value.length === 0) {
      return false;
    }

    return accounts.every((account) => value.includes(account.id));
  }, [accounts, value]);

  return (
    <>
      <Dropdown show={isMenuOpen} onToggle={handleMenuToggle} drop="down" align="left">
        <Trigger className="d-flex" as={Dropdown.Toggle} variant={variant} size="sm">
          <TriggerContent
            variant={variant}
            displayBalance={displayBalance}
            accountText={accountText}
            balanceText={FORMATTERS.NUMBER(calculatedBalance)}
            balanceClassName={balanceClassName}
            canViewBalances={canViewBalances}
          />
        </Trigger>
        <Content>
          <SelectAllAccountsItem
            variant={variant}
            isAllChecked={isAllChecked}
            isMultiple={isMultiple}
            displayBalance={displayBalance}
            balance={balance}
            onSelectAllAccounts={handleSelectAll}
          />
          <hr className="mb-0 mt-0" />
          {accounts.map((account) => (
            <AccountItem
              key={account.id}
              selectedAccountIds={value}
              variant={variant}
              isMultiple={isMultiple}
              displayBalance={displayBalance}
              displayPrincipalStar={displayPrincipalStar}
              account={account}
              balance={balance}
              onAccountSelected={handleAccountSelected}
              shortenAccountNames={shortenAccountNames}
            />
          ))}
          <hr className="mb-0 mt-0" />
          <ManageBankAccountsItem />
        </Content>
      </Dropdown>
    </>
  );
}

BankAccountSelect.defaultProps = {
  variant: 'default',
  accounts: [],
  value: [],
  balance: {},
  isMultiple: true,
  displayBalance: false,
  displayPrincipalStar: false,
  shortenAccountNames: false,
  canViewBalances: true,
  placeholder: 'Nenhuma conta selecionada',
};

BankAccountSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  shortenAccountNames: PropTypes.bool,
  accounts: PropTypes.array,

  balance: PropTypes.object,
  isMultiple: PropTypes.bool,
  displayBalance: PropTypes.bool,
  displayPrincipalStar: PropTypes.bool,
  variant: PropTypes.string,
  canViewBalances: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default BankAccountSelect;
