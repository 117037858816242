/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { FcRefresh } from 'react-icons/fc';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery } from 'helpers';

import { Button } from '_components/_core';

import Row from './Row';
import RowForm from './RowForm';
import { InstalmentsTable } from '../styles';

function Table({
  parcelasCalculadas,
  parcelasEditadasValor,
  parcelasEditadasData,
  qtdeParcelas,
  valorTotalCalculado,
  renderSumWarningFor,
  renderRecalcWarningFor,
  renderRecalcTotalWarning,
  onChangeParcela,
  onDeleteParcela,
  onClearDeletedParcelas,
  onRecalcularParcelas,
  onRecalcularTotal,
  onSelectInstalmentForEdit,
}) {
  const transactionFormRef = useRef();

  const { isMobile } = useMediaQuery();

  const [selectedParcelaId, setSelectedParcelaId] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const handleEditParcela = useCallback((frequency_number, fieldName) => {
    setSelectedParcelaId(frequency_number);
    setSelectedFieldName(fieldName);

    onSelectInstalmentForEdit(frequency_number);
  }, [onSelectInstalmentForEdit]);

  const handleCancelEditParcela = useCallback(() => {
    setSelectedParcelaId(null);
    setSelectedFieldName(null);

    onSelectInstalmentForEdit(null);
  }, [onSelectInstalmentForEdit]);

  const handleInstalmentUpdated = useCallback((values, changedValues) => {
    onChangeParcela(values, changedValues);
  }, [onChangeParcela]);

  return (
    <>
      <InstalmentsTable
        isMobile={isMobile}
        className="table table-striped"
      >
        <thead>
          <tr>
            <th className="number-column">#</th>
            <th className="event-date-column">Vencimento</th>
            <th className="amount-column">Valor</th>
            <th className="paid-column">Pago?</th>
            <th className="buttons-column">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {parcelasCalculadas.map((parcela) => {
            const { frequency_number } = parcela;

            return (
              <>
                {selectedParcelaId !== frequency_number && (
                  <Row
                    key={`normal-row-${frequency_number}`}
                    transactionFormRef={transactionFormRef}
                    selectedParcelaId={selectedParcelaId}
                    parcela={parcela}
                    qtdeParcelas={qtdeParcelas}
                    parcelasEditadasValor={parcelasEditadasValor}
                    parcelasEditadasData={parcelasEditadasData}
                    renderSumWarningFor={renderSumWarningFor}
                    renderRecalcWarningFor={renderRecalcWarningFor}
                    isMobile={isMobile}
                    onChangeParcela={onChangeParcela}
                    onDeleteParcela={onDeleteParcela}
                    onClearDeletedParcelas={onClearDeletedParcelas}
                    onRecalcularParcelas={onRecalcularParcelas}
                    onEditParcela={handleEditParcela}
                  />
                )}
                {selectedParcelaId !== null && selectedParcelaId === frequency_number && (
                  <RowForm
                    key={`form-row-${frequency_number}`}
                    transactionFormRef={transactionFormRef}
                    parcela={parcela}
                    selectedFieldName={selectedFieldName}
                    qtdeParcelas={qtdeParcelas}
                    isMobile={isMobile}
                    onDeleteParcela={onDeleteParcela}
                    onSubmit={handleInstalmentUpdated}
                    onCancelEditInstalment={handleCancelEditParcela}
                  />
                )}
              </>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="number-column">
              &nbsp;
            </td>
            <td className="event-date-column">
              <span>
                <strong>Total</strong>
              </span>
            </td>
            <td className="amount-column">
              <span>
                <span className="d-flex align-items-center">
                  <strong className="mr-3">
                    {FORMATTERS.NUMBER(valorTotalCalculado)}
                  </strong>
                  {renderRecalcTotalWarning && (
                    <Button
                      variant="link"
                      className="pl-0 d-flex align-items-center"
                      size="sm"
                      onClick={() => onRecalcularTotal()}
                    >
                      Recalcular total
                      <FcRefresh className="ml-2" />
                    </Button>
                  )}
                </span>
              </span>
            </td>
            <td className="paid-column">
              &nbsp;
            </td>
            <td className="buttons-column">&nbsp;</td>
          </tr>
        </tfoot>
      </InstalmentsTable>
    </>
  );
}

Table.propTypes = {
  parcelasCalculadas: PropTypes.array.isRequired,
  qtdeParcelas: PropTypes.number.isRequired,
  valorTotalCalculado: PropTypes.number.isRequired,
  parcelasEditadasValor: PropTypes.array.isRequired,
  parcelasEditadasData: PropTypes.array.isRequired,
  renderSumWarningFor: PropTypes.array.isRequired,
  renderRecalcWarningFor: PropTypes.array.isRequired,
  renderRecalcTotalWarning: PropTypes.bool.isRequired,
  onChangeParcela: PropTypes.func.isRequired,
  onDeleteParcela: PropTypes.func.isRequired,
  onRecalcularParcelas: PropTypes.func.isRequired,
  onRecalcularTotal: PropTypes.func.isRequired,
  onClearDeletedParcelas: PropTypes.func.isRequired,
  onSelectInstalmentForEdit: PropTypes.func.isRequired,
};

export default Table;
