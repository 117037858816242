/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { FaEdit, FaExclamationCircle, FaTrash } from 'react-icons/fa';
import { FcRefresh } from 'react-icons/fc';
import { IoTrashBinOutline } from 'react-icons/io5';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';

function Row({
  transactionFormRef,
  selectedParcelaId,
  parcela,
  qtdeParcelas,
  parcelasEditadasValor,
  parcelasEditadasData,
  renderSumWarningFor,
  renderRecalcWarningFor,
  onChangeParcela,
  onDeleteParcela,
  onClearDeletedParcelas,
  onRecalcularParcelas,
  onEditParcela,
}) {
  const {
    frequency_number,
    event_date,
    amount,
    destroyed,
    paid,
    split,
  } = parcela;

  const handleEditParcela = useCallback((fieldName) => {
    if (parcela.split) {
      // eslint-disable-next-line no-alert
      alert('Não é possível editar uma parcela que teve seu valor detalhado. Utilize a tela Detalhar Valor para isso.');

      return;
    }

    if (selectedParcelaId) {
      if (transactionFormRef.current) {
        transactionFormRef.current.handleSubmit();
      }
    }

    onEditParcela(parcela.frequency_number, fieldName);
  }, [onEditParcela, transactionFormRef, selectedParcelaId, parcela]);

  const renderToggleField = useCallback(() => {
    const isPaid = paid;

    const novaParcela = {
      ...parcela,
      paid: !isPaid,
    };

    return (
      <span className="d-flex">
        {!isPaid && <BsToggleOff size="2.5em" style={{ cursor: 'pointer' }} className="text-muted" onClick={() => onChangeParcela(novaParcela)} />}
        {isPaid && <BsToggleOn size="2.5em" style={{ cursor: 'pointer' }} className="text-success" onClick={() => onChangeParcela(novaParcela)} />}
      </span>
    );
  }, [paid, parcela, onChangeParcela]);

  const renderRecalculateButton = useCallback(() => {
    if (!renderRecalcWarningFor.includes(frequency_number)) {
      return null;
    }

    const handleClick = (e) => {
      e.stopPropagation();

      onRecalcularParcelas();

      setTimeout(() => {
        onClearDeletedParcelas();
      }, 1500);
    };

    return (
      <Button
        variant="link"
        className="pl-0 d-flex align-items-center"
        size="sm"
        onClick={(e) => handleClick(e, 'amount')}
      >
        Recalcular parcelas
        <FcRefresh className="ml-2" />
      </Button>
    );
  }, [frequency_number, renderRecalcWarningFor, onRecalcularParcelas, onClearDeletedParcelas]);

  const renderDeletedRecalculateButton = useCallback(() => {
    const handleClick = (e) => {
      e.stopPropagation();

      onRecalcularParcelas();

      setTimeout(() => {
        onClearDeletedParcelas();
      }, 1500);
    };

    return (
      <Button
        variant="link"
        className="pl-0 d-flex align-items-center"
        size="sm"
        onClick={(e) => handleClick(e, 'amount')}
      >
        Recalcular parcelas
        <FcRefresh className="ml-2" />
      </Button>
    );
  }, [onRecalcularParcelas, onClearDeletedParcelas]);

  const renderLockIcon = useCallback((field) => {
    if (field === 'event_date' && parcelasEditadasData.find((p) => p.frequency_number === frequency_number)) {
      return <FaEdit size="0.8em" className="ml-2 text-muted" />;
    }

    if (field === 'amount' && parcelasEditadasValor.find((p) => p.frequency_number === frequency_number)) {
      return <FaEdit size="0.8em" className="ml-2 text-muted" />;
    }

    return null;
  }, [parcelasEditadasValor, parcelasEditadasData, frequency_number]);

  const renderWarningIcon = useCallback(() => {
    if (renderSumWarningFor.includes(frequency_number)) {
      return (
        <FaExclamationCircle
          className="text-yellow ml-2"
          data-place="bottom"
          data-tip="A soma das parcelas não bate com o valor total."
        />
      );
    }

    return null;
  }, [renderSumWarningFor, frequency_number]);

  if (destroyed) {
    return (
      <tr
        className="transaction-deleted"
        style={{
          background: '#ffd',
        }}
      >
        <td className="number-column">
          &nbsp;
        </td>
        <td className="event-date-column">
          &nbsp;
        </td>
        <td colSpan={2} className="text-left amount-deleted-column">
          <span>
            <strong>
              Parcela excluída
            </strong>
          </span>
        </td>
        <td colSpan={2}>
          <span>
            {renderDeletedRecalculateButton()}
          </span>
        </td>
      </tr>
    );
  }

  return (
    <tr style={{
      pointerEvents: split ? 'none' : 'all',
    }}
    >
      <td className="number-column">
        {`${frequency_number}/${qtdeParcelas}`}
      </td>
      <td
        data-label="Vencimento"
        className="event-date-column"
        onClick={() => handleEditParcela('event_date')}
      >
        <span style={{ borderBottom: '1px dashed grey' }}>
          {FORMATTERS.DATE_DDMMYYYY(event_date)}
          {renderLockIcon('event_date')}
        </span>
      </td>
      <td
        data-label="Valor"
        className="amount-column"
        onClick={() => handleEditParcela('amount')}
      >
        <div>
          <span style={{ borderBottom: '1px dashed grey' }}>
            {FORMATTERS.NUMBER(amount)}
            &nbsp;
            {renderLockIcon('amount')}
            {renderWarningIcon()}
            {destroyed && <IoTrashBinOutline className="ml-2 text-danger" />}
          </span>
          <span>
            {renderRecalculateButton()}
          </span>
        </div>
      </td>
      <td data-label="Pago?" className="paid-column">
        {renderToggleField()}
      </td>
      <td data-label="Excluir" className="buttons-column">
        <Button
          noMargin
          variant="link"
          className="text-muted btn-sm p-0 m-0"
          icon={<FaTrash size="1.2rem" />}
          onClick={() => onDeleteParcela(parcela)}
        />
      </td>
    </tr>
  );
}

Row.defaultProps = {
  selectedParcelaId: null,
};

Row.propTypes = {
  parcela: PropTypes.object.isRequired,
  qtdeParcelas: PropTypes.number.isRequired,
  parcelasEditadasValor: PropTypes.array.isRequired,
  parcelasEditadasData: PropTypes.array.isRequired,
  onChangeParcela: PropTypes.func.isRequired,
  onDeleteParcela: PropTypes.func.isRequired,
  onRecalcularParcelas: PropTypes.func.isRequired,
  onEditParcela: PropTypes.func.isRequired,
  onClearDeletedParcelas: PropTypes.func.isRequired,
  renderSumWarningFor: PropTypes.array.isRequired,
  renderRecalcWarningFor: PropTypes.array.isRequired,
  transactionFormRef: PropTypes.object.isRequired,
  selectedParcelaId: PropTypes.number,
};

export default Row;
