const ITEMS_PER_PAGE = 26;
/* eslint-disable no-prototype-builtins */

const paginateResults = (invoices) => {
  let page = 1;
  let counter = 1;

  const paginatedResults = invoices.reduce((finalObject, currentItem) => {
    if (finalObject.hasOwnProperty(page)) {
      finalObject[page].push(currentItem);
    } else {
      finalObject[page] = [currentItem];
    }

    counter++;

    if (counter === ITEMS_PER_PAGE) {
      page++;
      counter = 1;
    }

    return finalObject;
  }, {});

  return paginatedResults;
};

export default paginateResults;
